import { getStoreAccessors } from "typesafe-vuex";
import { State } from "../state";
import { SentimentState } from "./state";

export const getters = {
  emailOverallSentiment: (state: SentimentState) => state.email.overall,
  teamsOverallSentiment: (state: SentimentState) => state.teams.overall,
  emailOutboundSentiment: (state: SentimentState) => state.email.outbound,
  emailInboundSentiment: (state: SentimentState) => state.email.inbound,
  emailOrganizationalSentiment: (state: SentimentState) =>
    state.email.organizational,
  teamsByTeamSentiment: (state: SentimentState) => state.teams.byTeam,
};

const { read } = getStoreAccessors<SentimentState, State>("");

export const readOverallEmailSentiment = read(getters.emailOverallSentiment);
export const readOverallTeamsSentiment = read(getters.teamsOverallSentiment);
export const readOutboundEmailSentiment = read(getters.emailOutboundSentiment);
export const readInboundEmailSentiment = read(getters.emailInboundSentiment);
export const readOrganizationalEmailSentiment = read(
  getters.emailOrganizationalSentiment
);
export const readTeamsByTeamSentiment = read(getters.teamsByTeamSentiment);
