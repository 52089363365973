
import { Component, Vue } from "vue-property-decorator";
import { ICompanyCreate } from "@/interfaces";
import { readCompanies } from "@/store/companies/getters";
import {
  dispatchGetCompanies,
  dispatchCreateCompany
} from "@/store/companies/actions";

@Component
export default class CreateCompany extends Vue {
  public valid = false;
  public companyName = "";
  public mailServerAdmin = "";
  public description = "";
  public tenantId = "";
  public companyAdminForm = true;
  public adminName = "";
  public selectedService: any = null;
  public moderatorEmail: string = null;
  public moderatorName: string = null;
  public allowedDomains: string = null;

  public async mounted() {
    await dispatchGetCompanies(this.$store, {});
    this.reset();
  }

  get companies() {
    return readCompanies(this.$store);
  }

  public reset() {
    this.companyName = "";
    this.mailServerAdmin = "";
    this.description = "";
    this.tenantId = "";
    this.allowedDomains = null;
    this.moderatorEmail = null;
    this.moderatorName = null;
    this.$validator.reset();
  }

  public cancel() {
    this.$router.back();
  }

  public async submit() {
    if (await this.$validator.validateAll()) {
      const updatedCompany: ICompanyCreate = {
        name: this.companyName,
        mail_server_admin: this.mailServerAdmin,
        description: this.description,
        create_account: this.companyAdminForm,
        allowed_domains: this.allowedDomains,
        company_services: this.selectedService
      };
      if (this.selectedService == "microsoft") {
        updatedCompany["tenant_id"] = this.tenantId;
      }
      if (this.companyAdminForm) {
        updatedCompany["moderator_email"] = this.moderatorEmail;
        updatedCompany["moderator_name"] = this.moderatorName;
      }
      if (this.description) {
        updatedCompany.description = this.description;
      }
      await dispatchCreateCompany(this.$store, updatedCompany).then(
        (companyId) => {
          if (companyId) {
            this.$router.push("/main/admin/companies/all");
          }
          // else if (companyId && this.companyAdminForm) {
          //   const companyAdmin = {
          //     full_name: this.adminName,
          //     email: this.mailServerAdmin,
          //     moderatorgroup: "Internal",
          //     company_id: companyId,
          //   };
          //   dispatchCreateModerator(this.$store, companyAdmin).then(
          //     (res: any) => {
          //       if (res) {
          //         this.$router.push("/main/admin/companies");
          //       }
          //     }
          //   );
          // }
        }
      );
    }
  }
}
