
import { Component, Vue } from "vue-property-decorator";
import NotificationsManager from "@/components/NotificationsManager.vue";
import { readIsLoggedIn, readUserProfile } from "@/store/main/getters";
import {
  dispatchCheckLoggedIn,
  dispatchGetUserCompany,
} from "@/store/main/actions";
import Login from "@/views/Login.vue";

@Component({
  components: {
    NotificationsManager,
    Login,
  },
})
export default class App extends Vue {
  public companyIdWatch: any;
  public isRender: boolean;

  async mounted() {
    if (
      !this.$store.getters.company?.company_id &&
      this.$store.getters.userProfile?.email &&
      this.$store.getters.newPasswordRequired
    ) {
      await dispatchGetUserCompany(this.$store);
    }

    this.companyIdWatch = this.$store.watch(
      async (state, getters) => {
        if (!getters.userProfile?.email && getters.newPasswordRequired) {
          await dispatchCheckLoggedIn(this.$store);
        }
        // if(!getters.company?.company_id && getters.userProfile?.email && getters.newPasswordRequired){
        //   await dispatchGetUserCompany(this.$store);
        // }
        return getters.company && getters.company.company_id;
      },
      () => {
        this.isRender = true;
      }
    );
  }

  get loggedIn() {
    const loggedIn = readIsLoggedIn(this.$store);
    if (loggedIn === null) {
      dispatchCheckLoggedIn(this.$store);
    }
    return readIsLoggedIn(this.$store);
  }

  get userInfo() {
    return readUserProfile(this.$store);
  }
  public async created() {
    await dispatchCheckLoggedIn(this.$store);
  }
}
