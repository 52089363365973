import { MSState } from "./state";
import { getStoreAccessors } from "typesafe-vuex";
import { State } from "../state";

export const getters = {
  msUsers: (state: MSState) => state.msUsers,
  msUser: (state: MSState) => (userId: string) => {
    const filteredUsers = state.msUsers.filter((user) => user.id === userId);
    if (filteredUsers.length > 0) {
      return { ...filteredUsers[0] };
    }
  },
  subscribedUsers: (state: MSState) => state.subscribedUsers,
  importedUsers: (state: MSState) => state.importedUsers,
};

const { read } = getStoreAccessors<MSState, State>("");

export const readMsUser = read(getters.msUser);
export const readMsUsers = read(getters.msUsers);
export const readSubscribedUsers = read(getters.subscribedUsers);
export const readImportedUsers = read(getters.importedUsers);
