import { MSState } from "./state";
import { getStoreAccessors } from "typesafe-vuex";
import { State } from "../state";

export const mutations = {
  setMsUser(state: MSState, payload) {
    state.msUser = payload;
  },
  setMsUsers(state: MSState, payload) {
    state.msUsers = payload;
  },
  setSubscribedUsers(state: MSState, payload) {
    state.subscribedUsers = payload;
  },
  setImportedUsers(state: MSState, payload) {
    state.importedUsers = payload;
  },
};

const { commit } = getStoreAccessors<MSState, State>("");

export const commitSetMsUser = commit(mutations.setMsUser);
export const commitSetMsUsers = commit(mutations.setMsUsers);
export const commitSetSubscribedUsers = commit(mutations.setSubscribedUsers);
export const commitSetImportedUsers = commit(mutations.setImportedUsers);
