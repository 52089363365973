
import { Component, Vue } from "vue-property-decorator";
import { readItemById, readForwards } from "@/store/items/getters";
import { readCompany, readUserProfile } from "@/store/main/getters";
import { readAdminUsers } from "@/store/admin/getters";
import {
  dispatchUpdateItem,
  dispatchGetItemById,
  dispatchGetForwards,
  dispatchGetToxicItemCount,
  dispatchGetToxicItems
} from "@/store/items/actions";
import moment from "moment";
import ForwardEmailForm from "@/components/ForwardEmailForm.vue";
import { dispatchCreateExcluded } from "@/store/excluded/actions";
import { IItemUpdate } from "@/interfaces";
import { TIMEFRAME_SEARCH_DEFAULT } from "@/store/sentiment";
import { dispatchGetCompanyCountsSummary } from "@/store/counts/actions";
import { readLastDashboardSearch } from "@/store/companies/getters";

@Component({
  components: {
    ForwardEmailForm
  }
})
export default class ItemDetails extends Vue {
  public docDatetime = "";
  public sender = "";
  public recipient = "";
  public date = new Date().toISOString().substr(0, 10);
  public status = "";
  public archivedLoading = false;
  public flaggedLoading = false;
  public reviewLoading = false;
  public loading = true;
  public startDate: string = moment()
    .subtract(TIMEFRAME_SEARCH_DEFAULT, "days")
    .startOf("day")
    .utc()
    .format();

  public async mounted() {
    this.getItemDetails().then((response) => {
      this.getForwards();
      if (this.itemDetail?.is_read === false) {
        this.updateItemStatus(this.itemDetail.uuid, "read");
      }
      let htmlTag = document.getElementById("htm");
      htmlTag.innerHTML = this.itemDetail.body_html
        ? this.itemDetail.body_html
        : this.itemDetail.body;
      this.loading = false;
    });
  }

  public async getForwards() {
    await dispatchGetForwards(this.$store, this.$router.currentRoute.params.id);
  }

  public async getItemDetails() {
    await dispatchGetItemById(this.$store, this.$router.currentRoute.params.id);
  }

  public async updateItemStatus(id: string, status: string) {
    const updatePayload: IItemUpdate = {
      uuid: id
    };
    if (status === "archived") {
      this.archivedLoading = true;
      updatePayload.is_archived = true;
      updatePayload.is_user_flagged = false;
    } else if (status === "flagged") {
      this.flaggedLoading = true;
      updatePayload.is_user_flagged = true;
      updatePayload.is_archived = false;
    } else if (status === "read") {
      updatePayload.is_read = true;
    }

    await dispatchUpdateItem(this.$store, {
      id,
      item: updatePayload
    }).then((response) => {
      this.archivedLoading = false;
      this.flaggedLoading = false;
    });
  }

  public async returnToReview(id: string) {
    this.reviewLoading = true;
    const payload: IItemUpdate = {
      uuid: id,
      is_archived: false,
      is_user_flagged: false
    };
    await dispatchUpdateItem(this.$store, {
      id,
      item: payload
    });
    this.reviewLoading = false;
    this.navigateToDashboard("review");
  }

  stringToHTML(str) {
    var parser = new DOMParser();
    var doc = parser.parseFromString(str, "text/html");
    return doc.body;
  }

  public async submitUpdateItemStatus(id: string, status: string) {
    await this.updateItemStatus(id, status).then((x) => {
      this.navigateToDashboard(status);
    });
  }

  public navigateToDashboard(tabName: string) {
    // tab options: unread, flagged, archived, all
    this.$router
      .push({ name: "Email Incidents", params: { tabName: tabName } })
      .catch((err) => err);
  }

  public formatTimestamp(datetime) {
    if (datetime) {
      return moment(datetime).format("MMMM D, YYYY - h:mm a");
    } else {
      return null;
    }
  }

  public async ignore() {
    const startDate = moment()
      .subtract(this.lastDashboardSearch!.timeframe!, "days")
      .startOf("day")
      .utc()
      .format();
    let promises = [];
    promises.push([
      dispatchCreateExcluded(this.$store, {
        email: this.itemDetail.email_sender,
        company_id: this.userProfile.company_id
      }),
      dispatchGetCompanyCountsSummary(this.$store, {
        companyId: this.company.company_id
      }),
      dispatchGetToxicItemCount(this.$store, { start: startDate }),
      dispatchGetToxicItems(this.$store, {
        start: startDate,
        limit: 10,
        offset: 0
      })
    ]);
    await Promise.all(promises);
  }

  get users() {
    return readAdminUsers(this.$store);
  }

  get userProfile() {
    return readUserProfile(this.$store);
  }

  get itemDetail() {
    return readItemById(this.$store);
  }

  get forwards() {
    return readForwards(this.$store);
  }

  get company() {
    return readCompany(this.$store);
  }

  get lastDashboardSearch() {
    return readLastDashboardSearch(this.$store);
  }

  public back() {
    this.$router.go(-1);
  }
}
