
import { ICompanyProfile } from "@/interfaces";
import { Component, Prop, Vue } from "vue-property-decorator";

@Component
export default class GmailAuthentication extends Vue {
  @Prop() public readonly enableNext: boolean;
  @Prop() public readonly verifyLoading: boolean;
  @Prop() public readonly company: ICompanyProfile;
  public mailServerAdmin = "";

  mounted() {
    this.mailServerAdmin = this.company?.mail_server_admin;
  }

  public verifyGmail() {
    this.$emit("verifyGmail", this.mailServerAdmin);
  }
}
