
import { Component, Vue } from "vue-property-decorator";
import { appName } from "@/env";
import { dispatchResetPassword } from "@/store/main/actions";

@Component
export default class UserProfileEdit extends Vue {
  public appName = appName;
  public valid = true;
  public password = "";
  public email = "";
  public code = "";
  public loading = false;
  public showPassword = false;
  public regex =
    /^(?=.*[a-z ])(?=.*[A-Z ])(?=.*[0-9])(?=.*[=+\-^$*.\[\]{}()?"!@#%&\/\\,><':;|_~`]).{8,99}$/;

  public validatePassword(password: string) {
    return this.regex.test(password);
  }

  public async mounted() {
    if (this.$route.params.email) {
      this.email = this.$route.params.email;
    }
  }

  public async submit() {
    this.loading = true;
    if (await this.$validator.validateAll()) {
      await dispatchResetPassword(this.$store, {
        username: this.email,
        code: this.code,
        password: this.password,
      }).then(
        (res) => {
          if (res !== undefined) {
            this.$router.push("/").catch((err) => err);
          }
          this.loading = false;
        },
        (err) => {
          this.loading = false;
        }
      );
    }
  }
}
