
import { Component, Vue } from "vue-property-decorator";
import { readUserProfile } from "@/store/main/getters";
import { dispatchGetUserCompany } from "@/store/companies/actions";
import { readUserCompany } from "@/store/companies/getters";
import { dispatchGetUserProfile } from "@/store/main/actions";
import ServiceNowForm from "@/components/ServiceNowForm.vue";

@Component({
  components: { ServiceNowForm },
})
export default class ServiceNowConfig extends Vue {
  public async mounted() {
    if (this.userProfile?.company_id) {
      await dispatchGetUserCompany(this.$store, this.userProfile?.company_id);
    }
    await dispatchGetUserProfile(this.$store).then((res) => {
      dispatchGetUserCompany(this.$store, this.userProfile?.company_id);
    });
  }

  get company() {
    return readUserCompany(this.$store);
  }

  get userProfile() {
    return readUserProfile(this.$store);
  }
}
