
import { Component, Vue, Prop } from "vue-property-decorator";
import { IMessageFlaggedEvent } from "@/interfaces";
import moment from "moment";

@Component
export default class IncidentPreview extends Vue {
  @Prop() public readonly item: IMessageFlaggedEvent;
  @Prop() public readonly index: number;

  formatTimestamp(datetime) {
    if (datetime) {
      return moment(datetime).format("MMMM Do YYYY - h:mm a");
    } else {
      return null;
    }
  }

  navigateToMessageDetails(id: any) {
    this.$router
      .push({ name: "Review Incident", params: { id } })
      .catch((err) => err);
  }
}
