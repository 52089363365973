
import { Component, Vue } from "vue-property-decorator";
import { readCompanies } from "@/store/companies/getters";
import { dispatchGetCompanies } from "@/store/companies/actions";

@Component
export default class AdminCompanies extends Vue {
  public headers = [
    {
      text: "Name",
      sortable: true,
      value: "name",
      align: "left",
    },
    {
      text: "Admin Email",
      sortable: true,
      value: "mail_server_admin",
      align: "left",
    },
    {
      text: "Description",
      sortable: true,
      value: "description",
      align: "left",
    },
    {
      text: "Actions",
      value: "id",
    },
  ];

  get companies() {
    return readCompanies(this.$store);
  }

  public async mounted() {
    await dispatchGetCompanies(this.$store, {});
  }
}
