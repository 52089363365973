
import { Frequency, IModeratorUpdate } from "@/interfaces";
import { dispatchUpdateModerator } from "@/store/moderators/actions";
import { Component, Prop, Vue } from "vue-property-decorator";

@Component
export default class NotificationSettings extends Vue {
  @Prop() public readonly notificationFrequencyEmail: Frequency;
  @Prop() public readonly notificationFrequencyText: Frequency;
  @Prop() public readonly moderatorEmail: string;
  public Frequency = Frequency;
  public emailNotifications: Frequency = Frequency.Weekly;
  public textNotifications: Frequency = Frequency.Weekly;

  mounted() {
    this.emailNotifications = this.notificationFrequencyEmail;
    this.textNotifications = this.notificationFrequencyText;
  }

  public updateNotifications() {
    const moderator: IModeratorUpdate = {
      email: this.moderatorEmail,
      notification_frequency_email: this.emailNotifications,
      notification_frequency_text: this.textNotifications
    };
    dispatchUpdateModerator(this.$store, {
      email: this.moderatorEmail,
      moderator: moderator
    });
  }
}
