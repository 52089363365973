import { ActionContext } from "vuex";
import { IMicrosoftTeamsItemUpdate } from "@/interfaces";
import { State } from "../state";
import { MicrosoftTeamsItemState } from "./state";
import { getStoreAccessors } from "typesafe-vuex";
import axios from "../interceptor";
import {
  commitSetMicrosoftTeamsToxicItems,
  commitSetMicrosoftTeamsToxicItemsLoading,
  commitSetMicrosoftTeamsToxicItemCount,
  commitSetMicrosoftTeamsEarliestMessageDate,
  commitSetMicrosoftTeamsItemById,
  commitSetMicrosoftTeamsItemsByType,
} from "./mutations";
import { dispatchCheckApiError } from "../main/actions";

type MainContext = ActionContext<MicrosoftTeamsItemState, State>;

export const actions = {
  /**
   * This function is used to get toxic items
   * @param context
   * @param queries
   * @returns
   */
  async actionGetMicrosoftTeamsToxicItems(
    context: MainContext,
    queries: {
      limit?: number;
      start?: any;
      offset?: number;
    }
  ) {
    try {
      const response = await axios.get("/teams/items", {
        params: {
          start_date: queries.start,
          limit: queries.limit,
          offset: queries.offset,
        },
      });
      if (response) {
        const items = response.data;
        commitSetMicrosoftTeamsToxicItems(context, items);
        commitSetMicrosoftTeamsToxicItemsLoading(context, false);

        return items;
      }
    } catch (error) {
      commitSetMicrosoftTeamsToxicItemsLoading(context, false);
      await dispatchCheckApiError(context, error);
      return "error";
    }
  },

  /**
   * This function is used to get toxic items
   * @param context
   * @param queries
   * @returns
   */
  async actionGetTeamsToxicItemsPerPage(
    context: MainContext,
    queries: {
      limit?: number;
      start?: any;
      offset?: number;
      type: string;
      searchInput?: string;
    }
  ) {
    try {
      const response = await axios.get("teams/items", {
        params: {
          start_date: queries.start,
          limit: queries.limit,
          offset: queries.offset,
          type: queries.type,
          search_input: queries.searchInput
        },
      });
      if (response) {
        const items = response.data;

        commitSetMicrosoftTeamsItemsByType(context, {
          items: items,
          type: queries.type,
        });
        commitSetMicrosoftTeamsToxicItemsLoading(context, false);
        return items;
      }
    } catch (error) {
      commitSetMicrosoftTeamsToxicItemsLoading(context, false);
      await dispatchCheckApiError(context, error);
      return "error";
    }
  },

  /**
   * This function get the toxic item count
   * @param context
   */
  async actionGetMicrosoftTeamsToxicItemCount(
    context: MainContext,
    payload: { start: any, searchInput?: string }
  ) {
    try {
      const response: any = await axios.get("/teams/items/count", {
        params: {
          start_date: payload.start,
          search_input: payload.searchInput
        },
      });

      commitSetMicrosoftTeamsToxicItemCount(context, response.data);
    } catch (error) {
      await dispatchCheckApiError(context, error);
    }
  },

  async actionGetMicrosoftTeamsEarliestMessageDate(
    context: MainContext,
    companyId: number
  ) {
    try {
      const response = await axios.get("/teams/items/earliest");
      if (response) {
        const utcDate = response.data.message_datetime;

        commitSetMicrosoftTeamsEarliestMessageDate(context, utcDate);
      }
    } catch (error) {
      await dispatchCheckApiError(context, error);
      return 0;
    }
  },
  /**
   * This function is used to get toxic item detail by id
   * @param context
   * @param id
   */
  async actionGetMicrosoftTeamsItemById(context: MainContext, uuid: string) {
    try {
      const response = await axios.get(`/teams/item/${uuid}`);
      if (response) {
        const item = response.data;
        commitSetMicrosoftTeamsItemById(context, item);
      }
    } catch (error) {
      await dispatchCheckApiError(context, error);
    }
  },

  /**
   * This function is used to update toxic item
   * @param context
   * @param payload
   */
  async actionUpdateMicrosoftTeamsItem(
    context: MainContext,
    payload: IMicrosoftTeamsItemUpdate
  ) {
    try {
      await axios.put(`teams/item/${payload.uuid}`, payload);
    } catch (error) {
      await dispatchCheckApiError(context, error);
    }
  },

  /**
   * This function is used to formward toxic item
   * @param context
   * @param payload
   * @returns
   */
  async actionCreateTeamsForward(context: MainContext, payload) {
    try {
      const response = await axios.post("/teams/forward", payload);

      return response;
    } catch (error) {
      await dispatchCheckApiError(context, error);
    }
  },
};
const { dispatch } = getStoreAccessors<MicrosoftTeamsItemState, State>("");

export const dispatchGetMicrosoftTeamsToxicItems = dispatch(
  actions.actionGetMicrosoftTeamsToxicItems
);
export const dispatchGetMicrosoftTeamsToxicItemCount = dispatch(
  actions.actionGetMicrosoftTeamsToxicItemCount
);
export const dispatchGetMicrosoftTeamsEarliestMessageDate = dispatch(
  actions.actionGetMicrosoftTeamsEarliestMessageDate
);
export const dispatchGetMicrosoftTeamsItemById = dispatch(
  actions.actionGetMicrosoftTeamsItemById
);
export const dispatchUpdateMicrosoftTeamsItem = dispatch(
  actions.actionUpdateMicrosoftTeamsItem
);
export const dispatchCreateTeamsForward = dispatch(
  actions.actionCreateTeamsForward
);
export const dispatchGetTeamsToxicItemsPerPage = dispatch(
  actions.actionGetTeamsToxicItemsPerPage
);
