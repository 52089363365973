import { mutations } from "./mutations";
import { getters } from "./getters";
import { actions } from "./actions";
import { SentimentState } from "./state";
export const TIMEFRAME_SEARCH_DEFAULT = 7;

export const defaultState: SentimentState = {
  email: {
    overall: {
      total_items_evaluated: null,
      average_positive: null,
      average_neutral: null,
      average_negative: null,
      grade: "",
      benchmark: "",
      timeframe: TIMEFRAME_SEARCH_DEFAULT,
    },
    organizational: {
      total_items_evaluated: null,
      average_positive: null,
      average_neutral: null,
      average_negative: null,
      grade: "",
      benchmark: "",
      timeframe: TIMEFRAME_SEARCH_DEFAULT,
    },
    inbound: {
      total_items_evaluated: null,
      average_positive: null,
      average_neutral: null,
      average_negative: null,
      grade: "",
      benchmark: "",
      timeframe: TIMEFRAME_SEARCH_DEFAULT,
    },
    outbound: {
      total_items_evaluated: null,
      average_positive: null,
      average_neutral: null,
      average_negative: null,
      grade: "",
      benchmark: "",
      timeframe: TIMEFRAME_SEARCH_DEFAULT,
    },
  },
  teams: {
    overall: {
      total_items_evaluated: null,
      average_positive: null,
      average_neutral: null,
      average_negative: null,
      grade: "",
      benchmark: "",
      timeframe: TIMEFRAME_SEARCH_DEFAULT,
    },
    byTeam: [],
  },
};

export const sentimentModule = {
  state: defaultState,
  mutations,
  actions,
  getters,
};
