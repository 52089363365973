import { ModeratorState } from "./state";
import { getStoreAccessors } from "typesafe-vuex";
import { State } from "../state";

export const getters = {
  moderators: (state: ModeratorState) => state.moderators,
  nonModeratorUsers: (state: ModeratorState) => state.nonModeratorUsers,
  moderator: (state: ModeratorState) => state.moderator,
};

const { read } = getStoreAccessors<ModeratorState, State>("");

export const readModerators = read(getters.moderators);
export const readNonModeratorUsers = read(getters.nonModeratorUsers);
export const readModerator = read(getters.moderator);
