
import { Component, Vue, Prop } from "vue-property-decorator";
import { IMicrosoftTeamsItem } from "@/interfaces";
import moment from "moment";

@Component
export default class TeamsIncidentPreview extends Vue {
  @Prop() public readonly item: IMicrosoftTeamsItem;
  @Prop() public readonly index: number;

  private formatTimestamp(datetime) {
    if (datetime) {
      return moment(datetime).local().format("MMMM Do YYYY - h:mm a");
    } else {
      return null;
    }
  }

  public navigateToMessageDetails(id: any) {
    this.$router
      .push({ name: "Review MS Teams Incident", params: { id } })
      .catch((err) => err);
  }
}
