import { mutations } from "./mutations";
import { getters } from "./getters";
import { actions } from "./actions";
import { ExcludedState } from "./state";

const defaultState: ExcludedState = {
  emails: [],
  excludedList: [],
  nonExcluded: [],
};

export const excludedModule = {
  state: defaultState,
  mutations,
  actions,
  getters,
};
