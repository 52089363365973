import { ISentiment } from "@/interfaces";
import { getStoreAccessors } from "typesafe-vuex";
import { TIMEFRAME_SEARCH_DEFAULT } from ".";
import { State } from "../state";
import { SentimentState } from "./state";

export const mutations = {
  setEmailOverallSentiment(state: SentimentState, overall: ISentiment) {
    state.email.overall = { ...state.email.overall, ...overall };
  },
  setEmailOverallTimeframe(state: SentimentState, timeframe: number) {
    state.email.overall.timeframe = timeframe;
  },
  setTeamsOverallSentiment(state: SentimentState, overall: ISentiment) {
    state.teams.overall = { ...state.teams.overall, ...overall };
  },
  setTeamsOverallTimeframe(state: SentimentState, timeframe: number) {
    state.teams.overall.timeframe = timeframe;
  },
  setEmailOutboundSentiment(state: SentimentState, outbound: ISentiment) {
    state.email.outbound = { ...state.email.outbound, ...outbound };
  },
  setEmailOutboundTimeframe(state: SentimentState, timeframe: number) {
    state.email.outbound.timeframe = timeframe;
  },
  setEmailInboundSentiment(state: SentimentState, inbound: ISentiment) {
    state.email.inbound = { ...state.email.inbound, ...inbound };
  },
  setEmailInboundTimeframe(state: SentimentState, timeframe: number) {
    state.email.inbound.timeframe = timeframe;
  },
  setEmailOrganizationalSentiment(
    state: SentimentState,
    organizational: ISentiment
  ) {
    state.email.organizational = {
      ...state.email.organizational,
      ...organizational,
    };
  },
  setEmailOrganizationalTimeframe(state: SentimentState, timeframe: number) {
    state.email.organizational.timeframe = timeframe;
  },
  setAllTeamsSentiment(state: SentimentState, byTeam: Array<ISentiment>) {
    const updateTeams = byTeam.map((team) => {
      const item = team;
      item.timeframe = TIMEFRAME_SEARCH_DEFAULT;

      return item;
    });
    state.teams.byTeam = updateTeams;
  },
  setIndividualTeamSentiment(state: SentimentState, singleTeam: ISentiment) {
    const updateTeams = state.teams.byTeam.map((team) => {
      let item = team;
      if (team.team_name == singleTeam.team_name) {
        item = { ...item, ...singleTeam };
      }
      return item;
    });

    state.teams.byTeam = updateTeams;
  },
  setIndividualTeamTimeframe(
    state: SentimentState,
    singleTeam: { timeframe: number; cardTitle: string }
  ) {
    const updateTeams = state.teams.byTeam.map((team) => {
      const item = team;
      if (team.team_name == singleTeam.cardTitle) {
        item.timeframe = singleTeam.timeframe;
      }
      return item;
    });

    state.teams.byTeam = updateTeams;
  },
};

const { commit } = getStoreAccessors<SentimentState, State>("");

export const commitSetEmailOverallSentiment = commit(
  mutations.setEmailOverallSentiment
);
export const commitSetTeamsOverallSentiment = commit(
  mutations.setTeamsOverallSentiment
);
export const commitSetEmailOutboundSentiment = commit(
  mutations.setEmailOutboundSentiment
);
export const commitSetEmailInboundSentiment = commit(
  mutations.setEmailInboundSentiment
);
export const commitSetEmailOrganizationalSentiment = commit(
  mutations.setEmailOrganizationalSentiment
);
export const commitSetAllTeamsSentiment = commit(
  mutations.setAllTeamsSentiment
);
export const commitSetIndividualTeamSentiment = commit(
  mutations.setIndividualTeamSentiment
);
export const commitSetEmailOverallTimeframe = commit(
  mutations.setEmailOverallTimeframe
);
export const commitSetEmailOrganizationalTimeframe = commit(
  mutations.setEmailOrganizationalTimeframe
);
export const commitSetEmailInboundTimeframe = commit(
  mutations.setEmailInboundTimeframe
);
export const commitSetEmailOutboundTimeframe = commit(
  mutations.setEmailOutboundTimeframe
);
export const commitSetTeamsOverallTimeframe = commit(
  mutations.setTeamsOverallTimeframe
);
export const commitSetIndividualTeamTimeframe = commit(
  mutations.setIndividualTeamTimeframe
);
