import Vue from "vue";
import Vuex, { StoreOptions } from "vuex";

import { mainModule } from "./main";
import { State } from "./state";
import { adminModule } from "./admin";
import { itemModule } from "./items";
import { companyModule } from "./companies";
import { countsModule } from "./counts";
import { msLoginModule } from "./microsoft";
import { excludedModule } from "./excluded";
import { moderatorModule } from "./moderators";
import { serviceNowModule } from "./serviceNow";
import { auditModule } from "./audit";
import { microsoftTeamsItemModule } from "./teamsItems";
import { sentimentModule } from "./sentiment";

Vue.use(Vuex);

export const storeOptions: StoreOptions<State> = {
  modules: {
    main: mainModule,
    admin: adminModule,
    items: itemModule,
    mstItems: microsoftTeamsItemModule,
    companies: companyModule,
    counts: countsModule,
    msLogin: msLoginModule,
    excluded: excludedModule,
    moderators: moderatorModule,
    serviceNow: serviceNowModule,
    audit: auditModule,
    sentiment: sentimentModule,
  },
};

export const store = new Vuex.Store<State>(storeOptions);

export default store;
