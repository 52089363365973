import {
  ICompanyProfile,
  ICompanyDashSearch,
  ImportUser,
  IKeyword,
  ICompanyO365Verification,
  ICompanyTeamsVerification,
  ICompanyTeamsDashSearch,
} from "@/interfaces";
import { CompanyState } from "./state";
import { getStoreAccessors } from "typesafe-vuex";
import { State } from "../state";

export const mutations = {
  setCompanies(state: CompanyState, payload: ICompanyProfile[]) {
    state.companies = payload;
  },
  setCompany(state: CompanyState, payload: ICompanyProfile) {
    const companies = state.companies.filter(
      (company: ICompanyProfile) => company.company_id !== payload.company_id
    );
    companies.push(payload);
    state.companies = companies;
  },
  setUserCompany(state: CompanyState, payload: ICompanyProfile) {
    state.userCompany = payload;
  },
  setLastDashboardSearch(state: CompanyState, payload: ICompanyDashSearch) {
    state.lastDashboardSearch = payload;
  },
  setLastTeamsDashboardSearch(
    state: CompanyState,
    payload: ICompanyTeamsDashSearch
  ) {
    state.lastTeamsDashboardSearch = payload;
  },
  setImportUser(state: CompanyState, payload: ImportUser) {
    state.importUser = payload;
  },
  setKeywordsList(state: CompanyState, payload: IKeyword[]) {
    state.keywordsList = payload;
  },
  setO365Verification(state: CompanyState, payload: ICompanyO365Verification) {
    state.isO356Verified = payload;
  },
  setTeamsVerification(
    state: CompanyState,
    payload: ICompanyTeamsVerification
  ) {
    state.isTeamsVerified = payload;
  },
  setChangePageLastDashboardSearch(
    state: CompanyState,
    payload: { page: number; tab: string }
  ) {
    state.lastDashboardSearch.currentPage[payload.tab] = payload.page;
  },
};

const { commit } = getStoreAccessors<CompanyState, State>("");

export const commitSetCompany = commit(mutations.setCompany);
export const commitSetCompanies = commit(mutations.setCompanies);
export const commitSetUserCompany = commit(mutations.setUserCompany);
export const commitSetImportUser = commit(mutations.setImportUser);
export const commitSetLastDashboardSearch = commit(
  mutations.setLastDashboardSearch
);
export const commitSetKeywordsList = commit(mutations.setKeywordsList);
export const commitSetO356Verification = commit(mutations.setO365Verification);
export const commitSetTeamsVerification = commit(
  mutations.setTeamsVerification
);
export const commitSetLastTeamsDashboardSearch = commit(
  mutations.setLastTeamsDashboardSearch
);
export const commitSetChangePageLastDashboardSearch = commit(
  mutations.setChangePageLastDashboardSearch
);
