
import { Component, Vue } from "vue-property-decorator";
import { readUserProfile } from "@/store/main/getters";
import { readUserCompany } from "@/store/companies/getters";
import ExcludedList from "@/components/ExcludedList.vue";

@Component({
  components: { ExcludedList }
})
export default class CompanyUsers extends Vue {

  get company() {
    return readUserCompany(this.$store);
  }

  get userProfile() {
    return readUserProfile(this.$store);
  }
}
