import { ActionContext } from "vuex";
import { IItemUpdate } from "@/interfaces";
import { State } from "../state";
import { ItemState } from "./state";
import { getStoreAccessors } from "typesafe-vuex";
import {
  commitSetToxicItems,
  commitSetToxicItemsLoading,
  commitSetItemById,
  commitSetEarliestMessageDate,
  commitSetForwards,
  commitSetToxicItemCount,
  commitSetItemsByType,
} from "./mutations";
import { dispatchCheckApiError } from "../main/actions";
import moment from "moment";
import axios from "../interceptor";

type MainContext = ActionContext<ItemState, State>;

export const actions = {
  /**
   * This function is used to get toxic items
   * @param context
   * @param queries
   * @returns
   */
  async actionGetToxicItems(
    context: MainContext,
    queries: {
      limit?: number;
      start?: any;
      offset?: number;
      searchInput?: string;
    }
  ) {
    try {
      const params = {
        start_date: queries.start,
        limit: queries.limit,
        offset: queries.offset,
        search_input: queries.searchInput
      };
      Object.keys(params).forEach(
        (key) => params[key] == null && delete params[key]
      );

      const response = await axios.get("/item/get_items_between_dates", {
        params: {
          start_date: queries.start,
          limit: queries.limit,
          offset: queries.offset,
          search_input: queries.searchInput
        },
      });
      if (response) {
        const items = response.data;
        commitSetToxicItems(context, items);
        commitSetToxicItemsLoading(context, false);
        return items;
      }
    } catch (error) {
      commitSetToxicItemsLoading(context, false);
      await dispatchCheckApiError(context, error);
      return "error";
    }
  },

  /**
   * This function is used to get toxic items
   * @param context
   * @param queries
   * @returns
   */
  async actionGetToxicItemsPerPage(
    context: MainContext,
    queries: {
      limit?: number;
      start?: string;
      offset?: number;
      type: string;
      searchInput?: string
    }
  ) {
    try {
      const response = await axios.get("/item/get_items_between_dates/page", {
        params: {
          start_date: queries.start,
          limit: queries.limit,
          offset: queries.offset,
          type: queries.type,
          search_input: queries.searchInput
        },
      });
      if (response) {
        const items = response.data;

        commitSetItemsByType(context, { items: items, type: queries.type });
        commitSetToxicItemsLoading(context, false);
        return items;
      }
    } catch (error) {
      commitSetToxicItemsLoading(context, false);
      await dispatchCheckApiError(context, error);
      return "error";
    }
  },

  /**
   * This function get the toxic item count
   * @param context
   */
  async actionGetToxicItemCount(context: MainContext, payload: { start: any, searchInput?: string }) {
    try {
      const response = await axios.get("/item/get_different_items_count", {
        params: {
          start_date: payload.start,
          search_input: payload.searchInput
        },
      });
      commitSetToxicItemCount(context, response.data);
    } catch (error) {
      await dispatchCheckApiError(context, error);
    }
  },

  /**
   * This function is used to update toxic item
   * @param context
   * @param payload
   */
  async actionUpdateItem(
    context: MainContext,
    payload: { id: string; item: IItemUpdate }
  ) {
    try {
      const response = await axios.put(
        `/item/${payload.item.uuid}`,
        payload.item
      );
      commitSetItemById(context, response.data);
    } catch (error) {
      await dispatchCheckApiError(context, error);
    }
  },

  /**
   * This function is used to get toxic item detail by id
   * @param context
   * @param id
   */
  async actionGetItemById(context: MainContext, uuid) {
    try {
      const response = await axios.get(`/item/${uuid}`);
      if (response) {
        const item = response.data;
        commitSetItemById(context, item);
      }
    } catch (error) {
      await dispatchCheckApiError(context, error);
    }
  },

  /**
   * This function is used to get date of earliest message
   * @param context
   * @param companyId
   * @returns
   */
  async actionGetEarliestMessageDate(context: MainContext, companyId: number) {
    try {
      const response = await axios.get("/item/get_oldest_item_date");
      if (response) {
        const utcDate = response.data.doc_datetime;
        commitSetEarliestMessageDate(context, utcDate);
      }
    } catch (error) {
      await dispatchCheckApiError(context, error);
      return 0;
    }
  },

  /**
   * This function is used to get the forward list by item id.
   * @param context
   * @param itemId
   */
  async actionListForwards(context: MainContext, itemId: string) {
    try {
      const response = await axios.post("/user/list_forward", {
        item_id: itemId,
      });
      if (response) {
        response.data.map((forward) => {
          forward.recipients = forward.recipients.split(",");
          forward.created_at = moment.utc(forward.created_at).local();
        });
        commitSetForwards(context, response.data);
      }
    } catch (error) {
      await dispatchCheckApiError(context, error);
    }
  },

  /**
   * This function is used to formward toxic item
   * @param context
   * @param payload
   * @returns
   */
  async actionCreateForward(context: MainContext, payload) {
    try {
      const response = await axios.post("/user/create_forward", payload);
      return response;
    } catch (error) {
      await dispatchCheckApiError(context, error);
    }
  },
};

const { dispatch } = getStoreAccessors<ItemState, State>("");

export const dispatchUpdateItem = dispatch(actions.actionUpdateItem);
export const dispatchGetToxicItems = dispatch(actions.actionGetToxicItems);
export const dispatchGetToxicItemCount = dispatch(
  actions.actionGetToxicItemCount
);
export const dispatchGetItemById = dispatch(actions.actionGetItemById);
export const dispatchGetEarliestMessageDate = dispatch(
  actions.actionGetEarliestMessageDate
);
export const dispatchGetForwards = dispatch(actions.actionListForwards);
export const dispatchCreateForward = dispatch(actions.actionCreateForward);
export const dispatchGetToxicItemsPerPage = dispatch(
  actions.actionGetToxicItemsPerPage
);
