
import { Component, Vue } from "vue-property-decorator";
import { readUserProfile } from "@/store/main/getters";
import { dispatchGetUserCompany } from "@/store/companies/actions";
import { readUserCompany } from "@/store/companies/getters";

@Component
export default class UserProfile extends Vue {
  public async mounted() {
    if (this.userProfile?.company_id) {
      await dispatchGetUserCompany(this.$store, this.userProfile?.company_id);
    }
  }

  get company() {
    return readUserCompany(this.$store);
  }

  get userProfile() {
    return readUserProfile(this.$store);
  }

  public goToEdit() {
    this.$router.push("/main/company/edit");
  }
}
