import { IScanProgress } from "@/interfaces";
import { AuditState } from "./state";
import { getStoreAccessors } from "typesafe-vuex";
import { State } from "../state";

export const mutations = {
  setScanProgress(state: AuditState, payload: IScanProgress) {
    state.scanProgress = payload;
  },
};

const { commit } = getStoreAccessors<AuditState, State>("");

export const commitSetScanProgress = commit(mutations.setScanProgress);
