import { mutations } from "./mutations";
import { getters } from "./getters";
import { actions } from "./actions";
import { MSState } from "./state";

const defaultState: MSState = {
  msUser: {},
  msUsers: [],
  subscribedUsers: [],
  importedUsers: [],
};

export const msLoginModule = {
  state: defaultState,
  mutations,
  actions,
  getters,
};
