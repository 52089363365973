
import { Component, Vue } from "vue-property-decorator";
import { readUserProfile } from "@/store/main/getters";
import { readImportUser, readUserCompany } from "@/store/companies/getters";
import ModeratorOptions from "@/components/ModeratorOptions.vue";

@Component({
  components: { ModeratorOptions },
})
export default class CompanyUsers extends Vue {
  public fileCountsData: any = {
    total_count: 0,
    total_invalid_mail: 0,
    total_valid_mail: 0,
  };
  public selectedModeratorOption: any = "";
  public uploadCsvTriggered = false;
  public csvUploaded = false;

  get company() {
    return readUserCompany(this.$store);
  }

  get userProfile() {
    return readUserProfile(this.$store);
  }

  public async fileCounts(res: any) {
    this.fileCountsData = res;
  }

  public async selectedElement(res: any) {
    this.selectedModeratorOption = res;
  }

  public async isCsvUploaded(res: any) {
    this.csvUploaded = res;
    this.uploadCsvTriggered = res;
  }

  get importUsers() {
    return readImportUser(this.$store);
  }
}
