import { mutations } from "./mutations";
import { getters } from "./getters";
import { actions } from "./actions";
import { MicrosoftTeamsItemState } from "./state";

export const defaultMicrosoftTeamsItemState: MicrosoftTeamsItemState = {
  item: {
    uuid: "",
    created_at: "",
    updated_at: null,
    teams_chat_id: "",
    text: "",
    html: "",
    is_read: null,
    is_archived: null,
    is_user_flagged: null,
    subject: null,
    message_sender: "",
    channel_name: "",
    team_name: "",
    message_recipients: [],
  },
  toxicItems: {
    review: [],
    archived: [],
    flagged: [],
    all: [],
  },
  toxicItemsLoading: false,
  earliestMessageDate: null,
  forwards: [],
  itemCount: { review: 0, archived: 0, flagged: 0, all: 0 },
};

export const microsoftTeamsItemModule = {
  state: defaultMicrosoftTeamsItemState,
  mutations,
  actions,
  getters,
};
