
import { Component, Vue, Prop } from "vue-property-decorator";
import { IUserProfile } from "@/interfaces";
import {
  dispatchGetModerators,
  dispatchCreateModerator,
  dispatchRemoveModerator,
} from "@/store/moderators/actions";
import { readModerators } from "@/store/moderators/getters";
import { dispatchGetUserCompany } from "@/store/main/actions";
import { readCompany } from "@/store/main/getters";

@Component
export default class Moderators extends Vue {
  @Prop() public readonly userProfile: IUserProfile;
  @Prop() public readonly users: IUserProfile[];
  public selectedUser = {};
  public chipsTrigger = true;
  public selectedUsers: string[] = [];
  public enteredName = "";
  public enteredEmail = "";
  public emailRules = [
    (v) => !!v || "E-mail is required",
    (v) => /.+@.+/.test(v) || "E-mail must be valid",
  ];
  public fullNameRule = [(v) => !!v || "Full name is required"];
  public addedMod: string | null = null;

  public isActive(email: string) {
    return this.addedMod === email;
  }

  public async mounted() {
    if (!this.$store.getters.company?.company_id) {
      await dispatchGetUserCompany(this.$store);
    }
    await dispatchGetModerators(this.$store, this.company.company_id);
  }

  get moderators() {
    return readModerators(this.$store);
  }

  get company() {
    return readCompany(this.$store);
  }

  public async removeModerator(email: string) {
    await dispatchRemoveModerator(this.$store, email).then((res) => {
      dispatchGetModerators(this.$store, this.company.company_id);
    });
  }

  public reset() {
    const refForm: any = this.$refs.form;
    refForm.reset();
  }

  public resetValidator() {
    const refForm: any = this.$refs.form;
    refForm.resetValidation();
  }

  public async addModerator() {
    const refForm: any = this.$refs.form;
    if (refForm.validate()) {
      const updatedForm = {
        email: this.enteredEmail,
        moderatorgroup: "External",
        full_name: this.enteredName,
      };
      if (this.enteredName.length > 0) {
        updatedForm.full_name = this.enteredName;
      }
      await dispatchCreateModerator(this.$store, updatedForm).then((res) => {
        this.addedMod = updatedForm.email;
        setTimeout(() => (this.addedMod = null), 2000);
        dispatchGetModerators(this.$store, this.company.company_id);
        this.reset();
        this.resetValidator();
      });
    }
  }
}
