export interface IUserProfile {
  email: string;
  is_active: boolean;
  is_superuser: boolean;
  full_name: string;
  id?: number;
  company_id: number;
  is_company_admin: boolean;
  company?: string;
  src_user_id?: string;
  notification_frequency_text: Frequency;
  notification_frequency_email: Frequency;
}

export enum Frequency { 
  Never = 'never', 
  Daily = 'daily', 
  Weekly = 'weekly', 
  Immediately = 'immediate' 
}

export interface IUserProfileCreate {
  email: string;
  full_name?: string;
  is_subscribed?: boolean;
  is_active?: boolean;
  is_superuser?: boolean;
  company_id?: number;
  is_company_admin?: boolean;
  src_user_id?: string;
}

export interface IToxicItems {
  review: IMessageFlaggedEvent[];
  archived: IMessageFlaggedEvent[];
  flagged: IMessageFlaggedEvent[];
  all: IMessageFlaggedEvent[];
}

export interface IMessageFlaggedEvent {
  uuid: string;
  email_created_at: string;
  email_sender: string;
  email_recipients: string[];
  is_read: boolean;
  is_user_flagged: boolean;
  is_archived: boolean;
  subject: string;
  body: string;
  body_html: string;
}

export interface IItemUpdate {
  uuid: string;
  is_read?: boolean;
  is_user_flagged?: boolean;
  is_archived?: boolean;
}

export interface ToxicItemCount {
  review: number;
  archived: number;
  flagged: number;
  all: number;
}

export interface IMicrosoftTeamsToxicItems {
  review: IMicrosoftTeamsItem[];
  archived: IMicrosoftTeamsItem[];
  flagged: IMicrosoftTeamsItem[];
  all: IMicrosoftTeamsItem[];
}

export interface IMicrosoftTeamsItem {
  uuid: string;
  created_at: string;
  updated_at: string;
  teams_chat_id: string;
  text: string;
  html: string;
  subject?: string;
  is_user_flagged?: boolean;
  is_read?: boolean;
  is_archived?: boolean;
  message_sender: string;
  channel_name?: string;
  team_name?: string;
  message_recipients: string[];
}

export interface IMicrosoftTeamsItemUpdate {
  uuid: string;
  is_user_flagged?: boolean;
  is_read?: boolean;
  is_archived?: boolean;
}

export interface MicrosoftTeamsToxicItemCount {
  review: number;
  archived: number;
  flagged: number;
  all: number;
}

export interface IMicrosoftTeamsForward {
  uuid: string;
  teams_chat_id: string;
  company_id?: number;
  created_at?: string;
  message_recipients?: string[];
  message_sender?: string;
}

export interface ICompanyProfile {
  company_name: string;
  company_id: number;
  description: string;
  company_description?: string;
  mail_server_admin: string;
  is_onboarded?: boolean;
  tenant_id?: string;
  is_audit_completed?: boolean;
  company_services?: string;
  allowed_domains: string;
  gmail_client_id: string;
  audit_only?: boolean;
}

export interface ICompanyO365Verification {
  is_o365_verified?: boolean;
}

export interface ICompanyTeamsVerification {
  is_teams_verified?: boolean;
}

export interface ImportUser {
  user_imported: boolean;
}

export interface ICompanyCreate {
  name: string;
  mail_server_admin: string;
  description?: string;
  tenant_id?: string;
  is_onboarded?: boolean;
  create_account: boolean;
  moderator_email?: string;
  moderator_name?: string;
  allowed_domains: string;
  company_services: string;
  audit_only?: boolean;
}

export interface ICompanyUpdate {
  id?: number;
  company_name?: string;
  company_description?: string;
  mail_server_admin?: string;
  is_onboarded?: boolean;
  tenant_id?: string;
  is_audit_completed?: boolean;
  allowed_domains?: string;
  company_services?: string;
  audit_only?: boolean;
  is_credentials_verified?: boolean;
}

export interface ICountData {
  todaysCount?: number | null;
  weeklyCount?: number | null;
  allTimeCount?: number | null;
}

export interface IMicrosoftTeamsCountData {
  todaysCount?: number | null;
  weeklyCount?: number | null;
  allTimeCount?: number | null;
}

export interface AuditTrigger {
  company_id: number;
  import_users: boolean;
  audit_email: boolean;
  audit_teams: boolean;
  audit_teams_direct_messages: boolean;
  subscribe_to_events: boolean;
  tenant_id: string;
  audit_days?: number;
  start_date?: string;
}

export interface ICompanyDashSearch {
  currentTabId?: 0 | number;
  timeframe?: null | number;
  label: null | string;
  currentPage?: {
    review: number;
    flagged: number;
    archived: number;
    all: number;
  };
}

export interface ICompanyTeamsDashSearch {
  currentTabId?: 0 | number;
  timeframe?: null | number;
  label: null | string;
  currentPage?: {
    review: number;
    flagged: number;
    archived: number;
    all: number;
  };
}

export interface IModerator {
  id: number;
  email: string;
  phone_number: string | null;
  full_name: string | null;
  moderatorgroup: string | null;
  company_id: number | null;
  notification_frequency_text: Frequency;
  notification_frequency_email: Frequency;
}

export interface IModeratorCreate {
  email: string;
  phone_number?: string | null;
  full_name?: string | null;
  moderatorgroup?: string | null;
  company_id?: number | null;
  is_superuser?: boolean | null;
  notification_frequency_text?: Frequency;
  notification_frequency_email?: Frequency;
}

export interface IModeratorUpdate {
  id?: number;
  email?: string;
  full_name?: string;
  moderatorgroup?: string;
  phone_number?: string;
  company_id?: number;
  user_pool_id?: string;
  user_name?: string;
  group_name?: string;
  notification_frequency_text?: Frequency;
  notification_frequency_email?: Frequency;
}

export interface IExcluded {
  id?: number;
  email?: string;
  company_id?: number;
}

export interface IKeyword {
  uuid?: string;
  keyword?: string;
}

export interface IServiceNowUser {
  id?: number;
  username?: string;
  company_id?: number;
  hashed_password?: string;
  verified?: boolean;
  domain_list?: string;
}

export interface IServiceNowUpdate {
  company_id: number;
  username: string;
  password?: string;
  verified: boolean;
  domain_list: string;
}

export interface IServiceNowCreate {
  company_id: number;
  username: string;
  password?: string;
  verified: boolean;
  domain_list: string;
}

export interface IScanProgress {
  scan_job_id?: number;
  company_id?: number;
  start_date?: string;
  end_date?: string;
  status?: string;
  last_completed_date?: string;
  total_days?: number;
  days_completed?: number;
}

export interface IForward {
  id: number;
  item_id: number;
  company_id?: number;
  created_at?: string;
  recipients?: string[];
  sender?: string;
}

export interface IForwardCreate {
  item_id: number | string;
  recipients: string[];
  sender: string;
}

export interface ISentiment {
  team_name?: string;
  total_items_evaluated: number;
  average_positive: number;
  average_neutral: number;
  average_negative: number;
  grade?: string;
  benchmark?: string;
  timeframe?: number;
}
