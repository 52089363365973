
import { Component, Vue } from "vue-property-decorator";
import { appName } from "@/env";
import {
  readLoginError,
  readNewPasswordRequired,
  readCompany,
} from "@/store/main/getters";
import {
  dispatchLogIn,
  dispatchSetLoginError,
} from "@/store/main/actions";
import router from "@/router";

@Component
export default class Login extends Vue {
  public valid = true;
  public email = "";
  public password = "";
  public appName = appName;
  public signInLoading = false;
  public showPassword = false;
  public regex =
    /^(?=.*[a-z ])(?=.*[A-Z ])(?=.*[0-9])(?=.*[=+\-^$*.\[\]{}()?"!@#%&\/\\,><':;|_~`]).{8,99}$/;

  public get loginError() {
    return readLoginError(this.$store);
  }

  public async submit() {
    if (!(await this.$validator.validateAll())) {
      return;
    }
    this.signInLoading = true;
    await dispatchLogIn(this.$store, {
      username: this.email.trim(),
      password: this.password,
    }).then(
      (response) => {
        this.signInLoading = false;
        if (readNewPasswordRequired(this.$store)) {
          this.$router
            .push({
              path: "/change-password",
              params: { email: this.email.trim() },
            })
            .catch((err) => err);
          this.$router
            .push({
              name: "Change Password Required",
              params: { email: this.email.trim(), tempPassword: this.password },
            })
            .catch((err) => err);
        }
      },
      () => {
        this.signInLoading = false;
      }
    );
    if (this.company && !this.company.is_onboarded) {
      router.push("/onboarding");
    }
  }

  public get newPasswordRequired() {
    return readNewPasswordRequired(this.$store);
  }

  get company() {
    return readCompany(this.$store);
  }

  public async destroyed() {
    await dispatchSetLoginError(this.$store, false);
  }
}
