// import { api } from '@/api';
import { ActionContext } from "vuex";
import { State } from "../state";
import { AdminState } from "./state";
import { getStoreAccessors } from "typesafe-vuex";
import { dispatchCheckApiError } from "../main/actions";
import { commitAddNotification } from "../main/mutations";
import axios from "../interceptor";

type MainContext = ActionContext<AdminState, State>;

export const actions = {
  async actionVerifyGmail(context: MainContext, mail_server_admin: string) {
    try {
      const response = await axios.post("/company/verify_gmail_services", {
        mail_server_admin,
      });
      if (!response.data) {
        const error = { content: `Email is not recognized`, color: "error" };
        commitAddNotification(context, error);
        return response;
      }
      return response;
    } catch (error) {
      await dispatchCheckApiError(context, error);
    }
  },
};

const { dispatch } = getStoreAccessors<AdminState, State>("");

export const dispatchVerifyGmail = dispatch(actions.actionVerifyGmail);
