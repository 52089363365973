
import { Component, Vue } from "vue-property-decorator";
import { store } from "@/store";
import {
  dispatchCheckLoggedIn,
  dispatchGetUserCompany,
} from "@/store/main/actions";
import {
  readIsLoggedIn,
  readIsOnboarded,
  readNewPasswordRequired,
} from "@/store/main/getters";

const startRouteGuard = async (
  to: any,
  from: any,
  next: any
): Promise<void> => {
  await dispatchCheckLoggedIn(store);
  if (readIsLoggedIn(store)) {
    await dispatchGetUserCompany(store);
  }
  if (
    readIsLoggedIn(store) &&
    !readIsOnboarded(store) &&
    store.getters.userProfile?.company_id
  ) {
    if (to.path === "/" || (to.path as string).startsWith("/main")) {
      next("/onboarding");
    } else {
      next();
    }
  } else if (readIsLoggedIn(store) && store.getters.userProfile?.company_id) {
    if (
      to.path === "/login" ||
      to.path === "/change-password" ||
      to.path === "/"
    ) {
      next("/main/company/sentiment");
    } else {
      next();
    }
  } else if (
    readIsLoggedIn(store) === false &&
    readNewPasswordRequired(store)
  ) {
    next();
  } else if (readIsLoggedIn(store) === false) {
    if (
      to.path === "/" ||
      (to.path as string).startsWith("/main") ||
      to.path === "/onboarding"
    ) {
      next("/login");
    } else {
      next();
    }
  } else if (readIsLoggedIn(store) && !store.getters.userProfile?.company_id) {
    next();
  }
};

@Component
export default class Start extends Vue {
  public beforeRouteEnter(to: any, from: any, next: any) {
    startRouteGuard(to, from, next);
  }

  public beforeRouteUpdate({
    to,
    from,
    next,
  }: {
    to: any;
    from: any;
    next: any;
  }) {
    startRouteGuard(to, from, next);
  }
}
