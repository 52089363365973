import { mutations } from "./mutations";
import { getters } from "./getters";
import { actions } from "./actions";
import { CountsState } from "./state";

const defaultState: CountsState = {
  companyCountsSummary: {},
  microsoftTeamsCompanyCountsSummary: {},
};

export const countsModule = {
  state: defaultState,
  mutations,
  actions,
  getters,
};
