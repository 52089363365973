import moment from "moment";

export const commonFunctions = {
  /**
   * This function convert your local time to utc time
   * @param dateTime
   * @returns
   */
  convertLocalTimeToUtc(dateTime: any) {
    return moment(dateTime).utc();
  },

  convertUTCToLocalTime(dateTime: string) {
    return moment(dateTime).utc().local().toISOString();
  },
  /**
   * This function use to get current month dates in UTC time
   * @returns
   */
  currentMonthDates() {
    const firstDay: any = moment().startOf("month").utc().toISOString();
    const lastDay: any = moment().endOf("month").utc().toISOString();
    return {
      start_date: firstDay,
      end_date: lastDay,
    };
  },

  /**
   * This function use to get current week dates in UTC time
   * @returns
   */
  currentWeekDates() {
    const firstDay: any = moment().startOf("week").utc().toISOString();
    const lastDay: any = moment().endOf("week").utc().toISOString();
    return {
      start_date: firstDay,
      end_date: lastDay,
    };
  },

  /**
   * This function use to get current date in UTC time
   * @returns
   */
  todayDates() {
    const startDay = moment().startOf("day").utc().toISOString();
    const endDay = moment().endOf("day").utc().toISOString();
    return {
      start_date: startDay,
      end_date: endDay,
    };
  },
};
