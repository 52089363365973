
import { Component, Vue, Prop } from "vue-property-decorator";
import { readExcludedEmails } from "@/store/excluded/getters";
import { IUserProfile } from "@/interfaces";
import {
  dispatchGetModerators,
  dispatchUpdateModerator
} from "@/store/moderators/actions";
import {
  readModerators,
  readNonModeratorUsers
} from "@/store/moderators/getters";
import { dispatchGetUserCompany } from "@/store/main/actions";
import { readCompany } from "@/store/main/getters";

@Component
export default class ModeratorSMS extends Vue {
  [x: string]: any;
  public selectedUser = {};
  public selectedUsers: string[] = [];
  @Prop() public readonly userProfile: IUserProfile;
  @Prop() public readonly users: IUserProfile[];
  public phoneNumberRegex =
    /^(\+\d{1,2}\s)?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/;
  public phoneNumberRule = [
    (v) =>
      !v ||
      /^(\+\d{1,2}\s)?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/.test(v) ||
      "Must be a valid phone number"
  ];

  public async mounted() {
    if (!this.company) {
      await dispatchGetUserCompany(this.$store);
    }
    if (this.moderatorList) {
      await dispatchGetModerators(this.$store, this.company.company_id);
    }
  }

  public blurHandler(item) {
    let isValidForm = (
      this.$refs.form as Vue & { validate: () => boolean }
    ).validate();
    this.$emit("isFormValid", isValidForm);
    this.updateModerator(item);
  }

  public async updateModerator(item) {
    const updatedMod = {
      email: item.email,
      id: item.id,
      phone_number: item.phone_number,
      moderatorgroup: item.moderatorgroup,
      full_name: item.full_name
    };
    if (
      updatedMod.phone_number &&
      this.phoneNumberRegex.test(updatedMod.phone_number)
    ) {
      updatedMod.phone_number = item.phone_number.replace(/\D/g, "");
      updatedMod.phone_number = "+1" + updatedMod.phone_number;
      await dispatchUpdateModerator(this.$store, {
        email: item.email,
        moderator: updatedMod
      }).then((res) => {
        dispatchGetModerators(this.$store, this.company.company_id);
        this.resetValidator();
      });
    } else if (!updatedMod.phone_number) {
      updatedMod.phone_number = "";
      await dispatchUpdateModerator(this.$store, {
        email: updatedMod.email,
        moderator: updatedMod
      }).then((res) => {
        dispatchGetModerators(this.$store, this.company.company_id);
        this.resetValidator();
      });
    }
  }

  public reset() {
    const refForm: any = this.$refs.form;
    refForm.reset();
  }

  public resetValidator() {
    const refForm: any = this.$refs.form;
    refForm.resetValidation();
    this.$emit("isFormValid", true);
  }

  get excludedEmails() {
    return readExcludedEmails(this.$store);
  }

  get nonModeratorUsers() {
    return readNonModeratorUsers(this.$store);
  }

  get moderatorList() {
    return readModerators(this.$store);
  }

  get moderators() {
    return readModerators(this.$store);
  }

  get company() {
    return readCompany(this.$store);
  }
}
