import Vue from "vue";
import Vuetify from "vuetify";
import theme from "./theme";

Vue.use(Vuetify, {
  iconfont: "md",
  options: {
    customProperties: true,
  },
  theme,
});
