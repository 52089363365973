
import moment from "moment";
import { Component, Vue, Watch } from "vue-property-decorator";
import { readUserProfile } from "@/store/main/getters";
import { ICompanyDashSearch } from "@/interfaces/index";
import { dispatchGetCompanyCountsSummary } from "@/store/counts/actions";
import { readCompanyCountsSummary } from "@/store/counts/getters";
import { readLastDashboardSearch } from "@/store/companies/getters";
import { readCompany } from "@/store/main/getters";
import {
  dispatchGetToxicItems,
  dispatchGetToxicItemCount,
  dispatchGetToxicItemsPerPage,
} from "@/store/items/actions";
import {
  readToxicItemsLoading,
  readFlaggedMessages,
  readReviewMessages,
  readArchivedMessages,
  readEarliestMessageDate,
  readToxicItemCount,
  readAllMessages,
} from "@/store/items/getters";
import { commitSetToxicItemsLoading } from "@/store/items/mutations";
import "@/assets/variables.scss";
import {
  dispatchChangePageDashboardSearch,
  dispatchSetLastDashboardSearch,
} from "@/store/companies/actions";
import { readScanProgress } from "@/store/audit/getters";
import IncidentPreview from "@/components/IncidentPreview.vue";

@Component({
  components: {
    IncidentPreview,
  },
})
export default class CompanyDashboard extends Vue {
  updatedItems: any;
  public search = "";
  public pagination = {
    current: 1,
    total: 0,
    perPageItems: 10,
  };
  public tabs = [
    {
      title: "Review",
      id: 0,
      text: "unread",
      type: "review",
      count: 0,
    },
    {
      title: "Flagged",
      id: 1,
      text: "flagged",
      type: "flagged",
      count: 0,
    },
    {
      title: "Archived",
      id: 2,
      text: "archived",
      type: "archived",
      count: 0,
    },
    {
      title: "All",
      id: 3,
      text: "all",
      type: "all",
      count: 0,
    },
  ];

  public currentTab =
    this.tabs.find((tab) => tab.id === this.lastDashboardSearch.currentTabId) ||
    this.tabs[0];

  public companyDashSearch: ICompanyDashSearch = this.lastDashboardSearch;

  timeframeItems: any[] = [
    { label: "Today", value: 1 },
    { label: "7 Days", value: 7 },
    { label: "30 Days", value: 30 },
    { label: "90 Days", value: 90 },
    { label: "6 Months", value: 180 },
    { label: "1 Year", value: 365 },
    { label: "All", value: null },
  ];

  messagesError = false;
  startDate;
  endDate;
  countsSummaryError = false;
  imageUrl = "@/assets/roboto.svg";
  pollingTimer = null;

  async mounted() {
    this.loadInitialData();
  }

  public async loadInitialData() {
    if (this.$router.currentRoute.params?.tabName) {
      this.currentTab = this.tabs.find(
        (tab) => tab.type === this.$router.currentRoute.params?.tabName
      );
      this.companyDashSearch.currentTabId = this.currentTab.id;
      dispatchSetLastDashboardSearch(this.$store, this.companyDashSearch);

      this.getToxicItemCount();
      this.updateAllPages();
      this.updateCounts();
    } else {
      this.currentTab = this.tabs.find(
        (tab) => tab.id === this.lastDashboardSearch.currentTabId
      );
      this.pagination.current =
        this.lastDashboardSearch.currentPage[this.currentTab.type];
    }
    this.setTabCounts();
  }

  async changePage() {
    await dispatchChangePageDashboardSearch(this.$store, {
      page: this.pagination.current,
      tab: this.currentTab.type,
    });
    this.updatePage(this.currentTab.type);
  }

  async updatePage(tabName) {
    const offset = this.lastDashboardSearch.currentPage[tabName] - 1;

    commitSetToxicItemsLoading(this.$store, true);
    const startDate = moment()
      .subtract(this.lastDashboardSearch!.timeframe!, "days")
      .startOf("day")
      .utc()
      .format();
    const form = {
      start: startDate,
      limit: 10,
      offset: offset * 10,
      type: tabName,
      searchInput: this.search
    };
    if (
      this.lastDashboardSearch!.timeframe == null &&
      this.lastDashboardSearch!.label == "all"
    ) {
      form.start = null;
    }
    await dispatchGetToxicItemsPerPage(this.$store, form).then((res: any) => {
      if (res === "error") {
        this.messagesError = true;
      }
    });
  }

  async updateAllPages() {
    this.updatePage("all");
    this.updatePage("archived");
    this.updatePage("flagged");
    this.updatePage("review");
  }

  async changeTab(tab: any) {
    this.currentTab = tab;
    this.companyDashSearch.currentTabId = this.currentTab.id;
    this.pagination.current =
      this.lastDashboardSearch.currentPage[this.currentTab.type];
    await dispatchSetLastDashboardSearch(this.$store, this.companyDashSearch);
  }

  private resetPage() {
    this.pagination.current = 1;
    this.companyDashSearch.currentPage.all = 1;
    this.companyDashSearch.currentPage.review = 1;
    this.companyDashSearch.currentPage.flagged = 1;
    this.companyDashSearch.currentPage.archived = 1;
  }

  private setToxicItemsLoading() {
    commitSetToxicItemsLoading(this.$store, true);
  }

  async updateIncidentsAndCounts() {
    await dispatchChangePageDashboardSearch(this.$store, {
      page: 1,
      tab: this.currentTab.type,
    });
    this.setToxicItemsLoading();
    this.updateIncidents();
    this.updateCounts();
    this.resetPage();
  }

  async refreshIncidents() {
    if (this.companyDashSearch.timeframe != null) {
      this.companyDashSearch.label = this.companyDashSearch.timeframe + " Days";
    } else {
      this.companyDashSearch.label = "all";
    }
    this.setToxicItemsLoading();
    this.resetPage();
    this.updateIncidents();

    await dispatchSetLastDashboardSearch(this.$store, this.companyDashSearch);
  }

  private async updateIncidents() {
    await this.getToxicItemCount(this.search);

    if (this.lastDashboardSearch.currentPage[this.currentTab.type]) {
      this.pagination.current =
        this.lastDashboardSearch.currentPage[this.currentTab.type];
    }
    await this.getLatestItems(this.pagination.current);
    this.messagesError = false;
  }

  private async updateCounts() {
    this.countsSummaryError = false;

    await dispatchGetCompanyCountsSummary(this.$store, {
      companyId: this.company.company_id,
    }).then((res) => {
      if (res === "error") {
        this.countsSummaryError = true;
      }
    });
  }

  paginator(items, currentPage, perPageItems) {
    return items;
  }

  private async getLatestItems(page) {
    commitSetToxicItemsLoading(this.$store, true);
    const offset = page - 1;
    const startDate = moment()
      .subtract(this.companyDashSearch!.timeframe!, "days")
      .startOf("day")
      .utc()
      .format();
    const form = {
      start: startDate,
      limit: 10,
      offset: offset * 10,
      searchInput: this.search
    };
    if (
      this.lastDashboardSearch!.timeframe == null &&
      this.lastDashboardSearch!.label == "all"
    ) {
      form.start = null;
    }
    await dispatchGetToxicItems(this.$store, form).then((res: any) => {
      if (res === "error") {
        this.messagesError = true;
      }
    });
  }

  /**
   * This function is used to get count of tabs
   */
  async getToxicItemCount(searchInput?) {
    const startDate = moment()
      .subtract(this.companyDashSearch!.timeframe!, "days")
      .startOf("day")
      .utc()
      .format();
    const payload = {
      start: startDate,
      searchInput: searchInput
    };
    if (
      this.companyDashSearch!.timeframe == null &&
      this.companyDashSearch!.label == "all"
    ) {
      payload.start = null;
    }
    await dispatchGetToxicItemCount(this.$store, payload);
    this.setTabCounts();
  }

  @Watch("toxicItemCount")
  onPropertyChange() {
    this.setTabCounts();
  }

  /**
   * This function is used to set tabs count values
   */
  setTabCounts() {
    this.tabs[0].count = this.toxicItemCount.review;
    this.tabs[1].count = this.toxicItemCount.flagged;
    this.tabs[2].count = this.toxicItemCount.archived;
    this.tabs[3].count = this.toxicItemCount.all;
  }

  async searchItems(){
    this.getToxicItemCount(this.search);
    this.setTabCounts();
    this.updateAllPages()
  }

  get selectedMessages() {
    if (this.currentTab.text === "unread") {
      return this.reviewMessages;
    } else if (this.currentTab.text === "flagged") {
      return this.flaggedMessages;
    } else if (this.currentTab.text === "archived") {
      return this.archivedMessages;
    } else if (this.currentTab.text === "all") {
      return this.allMessages;
    }
  }

  get totalPages() {
    return Math.ceil(this.currentTab.count / this.pagination.perPageItems) || 1;
  }

  get breakpoint() {
    return this.$vuetify.breakpoint;
  }

  get totalVisiblePaginator() {
    if (this.breakpoint.xs) {
      return 6;
    } else if (this.breakpoint.sm) {
      return 8;
    } else if (this.breakpoint.md) {
      return 10;
    } else if (this.breakpoint.lg || this.breakpoint.xl) {
      return 15;
    }
  }

  get company() {
    return readCompany(this.$store);
  }

  get scanProgress() {
    const scanProgress = readScanProgress(this.$store);
    return scanProgress;
  }

  get userProfile() {
    return readUserProfile(this.$store);
  }

  get companyCountsSummary() {
    return readCompanyCountsSummary(this.$store);
  }

  get flaggedMessages() {
    return readFlaggedMessages(this.$store);
  }

  get reviewMessages() {
    return readReviewMessages(this.$store);
  }

  get archivedMessages() {
    return readArchivedMessages(this.$store);
  }

  get allMessages() {
    return readAllMessages(this.$store);
  }

  get toxicItemCount() {
    return readToxicItemCount(this.$store);
  }

  get messagesLoading() {
    return readToxicItemsLoading(this.$store);
  }

  get lastDashboardSearch() {
    return readLastDashboardSearch(this.$store);
  }

  get earliestMessageDate() {
    return readEarliestMessageDate(this.$store);
  }
}
