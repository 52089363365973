import { mutations } from "./mutations";
import { getters } from "./getters";
import { actions } from "./actions";
import { ModeratorState } from "./state";
import { Frequency } from "@/interfaces";

const defaultState: ModeratorState = {
  moderators: [],
  nonModeratorUsers: [],
  moderator: {
    id: null,
    full_name: null,
    company_id: null,
    moderatorgroup: null,
    email: "",
    phone_number: null,
    notification_frequency_email: Frequency.Weekly,
    notification_frequency_text: Frequency.Weekly
  },
};

export const moderatorModule = {
  state: defaultState,
  mutations,
  actions,
  getters,
};
