
import { Component, Vue } from "vue-property-decorator";
import { readForwards } from "@/store/items/getters";
import { readMicrosoftTeamsItemById } from "@/store/teamsItems/getters";
import { readCompany, readUserProfile } from "@/store/main/getters";
import { readAdminUsers } from "@/store/admin/getters";
import { dispatchGetForwards } from "@/store/items/actions";
import {
  dispatchGetMicrosoftTeamsItemById,
  dispatchGetMicrosoftTeamsToxicItemCount,
  dispatchGetMicrosoftTeamsToxicItems,
  dispatchUpdateMicrosoftTeamsItem
} from "@/store/teamsItems/actions";
import moment from "moment";
import ForwardTeamsEmailForm from "@/components/ForwardTeamsEmailForm.vue";
import { IMicrosoftTeamsItemUpdate } from "@/interfaces";
import { dispatchCreateExcluded } from "@/store/excluded/actions";
import { readLastDashboardSearch } from "@/store/companies/getters";
import { dispatchGetMicrosoftTeamsCompanyCountsSummary } from "@/store/counts/actions";

@Component({
  components: {
    ForwardTeamsEmailForm
  }
})
export default class MstItemDetails extends Vue {
  public docDatetime = "";
  public sender = "";
  public recipient = "";
  public date = new Date().toISOString().substr(0, 10);
  public status = "";
  public archivedLoading = false;
  public flaggedLoading = false;
  public reviewLoading = false;
  public loading = true;

  public async mounted() {
    await this.getItemDetails();
    await this.getForwards();

    if (this.itemDetail && !this.itemDetail.is_read!) {
      this.updateItemStatus({
        uuid: this.itemDetail.uuid,
        is_archived: this.itemDetail.is_archived,
        is_user_flagged: this.itemDetail.is_user_flagged,
        is_read: true
      });
    }

    let htmlTag = document.getElementById("htm");
    htmlTag.innerHTML = this.itemDetail.text
      ? this.itemDetail.text
      : this.itemDetail.html;
    this.loading = false;
  }

  public async getForwards() {
    await dispatchGetForwards(this.$store, this.$router.currentRoute.params.id);
  }

  public async getItemDetails() {
    await dispatchGetMicrosoftTeamsItemById(
      this.$store,
      this.$router.currentRoute.params.id
    );
  }

  public async updateItemStatus(updatedItem: IMicrosoftTeamsItemUpdate) {
    if (updatedItem.is_archived) {
      this.archivedLoading = true;
    } else if (updatedItem.is_user_flagged) {
      this.flaggedLoading = true;
    }
    await dispatchUpdateMicrosoftTeamsItem(this.$store, updatedItem);
    this.archivedLoading = false;
    this.flaggedLoading = false;
  }

  stringToHTML(str) {
    var parser = new DOMParser();
    var doc = parser.parseFromString(str, "text/html");
    return doc.body;
  }

  public async submitUpdateItemStatus(
    updatedItem: IMicrosoftTeamsItemUpdate,
    tab: string
  ) {
    await this.updateItemStatus(updatedItem).then((x) => {
      this.navigateToDashboard(tab);
    });
  }

  public async returnToReview(id: string) {
    this.reviewLoading = true;
    const payload: IMicrosoftTeamsItemUpdate = {
      uuid: id,
      is_archived: false,
      is_user_flagged: false,
      is_read: true
    };
    await dispatchUpdateMicrosoftTeamsItem(this.$store, payload);
    this.reviewLoading = false;
    this.navigateToDashboard("review");
  }

  public navigateToDashboard(tabName: string) {
    this.$router
      .push({ name: "Microsoft Teams Incidents", params: { tabName: tabName } })
      .catch((err) => err);
  }

  public formatTimestamp(datetime) {
    if (datetime) {
      return moment(datetime).local().format("MMMM D, YYYY - h:mm a");
    } else {
      return null;
    }
  }

  get users() {
    return readAdminUsers(this.$store);
  }

  get userProfile() {
    return readUserProfile(this.$store);
  }

  get itemDetail() {
    return readMicrosoftTeamsItemById(this.$store);
  }

  public async ignore() {
    const startDate = moment()
      .subtract(this.lastDashboardSearch!.timeframe!, "days")
      .startOf("day")
      .utc()
      .format();
    let promises = [];
    promises.push([
      dispatchCreateExcluded(this.$store, {
        email: this.itemDetail.message_sender,
        company_id: this.userProfile.company_id
      }),
      dispatchGetMicrosoftTeamsCompanyCountsSummary(this.$store, {
        companyId: this.company.company_id
      }),
      dispatchGetMicrosoftTeamsToxicItemCount(this.$store, {
        start: startDate
      }),
      dispatchGetMicrosoftTeamsToxicItems(this.$store, {
        start: startDate,
        limit: 10,
        offset: 0
      })
    ]);
    await Promise.all(promises);
  }

  get company() {
    return readCompany(this.$store);
  }

  get forwards() {
    return readForwards(this.$store);
  }
  get lastDashboardSearch() {
    return readLastDashboardSearch(this.$store);
  }

  public back() {
    this.$router.go(-1);
  }
}
