import { mutations } from "./mutations";
import { getters } from "./getters";
import { actions } from "./actions";
import { AuditState } from "./state";

const defaultState: AuditState = {
  scanProgress: {},
};

export const auditModule = {
  state: defaultState,
  mutations,
  actions,
  getters,
};
