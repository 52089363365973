import { mutations } from "./mutations";
import { getters } from "./getters";
import { actions } from "./actions";
import { ServiceNowState } from "./state";

const defaultState: ServiceNowState = {
  serviceNowUser: {},
  verified: null,
};

export const serviceNowModule = {
  state: defaultState,
  mutations,
  actions,
  getters,
};
