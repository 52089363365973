
import { Component, Vue, Prop } from "vue-property-decorator";
import {
  IUserProfile,
  IServiceNowUpdate,
  IServiceNowCreate
} from "@/interfaces";
import { dispatchGetUserCompany } from "@/store/main/actions";
import { readCompany } from "@/store/main/getters";
import { readServiceNowUser } from "@/store/serviceNow/getters";
import {
  dispatchGetServiceNowUser,
  dispatchUpdateServiceNowUser,
  dispatchCreateServiceNowUser,
  dispatchRemoveServiceNowUser
} from "@/store/serviceNow/actions";

@Component
export default class ServiceNowForm extends Vue {
  @Prop() public readonly userProfile: IUserProfile;
  public isLoading = true;
  public displayDisconnectAlert = false;

  public snUsername = "";
  public snPassword = "";
  public snDomainList = [""];
  public snVerified = false;

  public editUsername = false;
  public editPassword = false;
  public passwordPlaceholder = "***************";
  public editDomainList = false;

  public domainList = "";
  public domainListRules = [(v) => !!v || "At least one domain is required"];
  public usernameRules = [(v) => !!v || "Username is required"];
  public passwordRules = [(v) => !!v || "Password is required"];

  public urlPrefixHint = "Enter the prefix(es) for your ServiceNow application";

  public submitDisabled = false;

  public async mounted() {
    if (!this.company) {
      await dispatchGetUserCompany(this.$store);
    }
    await this.getServiceNowUser();

    if (!this.serviceNowUser.company_id) {
      this.reset("create");
    } else {
      this.editPassword = true;
      // this.verifyServiceNowAccount();
    }
  }

  public editField(fieldName: string) {
    if (fieldName === "username") {
      this.editUsername = !this.editUsername;
    } else if (fieldName === "password") {
      this.editPassword = !this.editPassword;
      if (!this.editPassword) {
        this.snPassword = "";
      }
    } else if (fieldName === "domainList") {
      this.editDomainList = !this.editDomainList;
    }
  }

  public clearPassword() {
    this.editPassword = !this.editPassword;
    if (!this.editPassword) {
      this.snPassword = "";
    }
  }

  public clearUsername() {
    this.editUsername = !this.editUsername;
    if (!this.editUsername) {
      this.snUsername = this.serviceNowUser.username;
    }
  }

  public addSlot() {
    this.snDomainList.push("");
  }

  public removeSlot(index) {
    this.snDomainList.splice(index, 1);
  }

  get company() {
    return readCompany(this.$store);
  }

  get serviceNowUser() {
    return readServiceNowUser(this.$store);
  }

  get showPassword() {
    if (!this.serviceNowUser.id && this.snPassword.length < 1) {
      return true;
    } else {
      return false;
    }
  }

  public reset(formType: string) {
    if (formType === "edit") {
      this.snUsername = this.serviceNowUser.username;
      this.snDomainList = this.serviceNowUser.domain_list.split(",");
      this.snVerified = this.serviceNowUser.verified;
      this.editDomainList = false;
      this.editUsername = false;
      this.editPassword = false;
      this.snPassword = null;
    } else {
      this.snUsername = "";
      this.snDomainList = [""];
      this.snPassword = "";
      const refForm: any = this.$refs.form;
      refForm.resetValidation();
    }
    // this.resetValidator();
  }

  public async submit(formType: string) {
    this.submitDisabled = true;
    if (formType === "edit") {
      await this.updateServiceNowUser();
      // await this.verifyServiceNowAccount();
      this.submitDisabled = false;
    } else if (formType === "create") {
      await this.createServiceNowUser();
      // await this.verifyServiceNowAccount();
      this.submitDisabled = false;
    }
  }

  public async getServiceNowUser() {
    this.isLoading = true;
    try {
      await dispatchGetServiceNowUser(
        this.$store,
        this.userProfile.company_id
      ).then((res: any) => {
        if (this.serviceNowUser) {
          this.snUsername = this.serviceNowUser.username;
          this.snDomainList = this.serviceNowUser.domain_list.split(",");
          this.snVerified = this.serviceNowUser.verified;          
        }
        this.isLoading = false;
      });
    } catch (error) {
      this.isLoading = false;
    }
  }

  public async updateServiceNowUser() {
    const updateForm: IServiceNowUpdate = {
      company_id: this.userProfile.company_id,
      username: this.snUsername,
      verified: false,
      domain_list: this.snDomainList
        .filter((e) => e)
        .map((e) => e.trim())
        .join(",")
    };
    if (this.snPassword && this.snPassword.length > 0) {
      updateForm.password = this.snPassword;
    }
    await dispatchUpdateServiceNowUser(this.$store, updateForm).then((res) => {
      this.getServiceNowUser();
    });
  }

  public async createServiceNowUser() {
    const createForm: IServiceNowCreate = {
      company_id: this.userProfile.company_id,
      username: this.snUsername,
      verified: false,
      domain_list: this.snDomainList
        .filter((e) => e)
        .map((e) => e.trim())
        .join(","),
      password: null
    };
    if (this.snPassword && this.snPassword.length > 0) {
      createForm.password = this.snPassword;
    }
    await dispatchCreateServiceNowUser(this.$store, createForm).then((res) => {
      this.getServiceNowUser();
    });
  }

  public async disconnect() {
    await dispatchRemoveServiceNowUser(
      this.$store,
      this.userProfile.company_id
    ).then((res) => {
      this.getServiceNowUser();
      this.resetValidator();
    });
  }

  public resetValidator() {
    const refForm: any = this.$refs.form;
    this.snDomainList = [""];
    this.snUsername = "";
    this.snPassword = "";
    refForm.resetValidation();
  }

  // public async verifyServiceNowAccount() {
  //   await dispatchVerifyServiceNowAccount(this.$store, this.userProfile.company_id);
  // }

  public get verified() {
    const verified = this.$store.getters.serviceNowUser.verified;
    if (!verified) {
      this.displayDisconnectAlert = true;
    }
    return verified;
  }
}
