import Vue from "vue";
import Router from "vue-router";
import RouterComponent from "./components/RouterComponent.vue";

import { getAuth } from "firebase/auth";

Vue.use(Router);

async function guardMyroute(to: any, from: any, next: any) {
  const auth = getAuth();
  const token = await auth.currentUser?.getIdToken();

  if (token) {
    next();
  } else {
    next("/login");
  }
}

export default new Router({
  mode: "history",
  base: process.env.BASE_URL,
  routes: [
    {
      path: "/",
      redirect: "login",
      component: () =>
        import(/* webpackChunkName: "start" */ "./views/main/Start.vue"),
      children: [
        {
          path: "login",
          name: "Login",
          props: true,
          // route level code-splitting
          // this generates a separate chunk (about.[hash].js) for this route
          // which is lazy-loaded when the route is visited.
          component: () =>
            import(/* webpackChunkName: "login" */ "./views/Login.vue"),
        },
        {
          path: "change-password",
          name: "Change Password Required",
          props: {
            email: true,
            tempPassword: true,
          },
          component: () =>
            import(
              /* webpackChunkName: "reset-password" */ "./views/ForceChangePassword.vue"
            ),
        },
        {
          path: "recover-password",
          name: "Recover Password",
          props: {
            email: true,
          },
          component: () =>
            import(
              /* webpackChunkName: "recover-password" */ "./views/PasswordRecovery.vue"
            ),
        },
        {
          path: "reset-password",
          name: "Reset Password",
          props: {
            email: true,
          },
          component: () =>
            import(
              /* webpackChunkName: "reset-password" */ "./views/ResetPassword.vue"
            ),
        },
        {
          path: "onboarding",
          name: "Onboarding",
          beforeEnter: guardMyroute,
          component: () =>
            import(
              /* webpackChunkName: "onboarding" */ "./views/main/company/Onboarding.vue"
            ),
        },
        {
          path: "main",
          component: () =>
            import(/* webpackChunkName: "main" */ "./views/main/Main.vue"),
          beforeEnter: guardMyroute,
          children: [
            {
              path: "email",
              redirect: "company/email",
              props: {
                tabName: true,
              },
            },
            {
              path: "items",
              name: "Analyzed Messages",
              component: RouterComponent,
              redirect: "items/view",
              children: [
                {
                  path: "view/:id",
                  name: "Review Incident",
                  props: {
                    id: true,
                  },
                  component: () =>
                    import(
                      /* webpackChunkName: "main-items-detail" */ "./views/main/items/ItemDetails.vue"
                    ),
                },
              ],
            },
            {
              path: "teams/item/",
              name: "Analyzed Teams Messages",
              component: RouterComponent,
              redirect: "teams/item/",
              children: [
                {
                  path: "view/:id",
                  name: "Review MS Teams Incident",
                  props: {
                    id: true,
                  },
                  component: () =>
                    import(
                      /* webpackChunkName: "main-teams-item-detail" */ "./views/main/items/TeamsItemDetails.vue"
                    ),
                },
              ],
            },
            {
              path: "profile",
              component: RouterComponent,
              redirect: "profile/view",
              beforeEnter: guardMyroute,
              children: [
                {
                  path: "view",
                  name: "Profile",
                  component: () =>
                    import(
                      /* webpackChunkName: "main-profile" */ "./views/main/profile/UserProfile.vue"
                    ),
                },
                {
                  path: "edit",
                  name: "Edit Profile",
                  component: () =>
                    import(
                      /* webpackChunkName: "main-profile-edit" */ "./views/main/profile/UserProfileEdit.vue"
                    ),
                },
                {
                  path: "password",
                  name: "Change Password",
                  component: () =>
                    import(
                      /* webpackChunkName: "main-profile-password" */ "./views/main/profile/UserProfileEditPassword.vue"
                    ),
                },
              ],
            },
            {
              path: "admin",
              component: RouterComponent,
              redirect: "admin/users/all",
              children: [
                {
                  path: "users",
                  name: "Manage Staff",
                  redirect: "users/all",
                },
                {
                  path: "users/create",
                  name: "Add New Moderator",
                  component: () =>
                    import(
                      /* webpackChunkName: "main-admin-users-create" */ "./views/main/admin/CreateUser.vue"
                    ),
                },
                {
                  path: "companies",
                  redirect: "companies/all",
                },
                {
                  path: "companies/all",
                  // name: 'main-admin-companies',
                  name: "Manage Companies",
                  component: () =>
                    import(
                      /* webpackChunkName: "main-admin-companies" */ "./views/main/admin/AdminCompanies.vue"
                    ),
                },
                {
                  path: "companies/create",
                  // name: 'main-admin-companies-create',
                  name: "Add a Company",
                  component: () =>
                    import(
                      /* webpackChunkName: "main-admin-companies-create" */ "./views/main/admin/CreateCompany.vue"
                    ),
                },
                {
                  path: "companies/edit/:id",
                  // name: 'main-admin-companies-edit',
                  name: "Edit Companies",
                  component: () =>
                    import(
                      /* webpackChunkName: "main-admin-companies-edit" */ "./views/main/admin/EditCompany.vue"
                    ),
                },
              ],
            },
            {
              path: "company",
              component: RouterComponent,
              redirect: "company/email",
              children: [
                {
                  path: "view",
                  // name: 'main-company',
                  name: "Company Profile",
                  component: () =>
                    import(
                      /* webpackChunkName: "main-company" */ "./views/main/company/CompanyProfile.vue"
                    ),
                },
                {
                  path: "email",
                  name: "Email Incidents",
                  props: {
                    tabName: true,
                  },
                  component: () =>
                    import(
                      /* webpackChunkName: "main-company-email-incidents" */ "./views/main/company/EmailIncidents.vue"
                    ),
                },
                {
                  path: "teams",
                  name: "Microsoft Teams Incidents",
                  props: {
                    tabName: true,
                  },
                  component: () =>
                    import(
                      /* webpackChunkName: "main-company-ms-teams-incidents" */ "./views/main/company/MsTeamsIncidents.vue"
                    ),
                },
                {
                  path: "sentiment",
                  name: "Sentiment",
                  props: {
                    tab: true,
                  },
                  component: () =>
                    import(
                      /* webpackChunkName: "main-company-sentiment" */ "./views/main/company/Sentiment.vue"
                    ),
                },

                {
                  path: "excluded",
                  name: "Do Not Monitor",
                  component: () =>
                    import(
                      /* webpackChunkName: "main-company-excluded-list" */ "./views/main/company/ExcludedList.vue"
                    ),
                },
                {
                  path: "custom-keyword-scan",
                  name: "Monitor Keywords",
                  component: () =>
                    import(
                      /* webpackChunkName: "main-company-custom-keyword-scan" */ "./components/CustomKeywordScan.vue"
                    ),
                },
                {
                  path: "inclusion",
                  name: "Inclusion List",
                  component: () =>
                    import(
                      /* webpackChunkName: "main-company-inclusion-list" */ "./views/main/company/InclusionList.vue"
                    ),
                },
                {
                  path: "moderators",
                  name: "Moderators",
                  component: () =>
                    import(
                      /* webpackChunkName: "main-company-moderators" */ "./views/main/company/ModeratorSettings.vue"
                    ),
                },
                {
                  path: "sms-notifications",
                  name: "Text Notifications",
                  component: () =>
                    import(
                      /* webpackChunkName: "main-company-sms-notifications" */ "./views/main/company/SmsSettings.vue"
                    ),
                },
                {
                  path: "service-now",
                  name: "Connect ServiceNow® Account",
                  component: () =>
                    import(
                      /* webpackChunkName: "main-company-service-now" */ "./views/main/company/ServiceNowConfig.vue"
                    ),
                },
              ],
            },
          ],
        },
      ],
    },
    {
      path: "/*",
      redirect: "/login",
    },
  ],
});
