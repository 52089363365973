import axios from "axios";
import { getAuth } from "firebase/auth";

import { apiUrl } from "../env";

const instance = axios.create({
  baseURL: `${apiUrl}`,
});

instance.interceptors.request.use(
  async (config) => {
    const auth = getAuth();
    const token = await auth.currentUser?.getIdToken();

    if (token) {
      config.headers["Authorization"] = `Bearer ${token}`;
    }
    return config;
  },
  function (error) {
    // Do something with request error
    return Promise.reject(error);
  }
);

instance.interceptors.response.use(
  function (response) {
    // Any status code that lie within the range of 2xx cause this function to trigger
    // Do something with response data
    return response;
  },
  function (error) {
    // Any status codes that falls outside the range of 2xx cause this function to trigger
    // Do something with response error
    return Promise.reject(error);
  }
);
export default instance;
