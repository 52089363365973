import { ICompanyProfile, IUserProfile } from "@/interfaces";
import { MainState, AppNotification } from "./state";
import { getStoreAccessors } from "typesafe-vuex";
import { State } from "../state";

export const mutations = {
  setToken(state: MainState, payload: string) {
    state.token = payload;
  },
  setEmail(state: MainState, payload: string) {
    state.email = payload;
  },
  setLoggedIn(state: MainState, payload: boolean) {
    state.isLoggedIn = payload;
  },
  setLogInError(state: MainState, payload: boolean) {
    state.logInError = payload;
  },
  setUserProfile(state: MainState, payload: IUserProfile) {
    state.userProfile = payload;
  },
  setDashboardMiniDrawer(state: MainState, payload: boolean) {
    state.dashboardMiniDrawer = payload;
  },
  setDashboardShowDrawer(state: MainState, payload: boolean) {
    state.dashboardShowDrawer = payload;
  },
  addNotification(state: MainState, payload: AppNotification) {
    state.notifications.push(payload);
  },
  removeNotification(state: MainState, payload: AppNotification) {
    state.notifications = state.notifications.filter(
      (notification) => notification !== payload
    );
  },
  setIsOnboarded(state: MainState, payload: boolean) {
    state.isOnboarded = payload;
  },
  setCompany(state: MainState, payload: ICompanyProfile) {
    state.company = payload;
  },
  setNewPasswordRequired(state: MainState, payload: boolean) {
    state.newPasswordRequired = payload;
  },
};

const { commit } = getStoreAccessors<MainState | any, State>("");

export const commitSetDashboardMiniDrawer = commit(
  mutations.setDashboardMiniDrawer
);
export const commitSetDashboardShowDrawer = commit(
  mutations.setDashboardShowDrawer
);
export const commitSetLoggedIn = commit(mutations.setLoggedIn);
export const commitSetLogInError = commit(mutations.setLogInError);
export const commitSetToken = commit(mutations.setToken);
export const commitSetEmail = commit(mutations.setEmail);
export const commitSetUserProfile = commit(mutations.setUserProfile);
export const commitAddNotification = commit(mutations.addNotification);
export const commitRemoveNotification = commit(mutations.removeNotification);
export const commitSetIsOnboarded = commit(mutations.setIsOnboarded);
export const commitSetCompany = commit(mutations.setCompany);
export const commitSetNewPasswordRequired = commit(
  mutations.setNewPasswordRequired
);
