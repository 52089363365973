const env = process.env.VUE_APP_ENV;

// testing
let envApiUrl = "";
let envAuthDomain = "";
let envApiKey = "";

if (env === "production") {
  console.log("production");
  envApiUrl = process.env.VUE_APP_SERVER_URL;
  envAuthDomain = process.env.VUE_APP_SERVER_URL_AUTH_DOMAIN;
  envApiKey = process.env.VUE_APP_SERVER_URL_API_KEY;
} else if (env === "development") {
  console.log("development");
  envApiUrl = process.env.VUE_APP_SERVER_URL;
  envAuthDomain = process.env.VUE_APP_SERVER_URL_AUTH_DOMAIN;
  envApiKey = process.env.VUE_APP_SERVER_URL_API_KEY;
} else if (env === "staging") {
  console.log("staging");
  envApiUrl = process.env.VUE_APP_SERVER_URL;
  envAuthDomain = process.env.VUE_APP_SERVER_URL_AUTH_DOMAIN;
  envApiKey = process.env.VUE_APP_SERVER_URL_API_KEY;
} else {
  console.log("else");
  envApiUrl = process.env.VUE_APP_SERVER_URL;
}
export const authDomain = envAuthDomain;
export const apiKey = envApiKey;
export const apiUrl = envApiUrl;
export const appName = process.env.VUE_APP_NAME;
