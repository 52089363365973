
import { Component, Vue } from "vue-property-decorator";
import { ICompanyUpdate, AuditTrigger } from "@/interfaces";
import { readCompanies, readOneCompany } from "@/store/companies/getters";
import {
  dispatchAuditTrigger,
  dispatchGetCompanies,
  dispatchUpdateCompany
} from "@/store/companies/actions";

@Component
export default class CreateCompany extends Vue {
  public valid = false;
  public validAuditForm = false;
  public services: string[] = [];
  public companyName = "";
  public mailServerAdmin = "";
  public description = "";
  public tenantId = "";
  public selectedServices = "";
  public gmail: string = null;
  public teams: string = null;
  public microsoft: string = null;
  public isOnBoarded: any = "";
  public auditOnly: any = null;
  public allowedDomains = "";
  fromDate: any = new Date().toISOString().substring(0, 10);
  toDate: any = new Date().toISOString().substring(0, 10);
  dateFormatted: any;
  modal: any = false;
  menu: any = false;
  menu1: any = false;
  errors: any;
  numberOfDays: any = "";
  import_users = false;
  subscribe_to_events = false;
  audit_email = false;
  audit_teams = false;
  audit_teams_direct_messages = false;
  currentDate = new Date().toISOString().substring(0, 10);

  public async mounted() {
    await dispatchGetCompanies(this.$store, {});
    this.reset();
  }

  get companies() {
    return readCompanies(this.$store);
  }
  get company() {
    return readOneCompany(this.$store)(+this.$router.currentRoute.params.id);
  }

  public reset() {
    this.$validator.reset();
    if (this.company) {
      this.companyName = this.company.company_name;
      this.mailServerAdmin = this.company.mail_server_admin;
      this.description = this.company.company_description;
      this.tenantId = this.company.tenant_id;
      this.isOnBoarded = this.company.is_onboarded ? true : false;
      this.allowedDomains = this.company.allowed_domains;
      this.selectedServices = this.company.company_services;
      this.auditOnly = this.company.audit_only;
      this.gmail = this.company.company_services.includes("gmail")
        ? "gmail"
        : "";
      this.teams = this.company.company_services.includes("teams")
        ? "teams"
        : "";
      this.microsoft = this.company.company_services.includes("microsoft")
        ? "microsoft"
        : "";
      this.auditOnly = this.company.audit_only;
    }
  }

  public cancel() {
    this.$router.back();
  }

  /**
   * This function is for trigger company audit
   */
  submitAudit() {
    const auditData: AuditTrigger = {
      company_id: this.company!.company_id,
      import_users: this.import_users,
      audit_teams: this.audit_teams,
      audit_email: this.audit_email,
      audit_teams_direct_messages: this.audit_teams_direct_messages,
      subscribe_to_events: this.subscribe_to_events,
      tenant_id: this.company.tenant_id
    };
    if (this.audit_email || this.audit_teams || this.audit_teams_direct_messages) {
      (auditData.audit_days = parseInt(this.numberOfDays)),
        (auditData.start_date = this.fromDate);
    }
    if (this.audit_email) {
      dispatchAuditTrigger(this.$store, { data: auditData });
    }
    //TODO: add if statements for if this.audit_teams and this.audit_teams_direct_messages
  }

  public async submit() {
    if (this.gmail) {
      this.services.push(this.gmail);
    }
    if (this.microsoft) {
      this.services.push(this.microsoft);
    }
    if (this.teams) {
      this.services.push(this.teams);
    }
    if (this.services.length > 1) {
      this.selectedServices = this.services.join(", ");
    } else {
      this.selectedServices = this.services[0];
    }

    if (await this.$validator.validateAll()) {
      const updatedCompany: ICompanyUpdate = {
        company_name: this.companyName,
        mail_server_admin: this.mailServerAdmin,
        allowed_domains: this.allowedDomains,
        company_services: this.selectedServices
      };

      updatedCompany.company_description = this.description;
      updatedCompany.tenant_id = this.tenantId;
      updatedCompany.allowed_domains = this.allowedDomains;
      updatedCompany.audit_only = this.auditOnly;
      dispatchUpdateCompany(this.$store, {
        id: this.company!.company_id,
        company: updatedCompany
      }).then(() => {
        this.$router.push("/main/admin/companies/all");
      });
    }
  }
}
