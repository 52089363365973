
import moment from "moment";
import { Component, Prop, Vue } from "vue-property-decorator";

@Component
export default class SentimentCard extends Vue {
  @Prop() public readonly title: string;
  @Prop() public readonly positiveScore: string;
  @Prop() public readonly neutralScore: string;
  @Prop() public readonly negativeScore: string;
  @Prop() public readonly grade: string;
  @Prop() public readonly benchmark: string;
  @Prop() public readonly totalItems: number;
  @Prop() public readonly timeframeSearch: number;
  @Prop() public readonly description: string;
  public loading = false;

  timeframeItems: any[] = [
    { label: "7 Days", value: 7 },
    { label: "30 Days", value: 30 },
    { label: "3 Months", value: 90 },
    { label: "6 Months", value: 180 },
    { label: "9 Months", value: 270 }
  ];

  public timeFrame: number = null;

  mounted() {
    this.timeFrame = this.timeframeSearch;
  }

  public getItemsBetweenTimeframe() {
    let startDate: string = null;
    this.loading = true;

    if (this.timeFrame) {
      startDate = moment()
        .subtract(this.timeFrame, "days")
        .startOf("day")
        .utc()
        .format();
    }

    this.$emit("fetchScores", startDate, () => (this.loading = false));
    this.$emit("setTimeframe", this.timeFrame);
  }

  public gradeColor(grade) {
    return {
      A: grade?.includes("A"),
      B: grade?.includes("B"),
      C: grade?.includes("C") || grade == null,
      D: grade?.includes("D"),
      F: grade?.includes("F")
    };
  }
}
