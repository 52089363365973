import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import "./plugins/vuetify";
import "./plugins/vee-validate";
import "./registerServiceWorker";
import "vuetify/dist/vuetify.min.css";
import * as firebase from "firebase/app";
import Component from "vue-class-component";
Component.registerHooks(["beforeRouteEnter"]);
import { apiKey, authDomain } from "./env";
import { getAuth } from "firebase/auth";

Vue.config.productionTip = false;
firebase.initializeApp({
  apiKey,
  authDomain,
});

// Need to let the fire base app initialize
// and determine user auth status before we start the app
let app;
const auth = getAuth();
auth.onAuthStateChanged(() => {
  if (!app) {
    app = new Vue({
      router,
      store,
      render: (h) => h(App),
    }).$mount("#app");
  }
});
