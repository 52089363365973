import { ICountData, IMicrosoftTeamsCountData } from "@/interfaces";
import { CountsState } from "./state";
import { getStoreAccessors } from "typesafe-vuex";
import { State } from "../state";

export const mutations = {
  setCompanyCountsSummary(state: CountsState, payload: ICountData) {
    state.companyCountsSummary = payload;
  },
  setMicrosoftTeamsCompanyCountsSummary(
    state: CountsState,
    payload: IMicrosoftTeamsCountData
  ) {
    state.microsoftTeamsCompanyCountsSummary = payload;
  },
};

const { commit } = getStoreAccessors<CountsState, State>("");

export const commitSetCompanyCountsSummary = commit(
  mutations.setCompanyCountsSummary
);
export const commitSetMicrosoftTeamsCompanyCountsSummary = commit(
  mutations.setMicrosoftTeamsCompanyCountsSummary
);
