import { AuditState } from "./state";
import { getStoreAccessors } from "typesafe-vuex";
import { State } from "../state";

export const getters = {
  scanProgress: (state: AuditState) => state.scanProgress,
};

const { read } = getStoreAccessors<AuditState, State>("");

export const readScanProgress = read(getters.scanProgress);
