
import { Component, Prop, Vue } from "vue-property-decorator";
import {
  ICompanyO365Verification,
  ICompanyProfile,
  IUserProfile,
} from "@/interfaces";
import {
  dispatchGetMsO365OnboardingClientId,
  dispatchTestConnection,
} from "@/store/companies/actions";
import { onboardingPopUp } from "@/utils/onboardingPopUp";

@Component
export default class O365Authorization extends Vue {
  @Prop() public readonly company: ICompanyProfile;
  @Prop() public readonly msLoginError: boolean;
  @Prop() public readonly userProfile: IUserProfile;
  @Prop() public readonly o365Verification: ICompanyO365Verification;

  public O365ClientId = "";
  public msPopupWindow: any;
  public verificationConnectionTimer: any;
  public verificationHits = 0;
  public verification2MinLimit = 12;

  async mounted() {
    this.O365ClientId = await dispatchGetMsO365OnboardingClientId(this.$store);
    this.o365Verification;
    if (!this.o365Verification) {
      await this.verifyConnection();
      this.verification_timer();
    }

    if (this.$route.query?.admin_consent == "True") {
      window.close();
    }
  }

  public o365AdminConsent() {
    onboardingPopUp.msAdminConsent(
      this.O365ClientId,
      this.company.tenant_id,
      this.msPopupWindow
    );
  }

  public async verifyConnection() {
    await dispatchTestConnection(this.$store, this.userProfile.company_id);
  }

  verification_timer() {
    this.verificationConnectionTimer = setInterval(() => {
      this.verificationHits += 1;
      if (this.verificationHits >= this.verification2MinLimit) {
        clearInterval(this.verificationConnectionTimer);
      }
      if (this.o365Verification && this.msPopupWindow) {
        this.msPopupWindow.close();
      }
      this.verifyConnection();
    }, 10000);
  }

  beforeDestroy() {
    clearInterval(this.verificationConnectionTimer);
  }
}
