import { ModeratorState } from "./state";
import { getStoreAccessors } from "typesafe-vuex";
import { State } from "../state";

export const mutations = {
  setModerators(state: ModeratorState, payload) {
    state.moderators = payload;
  },
  setNonModeratorUsers(state: ModeratorState, payload) {
    state.nonModeratorUsers = payload;
  },
  setModerator(state: ModeratorState, payload) {
    state.moderator = payload;
  },
};

const { commit } = getStoreAccessors<ModeratorState, State>("");

export const commitSetModerators = commit(mutations.setModerators);
export const commitSetNonModeratorUsers = commit(
  mutations.setNonModeratorUsers
);
export const commitSetModerator = commit(mutations.setModerator);
