
import { Component, Vue } from "vue-property-decorator";
import { appName } from "@/env";
import { dispatchCompleteNewPassword } from "@/store/main/actions";
import { readLoginError } from "@/store/main/getters";

@Component
export default class ForceChangePassword extends Vue {
  public appName = appName;
  public valid = true;
  public password = "";
  public email = "";
  public tempPassword = "";
  public loading = false;
  public showNewPassword = false;
  public regex =
    /^(?=.*[a-z ])(?=.*[A-Z ])(?=.*[0-9])(?=.*[=+\-^$*.\[\]{}()?"!@#%&\/\\,><':;|_~`]).{8,99}$/;

  public validatePassword(password: string) {
    return this.regex.test(password);
  }

  public get loginError() {
    return readLoginError(this.$store);
  }

  public async mounted() {
    if (this.$route.params.email) {
      this.email = this.$route.params.email;
    }
    if (this.$route.params.tempPassword) {
      this.tempPassword = this.$route.params.tempPassword;
    }
  }

  public async submit() {
    this.loading = true;
    if (await this.$validator.validateAll()) {
      await dispatchCompleteNewPassword(this.$store, {
        username: this.email,
        tempPassword: this.tempPassword,
        newPassword: this.password,
      });
    }
  }
}
