import { render, staticRenderFns } from "./ExcludedList.vue?vue&type=template&id=763f2b1d&scoped=true&"
import script from "./ExcludedList.vue?vue&type=script&lang=ts&"
export * from "./ExcludedList.vue?vue&type=script&lang=ts&"
import style0 from "./ExcludedList.vue?vue&type=style&index=0&id=763f2b1d&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "763f2b1d",
  null
  
)

export default component.exports