import {
  IForward,
  IMessageFlaggedEvent,
  IToxicItems,
  ToxicItemCount,
} from "@/interfaces";
import { ItemState } from "./state";
import { getStoreAccessors } from "typesafe-vuex";
import { State } from "../state";
import { commonFunctions } from "@/utils/common";

export const mutations = {
  setItemById(state: ItemState, payload: IMessageFlaggedEvent) {
    let dateConvertItem = payload;
    if (payload?.email_created_at) {
      dateConvertItem = {
        ...payload,
        email_created_at: commonFunctions.convertUTCToLocalTime(
          payload.email_created_at
        ),
      };
    }

    state.item = dateConvertItem;
  },
  SetToxicItemCount(state: ItemState, payload: ToxicItemCount) {
    state.itemCount = payload;
  },
  setToxicItems(state: ItemState, payload: IToxicItems) {
    const dateConvertedItems = {
      review: payload?.review?.map((item) => {
        item.email_created_at = commonFunctions.convertUTCToLocalTime(
          item.email_created_at
        );
        return item;
      }),
      archived: payload?.archived?.map((item) => {
        item.email_created_at = commonFunctions.convertUTCToLocalTime(
          item.email_created_at
        );
        return item;
      }),
      flagged: payload?.flagged?.map((item) => {
        item.email_created_at = commonFunctions.convertUTCToLocalTime(
          item.email_created_at
        );
        return item;
      }),
      all: payload?.all?.map((item) => {
        item.email_created_at = commonFunctions.convertUTCToLocalTime(
          item.email_created_at
        );
        return item;
      }),
    };

    state.toxicItems = dateConvertedItems;
  },
  setToxicItemsLoading(state: ItemState, payload: boolean) {
    state.toxicItemsLoading = payload;
  },
  setEarliestMessageDate(state: ItemState, payload: any) {
    state.earliestMessageDate = payload;
  },
  setForwards(state: ItemState, payload: IForward[]) {
    state.forwards = payload;
  },
  setItemsByType(
    state: ItemState,
    payload: { items: IMessageFlaggedEvent[]; type: string }
  ) {
    const dateConvertedItems = payload.items.map((item) => {
      item.email_created_at = commonFunctions.convertUTCToLocalTime(
        item.email_created_at
      );
      return item;
    });
    state.toxicItems[payload.type] = dateConvertedItems;
  },
};

const { commit } = getStoreAccessors<ItemState, State>("");

export const commitSetToxicItems = commit(mutations.setToxicItems);
export const commitSetToxicItemsLoading = commit(
  mutations.setToxicItemsLoading
);
export const commitSetItemById = commit(mutations.setItemById);
export const commitSetEarliestMessageDate = commit(
  mutations.setEarliestMessageDate
);
export const commitSetForwards = commit(mutations.setForwards);
export const commitSetToxicItemCount = commit(mutations.SetToxicItemCount);
export const commitSetItemsByType = commit(mutations.setItemsByType);
