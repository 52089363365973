
import { Component, Vue } from "vue-property-decorator";
import { readCompany, readUserProfile } from "@/store/main/getters";
import NotificationSettings from "@/components/NotificationSettings.vue"

@Component({
  components: {
    NotificationSettings
  }
})
export default class UserProfile extends Vue {
  get userProfile() {
    const userProfile = readUserProfile(this.$store);
    return userProfile;
  }

  get company() {
    return readCompany(this.$store);
  }

  public goToEdit() {
    this.$router.push("/main/profile/edit");
  }

  public goToPassword() {
    this.$router.push("/main/profile/password");
  }
}
