import { CompanyState } from "./state";
import { getStoreAccessors } from "typesafe-vuex";
import { State } from "../state";

export const getters = {
  companies: (state: CompanyState) => state.companies,
  oneCompany: (state: CompanyState) => (companyId: number) => {
    const filteredCompanies = state.companies.filter(
      (company) => company.company_id === companyId
    );
    if (filteredCompanies.length > 0) {
      return { ...filteredCompanies[0] };
    }
  },
  userCompany: (state: CompanyState) => state.userCompany,
  importUser: (state: CompanyState) => state.importUser,
  lastDashboardSearch: (state: CompanyState) => state.lastDashboardSearch,
  lastTeamsDashboardSearch: (state: CompanyState) =>
    state.lastTeamsDashboardSearch,
  keywordsList: (state: CompanyState) => state.keywordsList,
  isO365Verified: (state: CompanyState) => state.isO356Verified,
  isTeamsVerified: (state: CompanyState) => state.isTeamsVerified,
};

const { read } = getStoreAccessors<CompanyState, State>("");

export const readOneCompany = read(getters.oneCompany);
export const readCompanies = read(getters.companies);
export const readUserCompany = read(getters.userCompany);
export const readImportUser = read(getters.importUser);
export const readLastDashboardSearch = read(getters.lastDashboardSearch);
export const readKeywordsList = read(getters.keywordsList);
export const readIsO365Verified = read(getters.isO365Verified);
export const readIsTeamsVerified = read(getters.isTeamsVerified);
export const readLastTeamsDashboardSearch = read(
  getters.lastTeamsDashboardSearch
);
