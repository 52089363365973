import { ItemState } from "./state";
import { getStoreAccessors } from "typesafe-vuex";
import { State } from "../state";

export const getters = {
  itemById: (state: ItemState) => state.item,
  toxicItemsLoading: (state: ItemState) => state.toxicItemsLoading,
  flaggedMessages: (state: ItemState) => state.toxicItems.flagged,
  reviewMessages: (state: ItemState) => state.toxicItems.review,
  archivedMessages: (state: ItemState) => state.toxicItems.archived,
  allMessages: (state: ItemState) => state.toxicItems.all,
  earliestMessageDate: (state: ItemState) => state.earliestMessageDate,
  forwards: (state: ItemState) => state.forwards,
  toxicItemCount: (state: ItemState) => state.itemCount,
};

const { read } = getStoreAccessors<ItemState, State>("");

export const readToxicItemsLoading = read(getters.toxicItemsLoading);
export const readItemById = read(getters.itemById);
export const readFlaggedMessages = read(getters.flaggedMessages);
export const readReviewMessages = read(getters.reviewMessages);
export const readArchivedMessages = read(getters.archivedMessages);
export const readEarliestMessageDate = read(getters.earliestMessageDate);
export const readForwards = read(getters.forwards);
export const readToxicItemCount = read(getters.toxicItemCount);
export const readAllMessages = read(getters.allMessages);
