import { ActionContext } from "vuex";
import { State } from "../state";
import { ServiceNowState } from "./state";
import { commitSetServiceNowUser, commitSetVerified } from "./mutations";
import { getStoreAccessors } from "typesafe-vuex";
import { dispatchCheckApiError } from "../main/actions";
import {
  commitAddNotification,
  commitRemoveNotification,
} from "../main/mutations";
import { IServiceNowCreate, IServiceNowUpdate } from "../../interfaces";
import axios from "../interceptor";

type MainContext = ActionContext<ServiceNowState, State>;

export const actions = {
  async actionGetServiceNowUser(context: MainContext, companyId: number) {
    try {
      if (companyId) {
        const response = await axios.get(
          "/service_now/get_service_now_user" + "?company_id=" + companyId
        );
        if (response.data) {
          if (
            response.data.hashed_password &&
            response.data.hashed_password.length > 0
          ) {
            response.data.hashed_password = "**************";
          }
          commitSetServiceNowUser(context, response.data);
        }
      }
    } catch (error) {
      commitSetServiceNowUser(context, {
        username: null,
        hashed_password: null,
        company_id: null,
        verified: null,
        domain_list: null,
        password: null,
      });
      return null;
    }
  },
  async actionUpdateServiceNowUser(
    context: MainContext,
    payload: IServiceNowUpdate
  ) {
    try {
      const response = await axios.post(
        "/service_now/update_service_now_user",
        payload
      );
      const successNotification = {
        content: `Successfully updated`,
        color: "success",
      };
      commitAddNotification(context, successNotification);
    } catch (error) {
      await dispatchCheckApiError(context, error);
    }
  },
  async actionCreateServiceNowUser(
    context: MainContext,
    payload: IServiceNowCreate
  ) {
    try {
      const loadingNotification = { content: "saving", showProgress: true };
      commitAddNotification(context, loadingNotification);
      const response = await axios.post(
        "/service_now/create_service_now_user",
        payload
      );
      if (response) {
        commitRemoveNotification(context, loadingNotification);
        const successNotification = {
          content: `Service Now account details added.`,
          color: "success",
        };
        commitAddNotification(context, successNotification);
      }
      // const successNotification = { content: `Service Now account details added.`, color: 'success' };
    } catch (error) {
      await dispatchCheckApiError(context, error);
    }
  },
  async actionRemoveServiceNowUser(context: MainContext, companyId: number) {
    try {
      const response = true;
      // replace with GCP endpoint below
      // create service now user
      // const response: any = await API.graphql({
      //     query: deleteServiceNowUser,
      //     variables: { input: {company_id: companyId} },
      // });
      // replace with GCP endpoint above

      if (response) {
        const successNotification = {
          content: `Service Now account was disconnected.`,
          color: "success",
        };
        commitAddNotification(context, successNotification);
      }
    } catch (error) {
      await dispatchCheckApiError(context, error);
    }
  },
  async actionVerifyServiceNowAccount(context: MainContext, companyId: number) {
    try {
      // replace with GCP endpoint below
      // verify service now user
      // const response: any = await API.graphql({
      //     query: verifyServiceNowUser,
      //     variables: { input: { company_id: companyId }},
      // });
      // replace with GCP endpoint above

      // if (response.data.verifyServiceNowUser) {
      //     commitSetVerified(context, response.data.verifyServiceNowUser.verified);
      // }
      commitSetVerified(context, true);
    } catch (error) {
      // console.error(error);
      return;
    }
  },
};

const { dispatch } = getStoreAccessors<ServiceNowState, State>("");

export const dispatchGetServiceNowUser = dispatch(
  actions.actionGetServiceNowUser
);
export const dispatchUpdateServiceNowUser = dispatch(
  actions.actionUpdateServiceNowUser
);
export const dispatchCreateServiceNowUser = dispatch(
  actions.actionCreateServiceNowUser
);
export const dispatchRemoveServiceNowUser = dispatch(
  actions.actionRemoveServiceNowUser
);
export const dispatchVerifyServiceNowAccount = dispatch(
  actions.actionVerifyServiceNowAccount
);
