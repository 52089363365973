
import { Component, Vue, Prop } from "vue-property-decorator";
import { IModerator, IUserProfile } from "@/interfaces";
import { dispatchGetModerators } from "@/store/moderators/actions";
import { readModerators } from "@/store/moderators/getters";
import { readExcludedList } from "@/store/excluded/getters";
import { readCompany } from "@/store/main/getters";
import { dispatchGetUserCompany } from "@/store/main/actions";

@Component
export default class OnboardingReview extends Vue {
  public errorMessages: string[] = [];
  @Prop() public readonly userProfile: IUserProfile;
  @Prop() public readonly users: IUserProfile[];
  @Prop() public readonly Moderators: IModerator[];
  @Prop() public readonly fileCountsData: any;
  @Prop() public readonly selectedModeratorOption: any;

  public async mounted() {
    if (!this.company) {
      await dispatchGetUserCompany(this.$store);
    }
    await dispatchGetModerators(this.$store, this.company.company_id);
  }

  public formatPhoneNumber(str) {
    // Filter only numbers from the input
    const cleaned = ("" + str).replace(/\D/g, "");
    // Check if the input is of correct length
    const match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
    if (match) {
      return "(" + match[1] + ") " + match[2] + "-" + match[3];
    }
    return null;
  }

  get onboardingCompleted() {
    this.setErrorMsgs();
    if (this.moderators.length < 1) {
      return false;
    } else {
      return true;
    }
  }

  public setErrorMsgs() {
    this.errorMessages = [];
    if (this.moderators.length < 1) {
      this.errorMessages.push(
        "At least one internal moderator must be selected."
      );
    }
    if (
      this.fileCountsData.total_valid_mail == 0 &&
      this.selectedModeratorOption == "specified_users"
    ) {
      this.errorMessages.push(
        "No users found in uploaded csv please upload again"
      );
    }
  }

  get atLeastOnePhoneNumber() {
    for (const user of this.moderators) {
      if (user.phone_number) {
        return true;
      }
    }
    return false;
  }

  public async editStep(stepNumber: number) {
    this.$emit("edit-step-number", stepNumber);
  }

  get excludedList() {
    return readExcludedList(this.$store);
  }

  get moderators() {
    return readModerators(this.$store);
  }

  get company() {
    return readCompany(this.$store);
  }
}
