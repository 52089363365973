import { ActionContext } from "vuex";
import { State } from "../state";
import { CountsState } from "./state";
import { getStoreAccessors } from "typesafe-vuex";
import { dispatchCheckApiError } from "../main/actions";
import {
  commitSetCompanyCountsSummary,
  commitSetMicrosoftTeamsCompanyCountsSummary,
} from "./mutations";
import { ICountData, IMicrosoftTeamsCountData } from "@/interfaces";
import axios from "../interceptor";
import { commonFunctions } from "@/utils/common";

type MainContext = ActionContext<CountsState, State>;

export const actions = {
  async actionFormatDate(context: MainContext, date) {
    const d = new Date(date);
    let month = "" + (d.getMonth() + 1);
    let day = "" + d.getDate();
    const year = d.getFullYear();
    if (month.length < 2) {
      month = "0" + month;
    }
    if (day.length < 2) {
      day = "0" + day;
    }
    return [year, month, day].join("-");
  },

  /**
   * This function fetch the toxic item counts
   * @param context
   * @param queries
   * @returns
   */
  async actionGetCompanyCountsSummary(
    context: MainContext,
    queries: { companyId: number }
  ) {
    try {
      // consolidate with getItemCountsSummary
      if (queries.companyId) {
        const week = commonFunctions.currentWeekDates();
        const month = commonFunctions.currentMonthDates();
        const today = commonFunctions.todayDates();
        const payload = {
          today_start_date: today.start_date,
          today_end_date: today.end_date,
          week_start_date: week.start_date,
          week_end_date: week.end_date,
          month_start_date: month.start_date,
          month_end_date: month.end_date,
        };
        const response = await axios.get("/item/get_item_count_between_dates", {
          params: payload,
        });
        const countsSummary: ICountData = {
          todaysCount: response.data.todays_count,
          weeklyCount: response.data.week_count,
          allTimeCount: response.data.total_item_count,
        };
        commitSetCompanyCountsSummary(context, countsSummary);
      }
    } catch (error) {
      await dispatchCheckApiError(context, error);
      return "error";
    }
  },

  /**
   * @param context
   * @param queries
   * @returns
   */
  async actionGetMicrosoftTeamsCompanyCountsSummary(
    context: MainContext,
    queries: { companyId: number }
  ) {
    try {
      // consolidate with getItemCountsSummary
      if (queries.companyId) {
        const week = commonFunctions.currentWeekDates();
        const month = commonFunctions.currentMonthDates();
        const today = commonFunctions.todayDates();

        const response = await axios.get("/teams/items/count/between_dates", {
          params: {
            today_start_date: today.start_date,
            today_end_date: today.end_date,
            week_start_date: week.start_date,
            week_end_date: week.end_date,
            month_start_date: month.start_date,
            month_end_date: month.end_date,
          },
        });
        const countsSummary: IMicrosoftTeamsCountData = {
          todaysCount: response.data.todays_count,
          weeklyCount: response.data.week_count,
          allTimeCount: response.data.total_item_count,
        };
        commitSetMicrosoftTeamsCompanyCountsSummary(context, countsSummary);
      }
    } catch (error) {
      await dispatchCheckApiError(context, error);
      return "error";
    }
  },
};

const { dispatch } = getStoreAccessors<CountsState, State>("");

export const dispatchGetCompanyCountsSummary = dispatch(
  actions.actionGetCompanyCountsSummary
);
export const dispatchFormatDate = dispatch(actions.actionFormatDate);
export const dispatchGetMicrosoftTeamsCompanyCountsSummary = dispatch(
  actions.actionGetMicrosoftTeamsCompanyCountsSummary
);
