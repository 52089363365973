import router from "@/router";
import { getStoreAccessors } from "typesafe-vuex";
import { ActionContext } from "vuex";
import { State } from "../state";
// import axios from './index';
import axios from "../interceptor";
import {
  commitAddNotification,
  commitRemoveNotification,
  commitSetLoggedIn,
  commitSetLogInError,
  commitSetToken,
  commitSetUserProfile,
  commitSetCompany,
  commitSetIsOnboarded,
  commitSetNewPasswordRequired,
  commitSetEmail,
} from "./mutations";
import { AppNotification, MainState } from "./state";
import { getters } from "./getters";
import {
  getAuth,
  signInWithEmailAndPassword,
  signOut,
  setPersistence,
  browserLocalPersistence,
  updatePassword,
} from "firebase/auth";

type MainContext = ActionContext<MainState, State>;

export const actions = {
  /**
   * This function is for login first validate with IDP then trigger API of login for auth token
   * @param context
   * @param payload
   * @returns
   */
  async actionLogIn(
    context: MainContext,
    payload: { username: string; password: string }
  ) {
    try {
      const auth = getAuth();
      await setPersistence(auth, browserLocalPersistence);
      await signInWithEmailAndPassword(
        auth,
        payload.username,
        payload.password
      );
      const response = await axios.post("/user/login");
      
      commitSetEmail(context, payload.username);
      commitSetNewPasswordRequired(context, response.data.reset_password);
      if (response.data.reset_password) {
        return response;
      }

      commitSetLoggedIn(context, true);
      commitSetLogInError(context, false);
      const data = await dispatchGetUserProfile(context);
      if (data.company_id == null) {
        router.push("/main/admin/companies/all");
        return;
      } else {
        await dispatchGetUserCompany(context);
      }
      const company = getters.company(context.state);
      if (company && !response.data.reset_password && company.is_onboarded) {
        await dispatchRouteLoggedIn(context);
      }

      return response;
    } catch (error) {
      commitSetLogInError(context, true);
      await dispatchCheckApiError(context, error);
    }
  },

  /**
   * This function is used to reset moderator password
   * @param context
   * @param payload
   * @returns
   */
  async actionCompleteNewPassword(context: MainContext, payload: any) {
    const request = {
      email: payload.username,
    };
    const auth = getAuth();
    const userCredential = await signInWithEmailAndPassword(
      auth,
      payload.username,
      payload.tempPassword
    );
    
    await updatePassword(auth.currentUser, payload.newPassword);
    const response = await axios.post(
      "/moderator/reset_moderator_password",
      request
    );
    await dispatchCheckLoggedIn(context);
    await dispatchRouteLoggedIn(context);
    return true;
  },

  /**
   * This Function is used to get user information
   * @param context
   */
  async actionGetUserProfile(context: MainContext) {
    try {
      const response = await axios.get("/user/get_user_by_email");
      commitSetLoggedIn(context, true);
      commitSetUserProfile(context, response.data);
      return response.data;
    } catch (error) {
      await dispatchCheckApiError(context, error);
    }
  },

  /**
   * This function is used to check user is logged in ?
   * @param context
   * @returns
   */
  async actionCheckLoggedIn(context: MainContext) {
    if (!context.state.isLoggedIn) {
      try {
        const auth = getAuth();
        const token = await auth.currentUser?.getIdToken();

        if (token) {
          commitSetToken(context, token);
          commitSetEmail(context, auth.currentUser.email);
          commitSetLoggedIn(context, true);
          await dispatchGetUserProfile(context);
        } else {
          await dispatchRemoveLogIn(context);
        }
        return;
      } catch (error) {
        await dispatchRemoveLogIn(context);
      }
    }
  },

  /**
   * This function is used to remove login and set state for logged in
   * @param context
   */
  async actionRemoveLogIn(context: MainContext) {
    commitSetToken(context, "");
    commitSetLoggedIn(context, false);
  },

  /**
   * This function is used for logout and redirect on particular route using route logout function
   * @param context
   * @returns
   */
  async actionLogOut(context: MainContext) {
    try {
      signOut(getAuth());
      await axios.get("/user/logout");
      await dispatchCheckLoggedIn(context);
      await dispatchRemoveLogIn(context);
      await dispatchRouteLogOut(context);
      return true;
    } catch (error) {
      await dispatchCheckApiError(context, error);
    }
  },
  /**
   * This function is used for user logout
   * @param context
   */
  async actionUserLogOut(context: MainContext) {
    await dispatchCheckLoggedIn(context);
    await dispatchLogOut(context);
  },
  /**
   * This function is used to redirect to login after logout
   * @param context
   */
  actionRouteLogOut(context: MainContext) {
    window.location.reload();
  },

  /**
   * This function is used to handle all API errors.
   * @param context
   * @param payload
   * @returns
   */
  async actionCheckApiError(context: MainContext, payload: any) {
    let errorMsg = "An error occurred";
    console.error(payload);
    if (payload.response?.status == 401) {
      commitAddNotification(context, {
        content: "Unauthorized! Relogin Please",
        showProgress: false,
        color: "error",
      });
      dispatchRouteLogOut(context);
      return;
    }
    if (payload.response?.data?.message) {
      errorMsg = payload.response.data.message;
    } else {
      if (payload.errors) {
        errorMsg = payload.errors[0].message;
      } else if (payload.message) {
        errorMsg = payload.message;
      }
    }
    const errorNotification = {
      content: errorMsg,
      showProgress: false,
      color: "error",
    };
    commitAddNotification(context, errorNotification);
  },

  /**
   * This function is used to redirect on dashboard after login
   * @param context
   */
  actionRouteLoggedIn(context: MainContext) {
    if (
      router.currentRoute.path === "/login" ||
      router.currentRoute.path === "/" ||
      router.currentRoute.path === "/change-password" ||
      router.currentRoute.path === "/reset-password"
    ) {
      router.push("/main/company/sentiment");
    }
  },
  /**
   * This function is used to remove notification
   * @param context
   * @param payload
   * @returns
   */
  async removeNotification(
    context: MainContext,
    payload: { notification: AppNotification; timeout: number }
  ) {
    return new Promise((resolve, reject) => {
      setTimeout(() => {
        commitRemoveNotification(context, payload.notification);
        resolve(true);
      }, payload.timeout);
    });
  },

  /**
   * This function is ised to send mail of password recovery.
   * @param context
   * @param payload
   */
  async passwordRecovery(context: MainContext, payload: { username: string }) {
    try {
      const response = await axios.get("/moderator/send_reset_password_mail", {
        params: { email: payload.username },
      });
      commitAddNotification(context, {
        content: "Password recovery email sent",
        color: "success",
      });
    } catch (error) {
      await dispatchCheckApiError(context, error);
    }
  },

  /**
   * This function is used to reset moderator password
   * @param context
   * @param payload
   * @returns
   */
  async resetPassword(
    context: MainContext,
    payload: { username: string; password: string; code: string }
  ) {
    const loadingNotification = {
      content: "Resetting password",
      showProgress: true,
    };
    try {
      const auth = getAuth();
      const request = {
        email: payload.username,
      };

      await signInWithEmailAndPassword(auth, request.email, payload.code);
      await updatePassword(auth.currentUser, payload.password);

      const response = await axios.post(
        "/moderator/reset_moderator_password",
        request
      );
      commitAddNotification(context, {
        content: "Password successfully reset",
        color: "success",
      });
      await dispatchCheckLoggedIn(context);
      await dispatchRouteLoggedIn(context);
      return;
    } catch (error) {
      const errorNotification = {
        content: "Invalid  Code",
        showProgress: false,
        color: "error",
      };
      commitAddNotification(context, errorNotification);
      await dispatchCheckApiError(context, error);
    }
  },

  /**
   * This function is used for change password
   * @param context
   * @param payload
   * @returns
   */
  async changePassword(
    context: MainContext,
    payload: { oldPassword: string; newPassword: string }
  ) {
    try {
      const auth = getAuth();
      await signInWithEmailAndPassword(auth, auth.currentUser.email, payload.oldPassword);
      await updatePassword(auth.currentUser, payload.newPassword);

      commitAddNotification(context, {
        content: "Password successfully reset",
        color: "success",
      });
      return true;
    } catch (error) {
      commitAddNotification(context, {
        content: "Reset failed, please verify your password",
        color: "error",
      });
    }
  },

  /**
   * This function is used to fetch company detain of logged in user
   * @param context
   * @returns
   */
  async actionGetUserCompany(context: MainContext) {
    try {
      // get company by company id
      const response = await axios.get("/company/get_company");
      if (response?.data) {
        commitSetCompany(context, response.data);
        commitSetIsOnboarded(context, response.data?.is_onboarded);
      }
      return;
    } catch (error) {
      await dispatchCheckApiError(context, error);
    }
  },
  async actionSetLoginError(context: MainContext, payload: boolean) {
    commitSetLogInError(context, payload);
  },
};

const { dispatch } = getStoreAccessors<MainState | any, State>("");

export const dispatchCheckApiError = dispatch(actions.actionCheckApiError);
export const dispatchCheckLoggedIn = dispatch(actions.actionCheckLoggedIn);
export const dispatchGetUserProfile = dispatch(actions.actionGetUserProfile);
export const dispatchLogIn = dispatch(actions.actionLogIn);
export const dispatchLogOut = dispatch(actions.actionLogOut);
export const dispatchUserLogOut = dispatch(actions.actionUserLogOut);
export const dispatchRemoveLogIn = dispatch(actions.actionRemoveLogIn);
export const dispatchRouteLoggedIn = dispatch(actions.actionRouteLoggedIn);
export const dispatchRouteLogOut = dispatch(actions.actionRouteLogOut);
export const dispatchRemoveNotification = dispatch(actions.removeNotification);
export const dispatchPasswordRecovery = dispatch(actions.passwordRecovery);
export const dispatchResetPassword = dispatch(actions.resetPassword);
export const dispatchChangePassword = dispatch(actions.changePassword);
export const dispatchCompleteNewPassword = dispatch(
  actions.actionCompleteNewPassword
);
export const dispatchGetUserCompany = dispatch(actions.actionGetUserCompany);
export const dispatchSetLoginError = dispatch(actions.actionSetLoginError);
