import { ActionContext } from "vuex";
import {
  ICompanyCreate,
  ICompanyUpdate,
  ICompanyDashSearch,
  AuditTrigger,
  ICompanyTeamsDashSearch,
} from "@/interfaces";
import { State } from "../state";
import { CompanyState } from "./state";
import { getStoreAccessors } from "typesafe-vuex";
import {
  commitSetCompanies,
  commitSetCompany,
  commitSetUserCompany,
  commitSetLastDashboardSearch,
  commitSetImportUser,
  commitSetKeywordsList,
  commitSetO356Verification,
  commitSetTeamsVerification,
  commitSetLastTeamsDashboardSearch,
  commitSetChangePageLastDashboardSearch,
} from "./mutations";
import { dispatchCheckApiError } from "../main/actions";
import {
  commitAddNotification,
  commitRemoveNotification,
} from "../main/mutations";
import axios from "../interceptor";

type MainContext = ActionContext<CompanyState, State>;

export const actions = {
  /**
   * This function fetch company list
   * @param context
   * @param queries
   */
  async actionGetCompanies(
    context: MainContext,
    queries: { skip?: number; limit?: number }
  ) {
    try {
      const response = await axios.get("/company/list_companies");
      commitSetCompanies(context, response.data);
    } catch (error) {
      await dispatchCheckApiError(context, error);
    }
  },

  /**
   * This function fetch the company by company id or logged in user company detail.
   * @param context
   * @param companyId
   */
  async actionGetUserCompany(context: MainContext, companyId: number) {
    try {
      if (companyId) {
        const response = await axios.get("/company/get_company");
        if (response) {
          commitSetUserCompany(context, response.data);
        }
      }
    } catch (error) {
      await dispatchCheckApiError(context, error);
    }
  },

  /**
   * This function update the particular company detail.
   * @param context
   * @param payload
   * @returns
   */
  async actionUpdateCompany(
    context: MainContext,
    payload: { id: number; company: ICompanyUpdate }
  ) {
    try {
      const loadingNotification = { content: "saving", showProgress: true };
      commitAddNotification(context, loadingNotification);
      payload.company.id = payload.id;
      const response = await axios.post(
        "/company/update_company",
        payload.company
      );
      commitSetCompany(context, response.data);
      commitRemoveNotification(context, loadingNotification);
      commitAddNotification(context, {
        content: "Company successfully updated",
        color: "success",
      });
      return response;
    } catch (error) {
      await dispatchCheckApiError(context, error);
    }
  },

  /**
   * This function update the particular company detail.
   * @param context
   * @param payload
   * @returns
   */
  async actionAuditTrigger(
    context: MainContext,
    payload: { data: AuditTrigger }
  ) {
    try {
      const loadingNotification = { content: "saving", showProgress: true };
      commitAddNotification(context, loadingNotification);
      const response = await axios.post(
        "/company/company_import_user_and_audit",
        payload.data
      );
      commitSetCompany(context, response.data);
      commitRemoveNotification(context, loadingNotification);
      commitAddNotification(context, {
        content: "Company successfully updated",
        color: "success",
      });
      return response;
    } catch (error) {
      await dispatchCheckApiError(context, error);
    }
  },

  /**
   * This function update the particular company detail.
   * @param context
   * @param payload
   * @returns
   */
  async actionImportUser(context: MainContext, payload: any) {
    try {
      const response = await axios.post("/company/csv/inclusion_list", payload);
      commitSetImportUser(context, { user_imported: true });
      commitAddNotification(context, {
        content: "Users Successfully Imported",
        color: "success",
      });
      return response;
    } catch (error) {
      await dispatchCheckApiError(context, error);
    }
  },

  /**
   * This function upload the exclusion list csv
   * @param context
   * @param payload
   * @returns
   */
  async actionUploadExclusionCsv(context: MainContext, payload: any) {
    try {
      const response = await axios.post("/company/csv/exclusion_list", payload);
      commitAddNotification(context, {
        content: "Exclusion List Successfully Uploaded",
        color: "success",
      });
      return response;
    } catch (error) {
      await dispatchCheckApiError(context, error);
    }
  },
  /**
   * This function upload the exclusion list csv
   * @param context
   * @param payload
   * @returns
   */
  async actionUploadKeywordCsv(context: MainContext, payload: any) {
    try {
      const response = await axios.post("/company/csv/keyword_list", payload);
      commitAddNotification(context, {
        content: "Keywords List Successfully Uploaded",
        color: "success",
      });
      return response;
    } catch (error) {
      await dispatchCheckApiError(context, error);
    }
  },

  async actionUpdateIsOnboarded(
    context: MainContext,
    payload: { id: number; is_onboarded: boolean }
  ) {
    try {
      const response = await axios.get("/company/company_onboard_completion");
    } catch (error) {
      await dispatchCheckApiError(context, error);
    }
  },

  async actionUpdateIsAuditCompleted(
    context: MainContext,
    payload: { id: number; is_audit_completed: boolean }
  ) {
    try {
      return true;
    } catch (error) {
      await dispatchCheckApiError(context, error);
    }
  },

  /**
   * This function is used to create company
   * @param context
   * @param payload
   * @returns
   */
  async actionCreateCompany(context: MainContext, payload: ICompanyCreate) {
    try {
      const loadingNotification = { content: "saving", showProgress: true };
      commitAddNotification(context, loadingNotification);
      const response = await axios.post("/company/create_company", payload);
      commitSetCompany(context, response.data);
      commitRemoveNotification(context, loadingNotification);
      commitAddNotification(context, {
        content: "Company successfully created",
        color: "success",
      });
      return response.data.id;
    } catch (error) {
      await dispatchCheckApiError(context, error);
    }
  },

  async actionSetLastDashboardSearch(
    context: MainContext,
    payload: ICompanyDashSearch
  ) {
    commitSetLastDashboardSearch(context, payload);
  },

  async actionSetLastTeamsDashboardSearch(
    context: MainContext,
    payload: ICompanyTeamsDashSearch
  ) {
    commitSetLastTeamsDashboardSearch(context, payload);
  },

  async actionChangePageLastDashboardSearch(
    context: MainContext,
    payload: { page: number; tab: string }
  ) {
    commitSetChangePageLastDashboardSearch(context, {
      page: payload.page,
      tab: payload.tab,
    });
  },

  /**
   * This function is used to check credentials for O365
   * @param context
   * @param payload
   * @returns
   */
  async actionTestConnection(context: MainContext, companyId: number) {
    try {
      if (companyId) {
        const response = await axios.get(
          "/company/microsoft/O365/verification"
        );

        if (response.data) {
          commitSetO356Verification(context, { is_o365_verified: true });
        }
      }
    } catch (error) {
      await dispatchCheckApiError(context, error);
    }
  },

  /**
   * This function is used to check credentials for microsoft teams
   * @param context
   * @param payload
   * @returns
   */
  async actionTestMicrosoftTeamsConnection(
    context: MainContext,
    companyId: number
  ) {
    try {
      if (companyId) {
        const response = await axios.get(
          "/company/microsoft/teams/verification"
        );

        if (response.data) {
          commitSetTeamsVerification(context, { is_teams_verified: true });
        }
      }
    } catch (error) {
      await dispatchCheckApiError(context, error);
    }
  },

  /**
   * This function get keywords list
   * @param context
   * @param companyId
   */
  async actionGetKeywordsList(context: MainContext) {
    try {
      commitSetKeywordsList(context, []);
      const response = await axios.get("/company/keyword_list");
      if (response.data) {
        commitSetKeywordsList(context, response.data);
      }
    } catch (error) {
      await dispatchCheckApiError(context, error);
    }
  },

  /**
   * This function is used to add keywords
   * @param context
   * @param payload
   */
  async actionAddKeyword(context: MainContext, payload: { keyword: string }) {
    try {
      const loadingNotification = { content: "Saving", showProgress: true };
      commitAddNotification(context, loadingNotification);
      await axios.post("/company/keyword_list", payload);
      commitRemoveNotification(context, loadingNotification);
      const successNotification = {
        content: `Keyword Added Successfully`,
        color: "success",
      };
      commitAddNotification(context, successNotification);
    } catch (error) {
      await dispatchCheckApiError(context, error);
    }
  },

  /**
   * This function is used to remove keyword
   * @param context
   * @param payload
   */
  async actionRemoveKeyword(context: MainContext, payload) {
    try {
      const deletePayload = {
        uuid: payload,
      };
      const loadingNotification = { content: "Removing", showProgress: true };
      commitAddNotification(context, loadingNotification);
      await axios.delete("/company/keyword_list/" + deletePayload.uuid);
      commitRemoveNotification(context, loadingNotification);
      const successNotification = {
        content: `Keyword Removed Successfully.`,
        color: "success",
      };
      commitAddNotification(context, successNotification);
    } catch (error) {
      await dispatchCheckApiError(context, error);
    }
  },

  async getMsO365OnboardingClientId(context: MainContext) {
    try {
      const response = await axios.get("/config/microsoft/mail");

      return response.data;
    } catch (error) {
      await dispatchCheckApiError(context, error);
    }
  },

  async getMsTeamsOnboardingClientId(context: MainContext) {
    try {
      const response = await axios.get("/config/microsoft/teams");

      return response.data;
    } catch (error) {
      await dispatchCheckApiError(context, error);
    }
  },
};

const { dispatch } = getStoreAccessors<CompanyState, State>("");

export const dispatchCreateCompany = dispatch(actions.actionCreateCompany);
export const dispatchGetCompanies = dispatch(actions.actionGetCompanies);
export const dispatchUpdateCompany = dispatch(actions.actionUpdateCompany);
export const dispatchAuditTrigger = dispatch(actions.actionAuditTrigger);
export const dispatchGetUserCompany = dispatch(actions.actionGetUserCompany);
export const dispatchSetLastDashboardSearch = dispatch(
  actions.actionSetLastDashboardSearch
);
export const dispatchSetLastTeamsDashboardSearch = dispatch(
  actions.actionSetLastTeamsDashboardSearch
);
export const dispatchUpdateIsOnboarded = dispatch(
  actions.actionUpdateIsOnboarded
);
export const dispatchUpdateIsAuditCompleted = dispatch(
  actions.actionUpdateIsAuditCompleted
);
export const dispatchImportUser = dispatch(actions.actionImportUser);
export const dispatchUploadExclusionCsv = dispatch(
  actions.actionUploadExclusionCsv
);
export const dispatchUploadKeywordCsv = dispatch(
  actions.actionUploadKeywordCsv
);
export const dispatchTestConnection = dispatch(actions.actionTestConnection);
export const dispatchGetKeywordsList = dispatch(actions.actionGetKeywordsList);
export const dispatchAddKeyword = dispatch(actions.actionAddKeyword);
export const dispatchRemoveKeyword = dispatch(actions.actionRemoveKeyword);
export const dispatchGetMsO365OnboardingClientId = dispatch(
  actions.getMsO365OnboardingClientId
);
export const dispatchGetMsTeamsOnboardingClientId = dispatch(
  actions.getMsTeamsOnboardingClientId
);
export const dispatchaTestMicrosoftTeamsConnection = dispatch(
  actions.actionTestMicrosoftTeamsConnection
);
export const dispatchChangePageDashboardSearch = dispatch(
  actions.actionChangePageLastDashboardSearch
);
