export const onboardingPopUp = {
  async msAdminConsent(msClientId, tenantId, msPopupWindow) {
    const dualScreenLeft =
      window.screenLeft !== undefined ? window.screenLeft : window.screenX;
    const dualScreenTop =
      window.screenTop !== undefined ? window.screenTop : window.screenY;

    const width = window.innerWidth
      ? window.innerWidth
      : document.documentElement.clientWidth
      ? document.documentElement.clientWidth
      : screen.width;
    const height = window.innerHeight
      ? window.innerHeight
      : document.documentElement.clientHeight
      ? document.documentElement.clientHeight
      : screen.height;

    const systemZoom = width / window.screen.availWidth;
    const left = (width - 450) / 2 / systemZoom + dualScreenLeft;
    const top = (height - 500) / 2 / systemZoom + dualScreenTop;

    const redirect_uri = `${location.origin}/onboarding`;
    const adminConsentLink = `https://login.microsoftonline.com/${tenantId}/adminconsent?client_id=${msClientId}&redirect_uri=${redirect_uri}`;

    msPopupWindow = window.open(
      adminConsentLink,
      "Microsoft Sign In",
      `
          scrollbars=yes,
          width=500, 
          height=550, 
          top=${top}, 
          left=${left}
          `
    );

    if (window.focus) msPopupWindow.focus();
  },
};
