
import SentimentCard from "@/components/SentimentCard.vue";
import { readCompany } from "@/store/main/getters";
import {
  dispatchGetEmailOutboundSentiment,
  dispatchGetEmailOverallSentiment,
  dispatchGetTeamsOverallSentiment,
  dispatchGetEmailInboundSentiment,
  dispatchGetEmailOrganizationalSentiment,
  dispatchGetAllTeamsSentiment,
  dispatchSetTimeframe,
} from "@/store/sentiment/actions";
import {
  readOverallEmailSentiment,
  readOverallTeamsSentiment,
  readOutboundEmailSentiment,
  readInboundEmailSentiment,
  readOrganizationalEmailSentiment,
  readTeamsByTeamSentiment,
} from "@/store/sentiment/getters";
import { Vue, Component } from "vue-property-decorator";

@Component({
  components: {
    SentimentCard,
  },
})
export default class Sentiment extends Vue {
  public displaySentiment = "email";

  mounted() {
    this.displaySentiment = this.company?.company_services.includes("microsoft")
      ? "msEmail"
      : "gmail";
  }

  public async fetchEmailOverallScores(startDate, onDone) {
    await dispatchGetEmailOverallSentiment(this.$store, {
      start_date: startDate,
    });
    onDone();
  }

  public setTimeframe(timeframe, title) {
    dispatchSetTimeframe(this.$store, {
      timeframe: timeframe,
      cardTitle: title,
    });
  }

  public async fetchEmailOrganizationalScores(startDate, onDone) {
    await dispatchGetEmailOrganizationalSentiment(this.$store, {
      start_date: startDate,
    });
    onDone();
  }

  public async fetchTeamsOverallScores(startDate, onDone) {
    await dispatchGetTeamsOverallSentiment(this.$store, {
      start_date: startDate,
    });
    onDone();
  }

  public async fetchIndividualTeamScores(start_date, team_name) {
    await dispatchGetAllTeamsSentiment(this.$store, {
      start_date: start_date,
      team_name: team_name,
    });
  }

  public async fetchOutboundScores(startDate, onDone) {
    await dispatchGetEmailOutboundSentiment(this.$store, {
      start_date: startDate,
    });
    onDone();
  }

  public async fetchInboundScores(startDate, onDone) {
    await dispatchGetEmailInboundSentiment(this.$store, {
      start_date: startDate,
    });
    onDone();
  }

  get overallEmailSentiment() {
    return readOverallEmailSentiment(this.$store);
  }

  get overallTeamsSentiment() {
    return readOverallTeamsSentiment(this.$store);
  }

  get allTeamsByTeamSentiment() {
    return readTeamsByTeamSentiment(this.$store);
  }

  get organizationalEmailSentiment() {
    return readOrganizationalEmailSentiment(this.$store);
  }

  get inboundEmailSentiment() {
    return readInboundEmailSentiment(this.$store);
  }

  get outboundEmailSentiment() {
    return readOutboundEmailSentiment(this.$store);
  }

  public toPercentage(num) {
    return num ? (num * 100).toFixed(1) + "%" : "n/a";
  }

  get company() {
    return readCompany(this.$store);
  }
}
