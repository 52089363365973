import { mutations } from "./mutations";
import { getters } from "./getters";
import { actions } from "./actions";
import { ItemState } from "./state";

export const defaultState: ItemState = {
  item: {
    uuid: null,
    email_created_at: null,
    email_sender: null,
    email_recipients: [],
    is_read: null,
    is_user_flagged: null,
    is_archived: null,
    subject: null,
    body: null,
    body_html: null,
  },
  toxicItems: {
    review: [],
    archived: [],
    flagged: [],
    all: [],
  },
  toxicItemsLoading: false,
  earliestMessageDate: null,
  forwards: [],
  itemCount: { review: 0, archived: 0, flagged: 0, all: 0 },
};

export const itemModule = {
  state: defaultState,
  mutations,
  actions,
  getters,
};
