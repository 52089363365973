import axios from "../interceptor";
import { getStoreAccessors } from "typesafe-vuex";
import { ActionContext } from "vuex";
import { dispatchCheckApiError } from "../main/actions";
import { State } from "../state";
import {
  commitSetEmailOverallSentiment,
  commitSetTeamsOverallSentiment,
  commitSetEmailOutboundSentiment,
  commitSetEmailInboundSentiment,
  commitSetEmailOrganizationalSentiment,
  commitSetAllTeamsSentiment,
  commitSetIndividualTeamSentiment,
  commitSetEmailOverallTimeframe,
  commitSetEmailOrganizationalTimeframe,
  commitSetEmailInboundTimeframe,
  commitSetEmailOutboundTimeframe,
  commitSetTeamsOverallTimeframe,
  commitSetIndividualTeamTimeframe,
} from "./mutations";
import { SentimentState } from "./state";

type MainContext = ActionContext<SentimentState, State>;

export const actions = {
  /**
   * @param context
   * @param queries
   * @returns
   */
  async actionGetEmailOverallSentiment(
    context: MainContext,
    queries: {
      start_date: string;
    }
  ) {
    try {
      const response = await axios.get("/item/sentiment", {
        params: {
          start_date: queries.start_date,
        },
      });
      if (response) {
        const sentiment = response.data;
        commitSetEmailOverallSentiment(context, sentiment);

        return sentiment;
      }
    } catch (error) {
      await dispatchCheckApiError(context, error);

      return "error";
    }
  },

  /**
   * @param context
   * @param queries
   * @returns
   */
  async actionGetEmailOutboundSentiment(
    context: MainContext,
    queries: {
      start_date: string;
    }
  ) {
    try {
      const response = await axios.get("/item/sentiment", {
        params: {
          start_date: queries.start_date,
          is_outbound: true,
        },
      });
      if (response) {
        const outboundSentiment = response.data;
        commitSetEmailOutboundSentiment(context, outboundSentiment);

        return outboundSentiment;
      }
    } catch (error) {
      await dispatchCheckApiError(context, error);

      return "error";
    }
  },

  /**
   * @param context
   * @param queries
   * @returns
   */
  async actionGetEmailInboundSentiment(
    context: MainContext,
    queries: {
      start_date: string;
    }
  ) {
    try {
      const response = await axios.get("/item/sentiment", {
        params: {
          start_date: queries.start_date,
          email_is_external: true,
        },
      });
      if (response) {
        const inboundSentiment = response.data;
        commitSetEmailInboundSentiment(context, inboundSentiment);

        return inboundSentiment;
      }
    } catch (error) {
      await dispatchCheckApiError(context, error);

      return "error";
    }
  },

  /**
   * @param context
   * @param queries
   * @returns
   */
  async actionGetEmailOrganizationalSentiment(
    context: MainContext,
    queries: {
      start_date: string;
    }
  ) {
    try {
      const response = await axios.get("/item/sentiment", {
        params: {
          start_date: queries.start_date,
          internal: true,
        },
      });
      if (response) {
        const organizationalSentiment = response.data;
        commitSetEmailOrganizationalSentiment(context, organizationalSentiment);

        return organizationalSentiment;
      }
    } catch (error) {
      await dispatchCheckApiError(context, error);

      return "error";
    }
  },

  /**
   * @param context
   * @param queries
   * @returns
   */
  async actionGetTeamsOverallSentiment(
    context: MainContext,
    queries: {
      start_date: string;
    }
  ) {
    try {
      const response = await axios.get("/teams/sentiment/overall", {
        params: {
          start_date: queries.start_date,
        },
      });
      if (response) {
        const sentiment = response.data;
        commitSetTeamsOverallSentiment(context, sentiment);

        return sentiment;
      }
    } catch (error) {
      await dispatchCheckApiError(context, error);

      return "error";
    }
  },

  /**
   * @param context
   * @param queries
   * @returns
   */
  async actionGetAllTeamsSentiment(
    context: MainContext,
    queries: {
      start_date: string;
      team_name?: string;
    }
  ) {
    try {
      const response = await axios.get("/teams/sentiment", {
        params: {
          start_date: queries.start_date,
          team_name: queries.team_name,
        },
      });

      if (response && !queries.team_name) {
        const sentiment = response.data.teams_sentiment;

        commitSetAllTeamsSentiment(context, sentiment);

        return sentiment;
      } else if (response && queries.team_name) {
        const sentiment = response.data.teams_sentiment[0];

        commitSetIndividualTeamSentiment(context, sentiment);

        return sentiment;
      }
    } catch (error) {
      await dispatchCheckApiError(context, error);

      return "error";
    }
  },

  async actionSetTimeframe(
    context: MainContext,
    queries: {
      timeframe: number;
      cardTitle: string;
    }
  ) {
    if (queries.cardTitle == "Overall") {
      commitSetEmailOverallTimeframe(context, queries.timeframe);
    } else if (queries.cardTitle == "Organizational") {
      commitSetEmailOrganizationalTimeframe(context, queries.timeframe);
    } else if (queries.cardTitle == "Inbound") {
      commitSetEmailInboundTimeframe(context, queries.timeframe);
    } else if (queries.cardTitle == "Outbound") {
      commitSetEmailOutboundTimeframe(context, queries.timeframe);
    } else if (queries.cardTitle == "TeamsOverall") {
      commitSetTeamsOverallTimeframe(context, queries.timeframe);
    } else {
      commitSetIndividualTeamTimeframe(context, queries);
    }
  },
};

const { dispatch } = getStoreAccessors<SentimentState, State>("");

export const dispatchGetEmailOverallSentiment = dispatch(
  actions.actionGetEmailOverallSentiment
);
export const dispatchGetTeamsOverallSentiment = dispatch(
  actions.actionGetTeamsOverallSentiment
);
export const dispatchGetEmailOutboundSentiment = dispatch(
  actions.actionGetEmailOutboundSentiment
);
export const dispatchGetEmailInboundSentiment = dispatch(
  actions.actionGetEmailInboundSentiment
);
export const dispatchGetEmailOrganizationalSentiment = dispatch(
  actions.actionGetEmailOrganizationalSentiment
);
export const dispatchGetAllTeamsSentiment = dispatch(
  actions.actionGetAllTeamsSentiment
);
export const dispatchSetTimeframe = dispatch(actions.actionSetTimeframe);
