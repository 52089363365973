
import { Component, Vue } from "vue-property-decorator";
import { appName } from "@/env";
import { dispatchPasswordRecovery } from "@/store/main/actions";

@Component
export default class PasswordRecovery extends Vue {
  public valid = true;
  public email = "";
  public appName = appName;
  public loading = false;

  public async mounted() {
    if (this.$route.params.email) {
      this.email = this.$route.params.email;
    }
  }

  public async submit() {
    this.loading = true;
    await dispatchPasswordRecovery(this.$store, { username: this.email })
      .then((res) => {
        this.$router
          .push({ name: "Reset Password", params: { email: this.email } })
          .catch((err) => err);
        this.loading = false;
      })
      .catch((error) => {
        this.loading = false;
      });
  }
}
