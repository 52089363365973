import { MicrosoftTeamsItemState } from "./state";
import { getStoreAccessors } from "typesafe-vuex";
import { State } from "../state";

export const getters = {
  microsoftTeamsItemById: (state: MicrosoftTeamsItemState) => state.item,
  microsoftTeamsToxicItems: (state: MicrosoftTeamsItemState) =>
    state.toxicItems,
  microsoftTeamsToxicItemsLoading: (state: MicrosoftTeamsItemState) =>
    state.toxicItemsLoading,
  microsoftTeamsFlaggedMessages: (state: MicrosoftTeamsItemState) =>
    state.toxicItems.flagged,
  microsoftTeamsReviewMessages: (state: MicrosoftTeamsItemState) =>
    state.toxicItems.review,
  microsoftTeamsArchivedMessages: (state: MicrosoftTeamsItemState) =>
    state.toxicItems.archived,
  microsoftTeamsAllMessages: (state: MicrosoftTeamsItemState) =>
    state.toxicItems.all,
  microsoftTeamsEarliestMessageDate: (state: MicrosoftTeamsItemState) =>
    state.earliestMessageDate,
  microsoftTeamsForwards: (state: MicrosoftTeamsItemState) => state.forwards,
  microsoftTeamsToxicItemCount: (state: MicrosoftTeamsItemState) =>
    state.itemCount,
};

const { read } = getStoreAccessors<MicrosoftTeamsItemState, State>("");

export const readMicrosoftTeamsToxicItems = read(
  getters.microsoftTeamsToxicItems
);
export const readMicrosoftTeamsToxicItemsLoading = read(
  getters.microsoftTeamsToxicItemsLoading
);
export const readMicrosoftTeamsItemById = read(getters.microsoftTeamsItemById);
export const readMicrosoftTeamsFlaggedMessages = read(
  getters.microsoftTeamsFlaggedMessages
);
export const readMicrosoftTeamsReviewMessages = read(
  getters.microsoftTeamsReviewMessages
);
export const readMicrosoftTeamsArchivedMessages = read(
  getters.microsoftTeamsArchivedMessages
);
export const readMicrosoftTeamsEarliestMessageDate = read(
  getters.microsoftTeamsEarliestMessageDate
);
export const readMicrosoftTeamsForwards = read(getters.microsoftTeamsForwards);
export const readMicrosoftTeamsToxicItemCount = read(
  getters.microsoftTeamsToxicItemCount
);
export const readMicrosoftTeamsAllMessages = read(
  getters.microsoftTeamsAllMessages
);
