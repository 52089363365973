
import { Component, Vue } from "vue-property-decorator";
import { readUserProfile } from "@/store/main/getters";
import { dispatchChangePassword } from "@/store/main/actions";

@Component
export default class UserProfileEdit extends Vue {
  public valid = true;
  public oldPassword = "";
  public newPassword = "";
  public loading = false;
  public regex =
    /^(?=.*[a-z ])(?=.*[A-Z ])(?=.*[0-9])(?=.*[=+\-^$*.\[\]{}()?"!@#%&\/\\,><':;|_~`]).{8,99}$/;

  get userProfile() {
    return readUserProfile(this.$store);
  }

  public cancel() {
    this.$router.back();
  }

  public validatePassword(password: string) {
    return this.regex.test(password);
  }

  public async submit() {
    this.loading = true;
    if (await this.$validator.validateAll()) {
      await dispatchChangePassword(this.$store, {
        oldPassword: this.oldPassword,
        newPassword: this.newPassword,
      }).then((res) => {
        if (res !== undefined) {
          this.$router.push("/main/profile");
        }
        this.loading = false;
      });
    }
  }
}
