
import { Component, Vue } from "vue-property-decorator";
import { IModeratorCreate } from "@/interfaces";
import { readCompanies } from "@/store/companies/getters";
import { dispatchGetCompanies } from "@/store/companies/actions";
import { dispatchCreateModerator } from "@/store/moderators/actions";

@Component
export default class CreateUser extends Vue {
  public valid = false;
  public fullName = "";
  public email = "";
  public isSuperuser = false;
  public companyId = 0;
  public selectedCompany = {};

  public async mounted() {
    await dispatchGetCompanies(this.$store, {});
    this.reset();
  }

  get companies() {
    return readCompanies(this.$store);
  }

  public async updateSelectedCompany(id: number) {
    this.selectedCompany = this.companies.find((company) => {
      return company.company_id === id;
    });
  }

  public reset() {
    this.fullName = "";
    this.email = "";
    this.isSuperuser = false;
    this.companyId = 0;
    this.$validator.reset();
  }

  public async submit() {
    if (await this.$validator.validateAll()) {
      const updatedProfile: IModeratorCreate = {
        full_name: this.fullName,
        email: this.email,
        moderatorgroup: "Internal"
      };
      if (this.fullName) {
        updatedProfile.full_name = this.fullName;
      }
      if (this.email) {
        updatedProfile.email = this.email;
      }
      if (this.companyId) {
        updatedProfile.company_id = this.companyId;
      }
      if (this.isSuperuser) {
        updatedProfile.is_superuser = this.isSuperuser;
      }
      await dispatchCreateModerator(this.$store, updatedProfile);
      this.$router.push("/main/admin/companies/all");
    }
  }
}
