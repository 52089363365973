
import { Component, Vue, Prop } from "vue-property-decorator";
import {
  IUserProfile,
  IForwardCreate,
  IMicrosoftTeamsItem,
} from "@/interfaces";
import { dispatchGetForwards } from "@/store/items/actions";
import { dispatchCreateTeamsForward } from "@/store/teamsItems/actions";

@Component
export default class ForwardEmailForm extends Vue {
  @Prop() public readonly userProfile: IUserProfile;
  @Prop() public readonly itemDetail: IMicrosoftTeamsItem;
  public dialog = false;
  public forwardList: any[] = [];
  public emailRegex =
    // eslint-disable-next-line no-control-regex
    /(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/;
  public enteredEmail = "";
  public emailRules = [
    (v) =>
      /^(\s?[^\s,]+@[^\s,]+\.[^\s,]+\s?,)*(\s?[^\s,]+@[^\s,]+\.[^\s,]+)$/g.test(
        v
      ) ||
      v.length === 0 ||
      "E-mail must be valid",
  ];
  public form: IForwardCreate = {
    item_id: null,
    recipients: [],
    sender: "",
  };

  public async mounted() {
    this.forwardList = [];
  }

  public closeDialog() {
    this.dialog = false;
    this.reset();
  }

  public reset() {
    this.forwardList = [];
    this.enteredEmail = "";
  }

  public removeEmail(index: number) {
    this.forwardList.splice(index, 1);
  }

  public async addEnteredEmail() {
    if (this.enteredEmail.includes(",")) {
      const emailArr = this.enteredEmail.split(",");
      emailArr.forEach((e) => {
        this.validateEnteredEmail(e.trim().toLowerCase());
      });
    } else {
      this.validateEnteredEmail(this.enteredEmail.trim());
    }
  }

  public validateEnteredEmail(email: string) {
    if (this.forwardList.indexOf(email.toLowerCase()) > -1) {
      this.enteredEmail = "";
    } else if (this.emailRegex.test(email) && email) {
      if (this.forwardList.length < 10) {
        this.forwardList.push(email.toLowerCase());
        this.enteredEmail = "";
      }
    }
  }

  public async submitForwards() {
    // hook up API to submit forwards here
    this.form = {
      item_id: this.itemDetail.uuid,
      recipients: this.forwardList,
      sender: this.userProfile.email,
    };
    this.createForward().then((x) => {
      dispatchGetForwards(this.$store, this.$router.currentRoute.params.id);
      this.dialog = false;
    });
    this.forwardList = [];
  }

  public async createForward() {
    await dispatchCreateTeamsForward(this.$store, this.form);
  }
}
