import { MainState } from "./state";
import { getStoreAccessors } from "typesafe-vuex";
import { State } from "../state";

export const getters = {
  hasAdminAccess: (state: MainState) => {
    return (
      state.userProfile &&
      state.userProfile.is_superuser &&
      state.userProfile.is_active
    );
  },
  hasCompanyAdminAccess: (state: MainState) => {
    return (
      state.userProfile &&
      state.userProfile.is_company_admin &&
      state.userProfile.is_active
    );
  },
  userCompanyId: (state: MainState) => state.userProfile?.company_id,
  loginError: (state: MainState) => state.logInError,
  dashboardShowDrawer: (state: MainState) => state.dashboardShowDrawer,
  dashboardMiniDrawer: (state: MainState) => state.dashboardMiniDrawer,
  userProfile: (state: MainState) => state.userProfile,
  token: (state: MainState) => state.token,
  isLoggedIn: (state: MainState) => state.isLoggedIn,
  firstNotification: (state: MainState) =>
    state.notifications.length > 0 && state.notifications[0],
  lastNotification: (state: MainState) =>
    state.notifications.length > 0 &&
    state.notifications[state.notifications.length - 1],
  company: (state: MainState) => state.company,
  isOnboarded: (state: MainState) => state.isOnboarded,
  newPasswordRequired: (state: MainState) => state.newPasswordRequired,
};

const { read } = getStoreAccessors<MainState, State>("");

export const readDashboardMiniDrawer = read(getters.dashboardMiniDrawer);
export const readDashboardShowDrawer = read(getters.dashboardShowDrawer);
export const readHasAdminAccess = read(getters.hasAdminAccess);
export const readIsLoggedIn = read(getters.isLoggedIn);
export const readLoginError = read(getters.loginError);
export const readToken = read(getters.token);
export const readUserProfile = read(getters.userProfile);
export const readFirstNotification = read(getters.firstNotification);
export const readHasCompanyAdminAccess = read(getters.hasCompanyAdminAccess);
export const readUserCompanyId = read(getters.userCompanyId);
export const readLastNotification = read(getters.lastNotification);
export const readIsOnboarded = read(getters.isOnboarded);
export const readCompany = read(getters.company);
export const readNewPasswordRequired = read(getters.newPasswordRequired);
