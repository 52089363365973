
import { Component, Vue } from "vue-property-decorator";
import { readCompanyUsers } from "@/store/admin/getters";
import { dispatchVerifyGmail } from "@/store/admin/actions";
import { readCompany, readUserProfile } from "@/store/main/getters";
import {
  dispatchGetUserCompany,
  dispatchUpdateIsOnboarded,
} from "@/store/companies/actions";
import {
  readImportUser,
  readIsO365Verified,
  readIsTeamsVerified,
} from "@/store/companies/getters";
import {
  dispatchGetUserProfile,
  dispatchUserLogOut,
} from "@/store/main/actions";
import { readModerators } from "@/store/moderators/getters";
import ExcludedList from "@/components/ExcludedList.vue";
import Moderators from "@/components/Moderators.vue";
import ModeratorOptions from "@/components/ModeratorOptions.vue";
import ModeratorSMS from "@/components/ModeratorSMS.vue";
import OnboardingReview from "@/components/OnboardingReview.vue";
import { defaultState } from "@/store/items/index";
import { readExcludedList } from "@/store/excluded/getters";
import MicrosoftTeamsAuthorization from "@/components/MicrosoftTeamsAuthentication.vue";
import O365Authorization from "@/components/O365Authentication.vue";
import GmailAuthentication from "@/components/GmailAuthentication.vue";

@Component({
  components: {
    ExcludedList,
    ModeratorSMS,
    Moderators,
    OnboardingReview,
    ModeratorOptions,
    MicrosoftTeamsAuthorization,
    O365Authorization,
    GmailAuthentication,
  },
})
export default class Onboarding extends Vue {
  public e1 = 0;
  public loggedIn = false;
  public formCompleted = false;
  public importLoading = false;
  public tenantId: any;
  public adminConsent = false;
  public msAuthError = false;
  public msAuthErrorDetails: any;
  public msLoginError = false;

  public enableNext = false;
  public verifyLoading = false;
  public fileCountsData: any = {
    total_count: 0,
    total_invalid_mail: 0,
    total_valid_mail: 0,
  };
  public isFormValid = true;
  public selectedModeratorOption: any = "";
  public uploadCsvTriggered = false;
  public csvUploaded = false;
  public phoneNumberRegex =
    /^(\+\d{1,2}\s)?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/;

  public async mounted() {
    await dispatchGetUserProfile(this.$store);
    await dispatchGetUserCompany(this.$store, this.userProfile?.company_id);

    if (!this.company?.company_id || !this.userProfile?.company_id) {
      await dispatchGetUserCompany(this.$store, this.userProfile?.company_id);
    }
  }

  public async verifyGmail(serverAdmin) {
    this.verifyLoading = true;
    await dispatchVerifyGmail(this.$store, serverAdmin).then(
      (res) => {
        if (res.data) {
          this.enableNext = true;
        }
        this.verifyLoading = false;
      },
      (err) => {
        this.verifyLoading = false;
      }
    );
  }

  public async completeOnboarding() {
    this.e1 = 7;
    dispatchUpdateIsOnboarded(this.$store, {
      id: this.company.company_id,
      is_onboarded: false,
    }).then((x): void => {
      this.formCompleted = true;
      setTimeout(
        () =>
          this.$router
            .push({ path: "/main/company/email" })
            .catch((err) => err),
        2000
      );
    });
  }

  public async navigateToDashboard() {
    this.$router.push({ path: "/main/company/email" });
  }

  get onboardingCompleted() {
    if (this.moderators.length < 1) {
      return false;
    } else {
      return true;
    }
  }

  public gmailOnly() {
    return (
      this.company &&
      (this.company.is_onboarded || this.enableNext) &&
      this.company.company_services.includes("gmail") &&
      this.company.company_services.includes("teams")
    );
  }

  public gmailTeams() {
    return (
      this.company &&
      (this.company.is_onboarded || this.enableNext) &&
      this.company.company_services.includes("gmail") &&
      !this.company.company_services.includes("teams")
    );
  }

  public microsoftOnly() {
    return (
      this.company &&
      this.company.company_services.includes("microsoft") &&
      this.company.company_services.includes("teams") &&
      (this.company.is_onboarded || this.enableNext || this.o365Verification)
    );
  }

  public microsoftAndTeams() {
    return (
      this.company &&
      this.company.company_services.includes("microsoft") &&
      !this.company.company_services.includes("teams") &&
      (this.company.is_onboarded || this.enableNext || this.o365Verification)
    );
  }

  public async edit(stepNumber: number) {
    this.e1 = stepNumber;
  }

  public async fileCounts(res: any) {
    this.fileCountsData = res;
  }

  public async validForm(res: any) {
    this.isFormValid = res;
  }

  public async selectedElement(res: any) {
    this.selectedModeratorOption = res;
  }

  public async isCsvUploaded(res: any) {
    this.csvUploaded = res;
    this.uploadCsvTriggered = res;
  }

  get moderators() {
    return readModerators(this.$store);
  }

  get company() {
    return readCompany(this.$store);
  }

  get users() {
    return readCompanyUsers(this.$store);
  }

  get userProfile() {
    return readUserProfile(this.$store);
  }

  get excludedList() {
    return readExcludedList(this.$store);
  }

  get importUsers() {
    return readImportUser(this.$store);
  }

  get o365Verification() {
    return readIsO365Verified(this.$store);
  }

  get teamsVerification() {
    return readIsTeamsVerified(this.$store);
  }

  public async logout() {
    // clear stores that are not needed
    defaultState.toxicItems = {
      review: [],
      archived: [],
      flagged: [],
      all: [],
    };
    await dispatchUserLogOut(this.$store);
  }
}
