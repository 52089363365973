
import { Vue, Component } from "vue-property-decorator";
import { appName } from "@/env";
import {
  readDashboardMiniDrawer,
  readDashboardShowDrawer,
  readHasAdminAccess,
  readHasCompanyAdminAccess,
  readCompany,
  readUserProfile
} from "@/store/main/getters";
import {
  commitSetDashboardShowDrawer,
  commitSetDashboardMiniDrawer
} from "@/store/main/mutations";
import { dispatchUserLogOut } from "@/store/main/actions";
import { readServiceNowUser } from "@/store/serviceNow/getters";
import {
  dispatchGetServiceNowUser,
  dispatchVerifyServiceNowAccount
} from "@/store/serviceNow/actions";
import { defaultState } from "@/store/items/index";
import { TIMEFRAME_SEARCH_DEFAULT } from "@/store/sentiment";
import {
  dispatchGetEmailOverallSentiment,
  dispatchGetEmailOutboundSentiment,
  dispatchGetEmailInboundSentiment,
  dispatchGetEmailOrganizationalSentiment,
  dispatchGetAllTeamsSentiment,
  dispatchGetTeamsOverallSentiment
} from "@/store/sentiment/actions";
import moment from "moment";
import {
  dispatchGetCompanyCountsSummary,
  dispatchGetMicrosoftTeamsCompanyCountsSummary
} from "@/store/counts/actions";
import {
  dispatchGetToxicItemCount,
  dispatchGetToxicItems
} from "@/store/items/actions";
import {
  dispatchGetMicrosoftTeamsToxicItemCount,
  dispatchGetMicrosoftTeamsToxicItems
} from "@/store/teamsItems/actions";

@Component
export default class Main extends Vue {
  public appName = appName;
  public displayDisconnectAlert = false;
  public hideServiceNow = false;
  public teamsService: boolean = null;
  public teamsTab: any = "none";
  public startDate: string = moment()
    .subtract(TIMEFRAME_SEARCH_DEFAULT, "days")
    .startOf("day")
    .utc()
    .format();

  async mounted() {
    this.teamsService = this.company?.company_services.includes("teams");
    this.teamsTab = !this.teamsService ? "teams-tab" : "none";
    if (this.company) {
      let promises = [];
      promises.push([
        //Sentiment calls
        dispatchGetEmailOverallSentiment(this.$store, {
          start_date: this.startDate
        }),
        dispatchGetEmailOutboundSentiment(this.$store, {
          start_date: this.startDate
        }),
        dispatchGetEmailInboundSentiment(this.$store, {
          start_date: this.startDate
        }),
        dispatchGetEmailOrganizationalSentiment(this.$store, {
          start_date: this.startDate
        }),
        dispatchGetAllTeamsSentiment(this.$store, {
          start_date: this.startDate
        }),
        //Email calls
        dispatchGetCompanyCountsSummary(this.$store, {
          companyId: this.company.company_id
        }),
        dispatchGetToxicItemCount(this.$store, {
          start: this.startDate
        }),
        dispatchGetToxicItems(this.$store, {
          start: this.startDate,
          limit: 10,
          offset: 0
        }),
        //Teams calls
        dispatchGetMicrosoftTeamsCompanyCountsSummary(this.$store, {
          companyId: this.company.company_id
        }),
        dispatchGetMicrosoftTeamsToxicItemCount(this.$store, {
          start: this.startDate
        }),
        dispatchGetMicrosoftTeamsToxicItems(this.$store, {
          start: this.startDate,
          limit: 10,
          offset: 0
        })
      ]);

      if (this.company?.company_services.includes("teams")) {
        promises.push(
          dispatchGetTeamsOverallSentiment(this.$store, {
            start_date: this.startDate
          })
        );
      }
      Promise.all(promises);
    }
  }

  get miniDrawer() {
    return readDashboardMiniDrawer(this.$store);
  }
  get showDrawer() {
    return readDashboardShowDrawer(this.$store);
  }
  set showDrawer(value) {
    commitSetDashboardShowDrawer(this.$store, value);
  }
  public switchShowDrawer() {
    commitSetDashboardShowDrawer(
      this.$store,
      !readDashboardShowDrawer(this.$store)
    );
  }
  public switchMiniDrawer() {
    commitSetDashboardMiniDrawer(
      this.$store,
      !readDashboardMiniDrawer(this.$store)
    );
  }

  public get hasAdminAccess() {
    return readHasAdminAccess(this.$store);
  }
  public get hasCompanyAdminAccess() {
    return readHasCompanyAdminAccess(this.$store);
  }
  public async logout() {
    // clear stores that are not needed
    defaultState.toxicItems = {
      review: [],
      archived: [],
      flagged: [],
      all: []
    };
    await dispatchUserLogOut(this.$store);
  }
  public get currentRouteName() {
    return this.$route.name;
  }

  get userProfile() {
    return readUserProfile(this.$store);
  }

  get company() {
    return readCompany(this.$store);
  }

  public async verifyServiceNowAccount() {
    await dispatchVerifyServiceNowAccount(this.$store, this.company.company_id);
  }

  public async getServiceNowUser() {
    await dispatchGetServiceNowUser(this.$store, this.company.company_id);
  }

  public get verified() {
    const verified = this.$store.getters.serviceNowUser.verified;
    if (!verified && this.serviceNowUser) {
      this.displayDisconnectAlert = true;
    }
    return verified;
  }

  public get serviceNowUser() {
    const snUser = readServiceNowUser(this.$store);
    if (!snUser.company_id) {
      return null;
    } else {
      return snUser;
    }
  }
}
