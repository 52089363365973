
import { dispatchImportUser } from "@/store/companies/actions";
import { readImportUser } from "@/store/companies/getters";
import { commitAddNotification } from "@/store/main/mutations";
import { Component, Vue, Prop, Emit, Watch } from "vue-property-decorator";
import VuePapaParse from "vue-papa-parse";
Vue.use(VuePapaParse);

@Component
export default class ModeratorOptions extends Vue {
  [x: string]: any;
  @Prop() public readonly uploadCsvTriggered: any;
  emits: ["fileCountsData", "moderatorOption", "isCsvUploaded"];
  public selectedOption: any = "all_users";
  public fileCounts: any = {
    total_count: 0,
    total_invalid_mail: 0,
    total_valid_mail: 0,
  };
  public isCsvEvaluate = false;
  public isLoading = false;
  public isCsvUploaded = false;
  public selectedCsvFile: any;
  public isHeaderMismatched = false;

  public async mounted() {
    this.$emit("moderatorOption", this.selectedOption);
  }

  @Watch("uploadCsvTriggered")
  public async function(newValue: any, oldValue: any) {
    if (this.uploadCsvTriggered) {
      this.uploadApiTriggered();
    }
  }

  public async updated() {
    this.$emit("moderatorOption", this.selectedOption);
  }

  chooseFiles() {
    const ref: any = document.getElementById("fileUpload");
    ref.value = "";
    ref.click();
  }

  onFilePicked(event: any) {
    if (event.target.files[0].size > 100000000) {
      commitAddNotification(this.$store, {
        content: "File size must be less than 100 mb",
        color: "error",
      });
      return;
    }
    this.selectedCsvFile = event.target.files[0];
    this.parseFile();
    this.selectedOption = "specified_users";
    this.isCsvUploaded = false;
    this.$emit("isCsvUploaded", this.isCsvUploaded);
  }

  async parseFile() {
    let data: any = [];
    let headerMismatched = false;
    const csvParsing = new Promise((res, rej) => {
      this.$papa.parse(this.selectedCsvFile, {
        header: true,
        skipEmptyLines: true,
        delimiter: ",",
        step: function (results: any, parser: any) {
          if (results.data.email_address == undefined) {
            // alert('header mismatched');
            parser.abort();
            headerMismatched = true;
          }
          data.push(results.data);
        },
        complete: function () {
          res(true);
        },
      });
    });
    await csvParsing.then((res) => {
      this.isHeaderMismatched = headerMismatched;
      if (!this.isHeaderMismatched) {
        const counts = this.validateUserCsv(data);
        this.fileCounts = counts;
        this.isCsvEvaluate = true;
        this.$emit("moderatorOption", this.selectedOption);
        this.$emit("fileCountsData", this.fileCounts);
      }
    });
  }

  downloadSampleCsv() {
    const sampleData = [
      { email_address: "test@gmail.com" },
      { email_address: "xyz@gmail.com" },
      { email_address: "testing@gmail.com" },
    ];
    const csv = this.$papa.unparse(sampleData);
    this.$papa.download(csv, "inclusion_list");
  }

  public validateUserCsv(data: any) {
    let counts = { total_count: 0, total_invalid_mail: 0, total_valid_mail: 0 };
    data.forEach((res: any) => {
      if (res.email_address == "") {
        return;
      }
      if (this.validateEmail(res.email_address)) {
        counts.total_valid_mail += 1;
      } else {
        counts.total_invalid_mail++;
      }
      counts.total_count++;
    });
    return counts;
  }

  validateEmail(email: any) {
    if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(email)) {
      return true;
    }
    return false;
  }

  async uploadApiTriggered() {
    if (
      !(
        this.fileCounts.total_invalid_mail == 0 &&
        this.fileCounts.total_valid_mail > 0
      )
    ) {
      return;
    }
    this.isLoading = true;
    const payload = new FormData();
    payload.append("upload_file", this.selectedCsvFile);
    await dispatchImportUser(this.$store, payload).then((response) => {
      this.isLoading = false;
      if (response) {
        this.isCsvUploaded = true;
        this.$emit("isCsvUploaded", this.isCsvUploaded);
      } else {
        this.isCsvUploaded = false;
        this.$emit("isCsvUploaded", this.isCsvUploaded);
      }
    });
  }

  get importUsers() {
    return readImportUser(this.$store);
  }
}
