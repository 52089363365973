import { ServiceNowState } from "./state";
import { getStoreAccessors } from "typesafe-vuex";
import { State } from "../state";

export const getters = {
  serviceNowUser: (state: ServiceNowState) => state.serviceNowUser,
  verified: (state: ServiceNowState) => state.verified,
};

const { read } = getStoreAccessors<ServiceNowState, State>("");

export const readServiceNowUser = read(getters.serviceNowUser);
export const readServiceNowVerified = read(getters.verified);
