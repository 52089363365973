import { ServiceNowState } from "./state";
import { getStoreAccessors } from "typesafe-vuex";
import { State } from "../state";

export const mutations = {
  setServiceNowUser(state: ServiceNowState, payload) {
    state.serviceNowUser = payload;
  },
  setVerified(state: ServiceNowState, payload) {
    state.verified = payload;
  },
};

const { commit } = getStoreAccessors<ServiceNowState, State>("");

export const commitSetServiceNowUser = commit(mutations.setServiceNowUser);
export const commitSetVerified = commit(mutations.setVerified);
