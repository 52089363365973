
import { Component, Vue } from "vue-property-decorator";
import { IModeratorUpdate } from "@/interfaces";
import { readUserProfile } from "@/store/main/getters";
import { dispatchGetUserProfile } from "@/store/main/actions";
import { readUserCompany } from "@/store/companies/getters";
import { dispatchUpdateModerator } from "@/store/moderators/actions";
import { dispatchGetUserCompany } from "@/store/companies/actions";

@Component
export default class UserProfileEdit extends Vue {
  public valid = true;
  public fullName = "";
  public email = "";
  public companyId = 0;
  public fullNameRule = [(v) => !!v.trim() || "Full name is required"];

  public created() {
    const userProfile = readUserProfile(this.$store);
    if (userProfile) {
      this.fullName = userProfile.full_name;
      this.email = userProfile.email;
    }
  }

  public async mounted() {
    // await dispatchGetUserProfile(this.$store);
    if (this.userProfile!.company_id) {
      await dispatchGetUserCompany(this.$store, this.userProfile!.company_id);
    }
    this.reset();
  }

  public async updateModerator() {
    const moderator = {
      full_name: this.fullName,
      email: this.email,
    };
    await dispatchUpdateModerator(this.$store, {
      email: this.email,
      notificationsOff: true,
      moderator,
    });
    await dispatchGetUserProfile(this.$store);
  }

  get company() {
    return readUserCompany(this.$store);
  }

  get userProfile() {
    return readUserProfile(this.$store);
  }

  public reset() {
    const userProfile = readUserProfile(this.$store);
    if (userProfile) {
      this.fullName = userProfile.full_name;
      this.email = userProfile.email;
      if (userProfile.company_id) {
        this.companyId = userProfile.company_id;
      }
    }
  }

  public cancel() {
    this.$router.back();
  }

  public async submit() {
    if ((this.$refs.form as any).validate()) {
      const updatedProfile: IModeratorUpdate = {};
      if (this.fullName) {
        updatedProfile.full_name = this.fullName;
      }
      if (this.email) {
        updatedProfile.email = this.email;
      }

      await this.updateModerator();
      this.$router.push("/main/profile");
    }
  }
}
