import { ActionContext } from "vuex";
import { State } from "../state";
import { ModeratorState } from "./state";
import {
  commitSetModerators,
  commitSetNonModeratorUsers,
  commitSetModerator,
} from "./mutations";
import { readModerators } from "./getters";
import { getStoreAccessors } from "typesafe-vuex";
import { dispatchCheckApiError } from "../main/actions";
import {
  commitAddNotification,
  commitRemoveNotification,
} from "../main/mutations";
import { Frequency, IModerator, IModeratorUpdate, IUserProfile } from "@/interfaces";
import axios from "../interceptor";

type MainContext = ActionContext<ModeratorState, State>;

export const actions = {
  /**
   * This function is used to get moderators list
   * @param context
   * @param companyId
   */
  async actionGetModerators(context: MainContext, companyId: number) {
    try {
      if (companyId) {
        const response = await axios.get("moderator/list_moderator");
        if (response) {
          const internalMods = [];
          const externalMods = [];
          const moderators = [];
          const modList = response.data.sort((a, b) => {
            if (a.full_name < b.full_name) {
              return -1;
            }
            if (a.full_name > b.full_name) {
              return 1;
            }
            return 0;
          });
          for (const item of modList) {
            if (item.phone_number) {
              item.phone_number = item.phone_number.substring(2);
            }
            if (
              item.moderatorgroup === "Internal" ||
              item.moderatorgroup === "internal"
            ) {
              internalMods.push(item);
            } else if (
              item.moderatorgroup === "External" ||
              item.moderatorgroup === "external"
            ) {
              externalMods.push(item);
            }
            moderators.push(item);
          }
          commitSetModerators(context, moderators);
        }
      }
    } catch (error) {
      await dispatchCheckApiError(context, error);
    }
  },

  /**
   * This function is used to create moderator
   * @param context
   * @param payload
   * @returns
   */
  async actionCreateModerator(context: MainContext, payload: any) {
    try {
      const loadingNotification = { content: "Adding", showProgress: true };
      commitAddNotification(context, loadingNotification);
      const response = await axios.post("moderator/create_moderator", payload);
      commitRemoveNotification(context, loadingNotification);
      const successNotification = {
        content: `Moderator ${payload.email} was added.`,
        color: "success",
      };
      commitAddNotification(context, successNotification);
      return response;
    } catch (error) {
      await dispatchCheckApiError(context, error);
    }
  },

  /**
   * This function is used to remove moderator
   * @param context
   * @param payload
   */
  async actionRemoveModerator(context: MainContext, payload: string) {
    try {
      const loadingNotification = { content: "Removing", showProgress: true };
      commitAddNotification(context, loadingNotification);
      await axios.post("moderator/delete_moderator", { email: payload });
      commitRemoveNotification(context, loadingNotification);
      const successNotification = {
        content: `Moderator was removed.`,
        color: "success",
      };
      commitAddNotification(context, successNotification);
    } catch (error) {
      await dispatchCheckApiError(context, error);
    }
  },

  /**
   * This function is used to update moderator details.
   * @param context
   * @param payload
   */
  async actionUpdateModerator(
    context: MainContext,
    payload: {
      email: string,
      moderator: IModeratorUpdate,
      notificationsOff?: boolean
    }
  ) {
    try {
      const response = await axios.post(
        "/moderator/update_moderator",
        payload.moderator
      );
      const successNotification = {
        content: `Moderator ${response.data.email} successfully updated`,
        color: "success",
      };
      commitAddNotification(context, successNotification);
    } catch (error) {
      if (!payload.notificationsOff) {
        await dispatchCheckApiError(context, error);
      }
    }
  },

  /**
   * This function is used to filter moderator
   * @param context
   * @param users
   */
  async updateNonModerators(context: MainContext, users: IUserProfile[]) {
    const nonModUsers = [...users];
    const moderators = readModerators(context);
    for (const mod of moderators) {
      const index = nonModUsers.findIndex((u) => u.email === mod.email);
      if (index !== -1) {
        nonModUsers.splice(index, 1);
      }
    }
    commitSetNonModeratorUsers(context, nonModUsers);
  },

  /**
   * This function is used to get moderators by email
   * @param context
   * @param email
   * @returns
   */
  async getModeratorByEmail(context: MainContext, email: string) {
    try {
      const response = await axios.get("/moderator", { params: { email } });
      const moderator: IModerator = response.data;
      if (moderator) {
        commitSetModerator(context, moderator);
        return moderator;
      }
    } catch (error) {
      await dispatchCheckApiError(context, error);
    }
  }
};

const { dispatch } = getStoreAccessors<ModeratorState, State>("");

export const dispatchGetModerators = dispatch(actions.actionGetModerators);
export const dispatchCreateModerator = dispatch(actions.actionCreateModerator);
export const dispatchRemoveModerator = dispatch(actions.actionRemoveModerator);
export const dispatchUpdateModerator = dispatch(actions.actionUpdateModerator);
export const dispatchUpdateNonModerators = dispatch(
  actions.updateNonModerators
);
export const dispatchGetModeratorByEmail = dispatch(
  actions.getModeratorByEmail
);
