import { ExcludedState } from "./state";
import { getStoreAccessors } from "typesafe-vuex";
import { State } from "../state";

export const getters = {
  emails: (state: ExcludedState) => state.emails,
  excludedList: (state: ExcludedState) => state.excludedList,
  nonExcluded: (state: ExcludedState) => state.nonExcluded,
};

const { read } = getStoreAccessors<ExcludedState, State>("");

export const readExcludedEmails = read(getters.emails);
export const readExcludedList = read(getters.excludedList);
export const readNonExcludedUsers = read(getters.nonExcluded);
