
import { Component, Vue } from "vue-property-decorator";
import { readCompanyUsers } from "@/store/admin/getters";
import { readUserProfile } from "@/store/main/getters";
import { dispatchGetUserCompany } from "@/store/companies/actions";
import { readUserCompany } from "@/store/companies/getters";
import { readSubscribedUsers } from "@/store/microsoft/getters";
import ModeratorSMS from "@/components/ModeratorSMS.vue";

@Component({
  components: { ModeratorSMS }
})
export default class CompanyUsers extends Vue {
  public async mounted() {
    if (this.userProfile?.company_id) {
      await dispatchGetUserCompany(this.$store, this.userProfile?.company_id);
    }
    // await dispatchGetUserProfile(this.$store).then((res) => {
    //   dispatchGetUserCompany(this.$store, this.userProfile?.company_id);
    // });
  }

  get subscribedUsers() {
    return readSubscribedUsers(this.$store);
  }

  get company() {
    return readUserCompany(this.$store);
  }

  get users() {
    return readCompanyUsers(this.$store);
  }

  get userProfile() {
    return readUserProfile(this.$store);
  }
}
