export default {
  primary: {
    base: "#171466",
    lighten1: "#24267B",
    lighten2: "#2C3087",
    lighten3: "#353992",
    lighten4: "#3A409C",
    lighten5: "#565CAA",
  },
  secondary: {
    base: "#FF9C33",
    darken1: "#FA9130",
    darken2: "#F2822D",
    darken3: "#EC732B",
    darken4: "#E05C27",
    lighten1: "#FFAA43",
    lighten2: "#FFB95F",
    lighten3: "#FFCE8A",
    lighten4: "#FFE1B8",
    lighten5: "#FFF3E3",
  },
  tertiary: {
    base: "#FFC533",
  },
  darkGray: {
    base: "#4A4A4A",
  },
  lightGray: {
    base: "#F2ECE6",
  },
  error: "#F46363",
  warning: "#FF9138",
  success: "#2ED477",
};
