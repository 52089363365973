import {
  IMicrosoftTeamsItem,
  IMicrosoftTeamsForward,
  MicrosoftTeamsToxicItemCount,
  IMicrosoftTeamsToxicItems,
} from "@/interfaces";
import { MicrosoftTeamsItemState } from "./state";
import { getStoreAccessors } from "typesafe-vuex";
import { State } from "../state";

export const mutations = {
  setMicrosoftTeamsItemById(
    state: MicrosoftTeamsItemState,
    payload: IMicrosoftTeamsItem
  ) {
    state.item = payload;
  },
  setMicrosoftTeamsToxicItemCount(
    state: MicrosoftTeamsItemState,
    payload: MicrosoftTeamsToxicItemCount
  ) {
    state.itemCount = payload;
  },
  setMicrosoftTeamsToxicItems(
    state: MicrosoftTeamsItemState,
    payload: IMicrosoftTeamsToxicItems
  ) {
    state.toxicItems = payload;
  },
  setMicrosoftTeamsToxicItemsLoading(
    state: MicrosoftTeamsItemState,
    payload: boolean
  ) {
    state.toxicItemsLoading = payload;
  },
  setMicrosoftTeamsEarliestMessageDate(
    state: MicrosoftTeamsItemState,
    payload: any
  ) {
    state.earliestMessageDate = payload;
  },
  setMicrosoftTeamsForwards(
    state: MicrosoftTeamsItemState,
    payload: IMicrosoftTeamsForward[]
  ) {
    state.forwards = payload;
  },
  setMicrosoftTeamsItemsByType(
    state: MicrosoftTeamsItemState,
    payload: {
      items: IMicrosoftTeamsItem[];
      type: string;
    }
  ) {
    state.toxicItems[payload.type] = payload.items;
  },
};

const { commit } = getStoreAccessors<MicrosoftTeamsItemState, State>("");

export const commitSetMicrosoftTeamsToxicItems = commit(
  mutations.setMicrosoftTeamsToxicItems
);
export const commitSetMicrosoftTeamsToxicItemsLoading = commit(
  mutations.setMicrosoftTeamsToxicItemsLoading
);
export const commitSetMicrosoftTeamsItemById = commit(
  mutations.setMicrosoftTeamsItemById
);
export const commitSetMicrosoftTeamsEarliestMessageDate = commit(
  mutations.setMicrosoftTeamsEarliestMessageDate
);
export const commitSetMicrosoftTeamsForwards = commit(
  mutations.setMicrosoftTeamsForwards
);
export const commitSetMicrosoftTeamsToxicItemCount = commit(
  mutations.setMicrosoftTeamsToxicItemCount
);
export const commitSetMicrosoftTeamsItemsByType = commit(
  mutations.setMicrosoftTeamsItemsByType
);
