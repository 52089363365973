import { ExcludedState } from "./state";
import { getStoreAccessors } from "typesafe-vuex";
import { State } from "../state";

export const mutations = {
  setEmails(state: ExcludedState, payload) {
    state.emails = payload;
  },
  setExcludedList(state: ExcludedState, payload) {
    state.excludedList = payload;
  },
  setNonExcluded(state: ExcludedState, payload) {
    state.nonExcluded = payload;
  },
};

const { commit } = getStoreAccessors<ExcludedState, State>("");

export const commitSetExcludedEmails = commit(mutations.setEmails);
export const commitSetExcludedList = commit(mutations.setExcludedList);
export const commitSetNonExcluded = commit(mutations.setNonExcluded);
