import { ActionContext } from "vuex";
import { State } from "../state";
import { AuditState } from "./state";
import { getStoreAccessors } from "typesafe-vuex";
import { commitSetScanProgress } from "./mutations";
import { IScanProgress } from "@/interfaces";
import axios from "../interceptor";
import { SCAN_PROGRESS_COMPLETED_STATUS } from "../../constants/index";

type MainContext = ActionContext<AuditState, State>;

export const actions = {
  /**
   * This function fetch data of scan table progress
   * @param context
   * @param payload
   */
  async actionsGetScanProgress(
    context: MainContext,
    payload: {
      companyId: number;
    }
  ) {
    try {
      const response = await axios.get("/moderator/get_scan_table_progress");
      if (response.data[0]) {
        const results: IScanProgress = response.data[response.data.length - 1];
        if (results.status === SCAN_PROGRESS_COMPLETED_STATUS) {
          results.days_completed = results.total_days;
        }
        commitSetScanProgress(context, results);
      }
    } catch (error) {
      // await dispatchCheckApiError(context, error);
    }
  },
};

const { dispatch } = getStoreAccessors<AuditState, State>("");
export const dispatchGetScanProgress = dispatch(actions.actionsGetScanProgress);
