
import { Component, Vue, Prop } from "vue-property-decorator";
import {
  dispatchGetExcludedEmails,
  dispatchCreateExcluded,
  dispatchRemoveExcluded
} from "@/store/excluded/actions";
import {
  readExcludedEmails,
  readExcludedList,
  readNonExcludedUsers
} from "@/store/excluded/getters";
import { IUserProfile } from "@/interfaces";
import { readCompany } from "@/store/main/getters";
import { readCompanyUsers } from "@/store/admin/getters";
import { dispatchGetUserCompany } from "@/store/main/actions";
import { commitAddNotification } from "@/store/main/mutations";
import VuePapaParse from "vue-papa-parse";
import { dispatchUploadExclusionCsv } from "@/store/companies/actions";
Vue.use(VuePapaParse);

@Component
export default class ExcludedList extends Vue {
  [x: string]: any;
  public selectedUser = {};
  public enteredEmail = "";
  public emailSearch = "";
  @Prop() public readonly userProfile: IUserProfile;
  public addedExcluded: string | null = null;
  public selectedOption: any = "all_users";
  public fileCounts: any = {
    total_count: 0,
    total_invalid_mail: 0,
    total_valid_mail: 0
  };
  public isCsvEvaluate = false;
  public isLoading = false;
  public isCsvUploaded = false;
  public selectedCsvFile: any;
  public isHeaderMismatched = false;

  public async mounted() {
    if (!this.company?.company_id) {
      await dispatchGetUserCompany(this.$store);
    }

    this.updateExcludedList();
  }

  public isActive(email: string) {
    return this.addedExcluded === email;
  }

  public async updateExcludedList() {
    await dispatchGetExcludedEmails(this.$store, this.company.company_id);
  }

  get excludedEmails() {
    return readExcludedEmails(this.$store);
  }

  get nonExcludedUsers() {
    return readNonExcludedUsers(this.$store);
  }

  get excludedList() {
    if (this.emailSearch) {
      return readExcludedList(this.$store).filter((user) => {
        return user.email
          .toLowerCase()
          .includes(this.emailSearch.toLowerCase());
      });
    } else return readExcludedList(this.$store)
  }

  get company() {
    return readCompany(this.$store);
  }

  get users() {
    return readCompanyUsers(this.$store);
  }

  public async removeExcluded(id: any) {
    await dispatchRemoveExcluded(this.$store, id);
    this.updateExcludedList();
  }

  public async addSelectedEmail(user: IUserProfile) {
    await dispatchCreateExcluded(this.$store, {
      email: user.email,
      company_id: user.company_id
    }).then((res) => {
      this.addedExcluded = user.email;
      setTimeout(() => (this.addedExcluded = null), 2000);
    });
    this.updateExcludedList();
    this.selectedUser = {};
  }

  public async addEnteredEmail(email: string) {
    if (!(await this.$validator.validateAll())) {
      return;
    }
    await dispatchCreateExcluded(this.$store, {
      company_id: this.company.company_id,
      email
    }).then((res) => {
      this.addedExcluded = email;
      setTimeout(() => (this.addedExcluded = null), 2000);
    });
    this.updateExcludedList();
    this.enteredEmail = "";
  }

  chooseFiles() {
    const ref: any = document.getElementById("fileUpload");
    ref.value = "";
    ref.click();
  }

  onFilePicked(event: any) {
    if (event.target.files[0].size > 100000000) {
      commitAddNotification(this.$store, {
        content: "File size must be less than 100 mb",
        color: "error"
      });
      return;
    }
    this.selectedCsvFile = event.target.files[0];
    this.parseFile();
    this.isCsvUploaded = false;
  }

  async parseFile() {
    let data: any = [];
    let headerMismatched = false;
    const csvParsing = new Promise((res, rej) => {
      this.$papa.parse(this.selectedCsvFile, {
        header: true,
        skipEmptyLines: true,
        delimiter: ",",
        step: function (results: any, parser: any) {
          if (results.data.email_address == undefined) {
            parser.abort();
            headerMismatched = true;
          }
          data.push(results.data);
        },
        complete: function () {
          res(true);
        }
      });
    });
    await csvParsing.then((res) => {
      this.isHeaderMismatched = headerMismatched;
      if (!this.isHeaderMismatched) {
        const counts = this.validateUserCsv(data);
        this.fileCounts = counts;
        this.isCsvEvaluate = true;
      }
    });
  }

  downloadSampleCsv() {
    const sampleData = [
      { email_address: "test@gmail.com" },
      { email_address: "xyz@gmail.com" },
      { email_address: "testing@gmail.com" }
    ];
    const csv = this.$papa.unparse(sampleData);
    this.$papa.download(csv, "exclusion_list");
  }

  public validateUserCsv(data: any) {
    let counts = { total_count: 0, total_invalid_mail: 0, total_valid_mail: 0 };
    data.forEach((res: any) => {
      if (res.email_address == "") {
        return;
      }
      if (this.validateEmail(res.email_address)) {
        counts.total_valid_mail += 1;
      } else {
        counts.total_invalid_mail++;
      }
      counts.total_count++;
    });
    return counts;
  }

  validateEmail(email: any) {
    if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(email)) {
      return true;
    }
    return false;
  }

  async uploadApiTriggered() {
    if (
      !(
        this.fileCounts.total_invalid_mail == 0 &&
        this.fileCounts.total_valid_mail > 0
      )
    ) {
      return;
    }
    this.isLoading = true;
    const payload = new FormData();
    payload.append("upload_file", this.selectedCsvFile);
    await dispatchUploadExclusionCsv(this.$store, payload).then(
      async (response) => {
        this.isLoading = false;
        if (response) {
          await dispatchGetExcludedEmails(this.$store, this.company.company_id);
          this.isCsvUploaded = true;
        } else {
          this.isCsvUploaded = false;
        }
      }
    );
  }
}
