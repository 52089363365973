import { ActionContext } from "vuex";
import { State } from "../state";
import { ExcludedState } from "./state";
import { commitSetExcludedEmails, commitSetExcludedList } from "./mutations";
import { getStoreAccessors } from "typesafe-vuex";
import { dispatchCheckApiError } from "../main/actions";
import {
  commitAddNotification,
  commitRemoveNotification,
} from "../main/mutations";
import axios from "../interceptor";

type MainContext = ActionContext<ExcludedState, State>;

export const actions = {
  /**
   * This function get excluded list
   * @param context
   * @param companyId
   */
  async actionGetExcludedList(context: MainContext, companyId: number) {
    try {
      if (companyId) {
        const response = await axios.get("/excluded/list_excluded");
        if (response.data) {
          const excludedList = response.data.sort((a, b) => {
            if (a.email < b.email) {
              return -1;
            }
            if (a.email > b.email) {
              return 1;
            }
            return 0;
          });
          commitSetExcludedList(context, excludedList);
          const emailList: string[] = [];
          for (const item of excludedList) {
            emailList.push(item.email);
          }
          commitSetExcludedEmails(context, emailList);
        }
      }
    } catch (error) {
      await dispatchCheckApiError(context, error);
    }
  },

  /**
   * This function is used to add user in excluded list
   * @param context
   * @param payload
   */
  async actionCreateExcluded(
    context: MainContext,
    payload: { email: string; company_id: number }
  ) {
    try {
      const loadingNotification = { content: "Saving", showProgress: true };
      commitAddNotification(context, loadingNotification);
      await axios.post("/excluded/create_excluded", payload);
      commitRemoveNotification(context, loadingNotification);
      const successNotification = {
        content: `${payload.email} was excluded.`,
        color: "success",
      };
      commitAddNotification(context, successNotification);
    } catch (error) {
      await dispatchCheckApiError(context, error);
    }
  },

  /**
   * This function is used to remove user from excluded list
   * @param context
   * @param payload
   */
  async actionRemoveExcluded(context: MainContext, payload) {
    try {
      const deletePayload = {
        id: payload,
      };
      const loadingNotification = { content: "Removing", showProgress: true };
      commitAddNotification(context, loadingNotification);
      await axios.post("/excluded/delete_excluded", deletePayload);
      commitRemoveNotification(context, loadingNotification);
      const successNotification = {
        content: `Excluded Successfully.`,
        color: "success",
      };
      commitAddNotification(context, successNotification);
    } catch (error) {
      await dispatchCheckApiError(context, error);
    }
  },
};

const { dispatch } = getStoreAccessors<ExcludedState, State>("");

export const dispatchGetExcludedEmails = dispatch(
  actions.actionGetExcludedList
);
export const dispatchCreateExcluded = dispatch(actions.actionCreateExcluded);
export const dispatchRemoveExcluded = dispatch(actions.actionRemoveExcluded);
