
import { Component, Vue, Prop } from "vue-property-decorator";
import { IUserProfile } from "@/interfaces";
import { readCompany } from "@/store/main/getters";
import { readCompanyUsers } from "@/store/admin/getters";
import { dispatchGetUserCompany } from "@/store/main/actions";
import { readKeywordsList } from "@/store/companies/getters";
import { commitAddNotification } from "@/store/main/mutations";
import VuePapaParse from "vue-papa-parse";
import {
  dispatchRemoveKeyword,
  dispatchUploadKeywordCsv,
} from "@/store/companies/actions";
import { dispatchGetKeywordsList } from "@/store/companies/actions";
import { dispatchAddKeyword } from "@/store/companies/actions";
Vue.use(VuePapaParse);

@Component
export default class CustomKeywordScan extends Vue {
  [x: string]: any;
  @Prop() public readonly userProfile: IUserProfile;

  public chipsTrigger = true;
  public enteredKeyword = "";
  public fullNameRule = [
    (v) => !!v.trim() || "Keyword is required",
    (v) => v.length <= 100 || "Keyword must be no longer than 100 words",
  ];
  public addedMod: string | null = null;

  public fileCounts: any = {
    total_count: 0,
    total_invalid_keyword: 0,
    total_valid_keyword: 0,
  };
  public isCsvEvaluate = false;
  public isLoading = false;
  public isCsvUploaded = false;
  public selectedCsvFile: any;
  public isHeaderMismatched = false;

  public async mounted() {
    if (!this.$store.getters.company?.company_id) {
      await dispatchGetUserCompany(this.$store);
    }
    if (!this.$store.getters.readKeywordsList) {
      await dispatchGetKeywordsList(this.$store);
    }
  }

  get company() {
    return readCompany(this.$store);
  }

  get users() {
    return readCompanyUsers(this.$store);
  }
  get keywordsList() {
    return readKeywordsList(this.$store);
  }

  chooseFiles() {
    const ref: any = document.getElementById("fileUpload");
    ref.value = "";
    ref.click();
  }

  onFilePicked(event: any) {
    if (event.target.files[0].size > 100000000) {
      commitAddNotification(this.$store, {
        content: "File size must be less than 100 mb",
        color: "error",
      });
      return;
    }
    this.selectedCsvFile = event.target.files[0];
    this.parseFile();
    this.isCsvUploaded = false;
  }

  async parseFile() {
    let data: any = [];
    let headerMismatched = false;
    const csvParsing = new Promise((res, rej) => {
      this.$papa.parse(this.selectedCsvFile, {
        header: true,
        skipEmptyLines: true,
        delimiter: ",",
        step: function (results: any, parser: any) {
          if (results.data.Keywords == undefined) {
            parser.abort();
            headerMismatched = true;
          }
          data.push(results.data);
        },
        complete: function () {
          res(true);
        },
      });
    });
    await csvParsing.then((res) => {
      this.isHeaderMismatched = headerMismatched;
      if (!this.isHeaderMismatched) {
        const counts = this.validateUserCsv(data);
        this.fileCounts = counts;
        this.isCsvEvaluate = true;
      }
    });
  }

  downloadSampleCsv() {
    const sampleData = [{ Keywords: "Top Secret" }, { Keywords: "Commsafe" }];
    const csv = this.$papa.unparse(sampleData);
    this.$papa.download(csv, "keywords_list");
  }

  public validateUserCsv(data: any) {
    let counts = {
      total_count: 0,
      total_invalid_keyword: 0,
      total_valid_keyword: 0,
    };
    data.forEach((res: any) => {
      if (res.Keywords == "") {
        return;
      }
      if (this.validateKeyword(res.Keywords)) {
        counts.total_valid_keyword += 1;
      } else {
        counts.total_invalid_keyword++;
      }
      counts.total_count++;
    });
    return counts;
  }

  validateKeyword(keyword: any) {
    if (keyword.length < 100) {
      return true;
    }
    return false;
  }

  async uploadApiTriggered() {
    if (
      !(
        this.fileCounts.total_invalid_keyword == 0 &&
        this.fileCounts.total_valid_keyword > 0
      ) ||
      this.fileCounts.total_valid_keyword > 100
    ) {
      return;
    }
    this.isLoading = true;
    const payload = new FormData();
    payload.append("upload_file", this.selectedCsvFile);
    await dispatchUploadKeywordCsv(this.$store, payload).then(
      async (response) => {
        this.isLoading = false;
        if (response) {
          this.isCsvUploaded = true;
        } else {
          this.isCsvUploaded = false;
        }
      }
    );
  }

  public async removeKeyword(email: string) {
    await dispatchRemoveKeyword(this.$store, email).then((res) => {
      dispatchGetKeywordsList(this.$store);
    });
  }

  public reset() {
    const refForm: any = this.$refs.form;
    refForm.reset();
  }

  public resetValidator() {
    const refForm: any = this.$refs.form;
    refForm.resetValidation();
  }

  public async addKeyword() {
    const refForm: any = this.$refs.form;
    if (refForm.validate()) {
      const updatedForm = {
        keyword: this.enteredKeyword,
      };
      await dispatchAddKeyword(this.$store, updatedForm).then((res) => {
        this.addedMod = updatedForm.keyword;
        setTimeout(() => (this.addedMod = null), 2000);
        dispatchGetKeywordsList(this.$store);
        this.reset();
        this.resetValidator();
      });
    }
  }
}
