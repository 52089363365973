
import { Component, Vue } from "vue-property-decorator";
import { readCompanyUsers } from "@/store/admin/getters";
import { readUserProfile } from "@/store/main/getters";
import { readUserCompany } from "@/store/companies/getters";
import Moderators from "@/components/Moderators.vue";

@Component({
  components: { Moderators },
})
export default class ModeratorSettings extends Vue {
  get company() {
    return readUserCompany(this.$store);
  }

  get users() {
    return readCompanyUsers(this.$store);
  }

  get userProfile() {
    return readUserProfile(this.$store);
  }
}
