import { CountsState } from "./state";
import { getStoreAccessors } from "typesafe-vuex";
import { State } from "../state";

export const getters = {
  companyCountsSummary: (state: CountsState) => state.companyCountsSummary,
  microsoftTeamsCompanyCountsSummary: (state: CountsState) =>
    state.microsoftTeamsCompanyCountsSummary,
};

const { read } = getStoreAccessors<CountsState, State>("");

export const readCompanyCountsSummary = read(getters.companyCountsSummary);
export const readMicrosoftTeamsCompanyCountsSummary = read(
  getters.microsoftTeamsCompanyCountsSummary
);
