import { mutations } from "./mutations";
import { getters } from "./getters";
import { actions } from "./actions";
import { CompanyState } from "./state";

const defaultState: CompanyState = {
  companies: [],
  userCompany: null,
  lastDashboardSearch: {
    currentTabId: 0,
    timeframe: 7,
    label: "7 Days",
    currentPage: {
      review: 1,
      flagged: 1,
      archived: 1,
      all: 1,
    },
  },
  lastTeamsDashboardSearch: {
    currentTabId: 0,
    timeframe: 7,
    label: "7 Days",
    currentPage: {
      review: 1,
      flagged: 1,
      archived: 1,
      all: 1,
    },
  },
  importUser: { user_imported: false },
  keywordsList: [],
  isO356Verified: null,
  isTeamsVerified: null,
};

export const companyModule = {
  state: defaultState,
  mutations,
  actions,
  getters,
};
