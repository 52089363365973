
import { Component, Prop, Vue } from "vue-property-decorator";
import {
  ICompanyProfile,
  ICompanyTeamsVerification,
  IUserProfile,
} from "@/interfaces";
import {
  dispatchaTestMicrosoftTeamsConnection,
  dispatchGetMsTeamsOnboardingClientId,
} from "@/store/companies/actions";
import { onboardingPopUp } from "@/utils/onboardingPopUp";

@Component
export default class MicrosoftTeamsAuthorization extends Vue {
  @Prop() public readonly company: ICompanyProfile;
  @Prop() public readonly msLoginError: boolean;
  @Prop() public readonly userProfile: IUserProfile;
  @Prop() public readonly teamsVerification: ICompanyTeamsVerification;

  public msTeamsClientId = "";
  public msPopupWindow: any;

  async mounted() {
    this.msTeamsClientId = await dispatchGetMsTeamsOnboardingClientId(
      this.$store
    );

    if (!this.teamsVerification) {
      await this.verifyTeamsConnection();
    }

    if (this.$route.query?.admin_consent == "True") {
      window.close();
    }
  }

  public teamsAdminConsent() {
    onboardingPopUp.msAdminConsent(
      this.msTeamsClientId,
      this.company.tenant_id,
      this.msPopupWindow
    );
  }

  public async verifyTeamsConnection() {
    await dispatchaTestMicrosoftTeamsConnection(
      this.$store,
      this.userProfile.company_id
    );
  }
}
