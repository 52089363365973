import { ActionContext } from "vuex";
import { State } from "../state";
import { MSState } from "./state";
import { getStoreAccessors } from "typesafe-vuex";
import { dispatchCheckApiError } from "../main/actions";

type MainContext = ActionContext<MSState, State>;

export const actions = {
  async actionsImportMsUsers(
    context: MainContext,
    payload: {
      companyId: number;
      tenantId: string;
    }
  ) {
    return true;
    // try {
    //   // replace with whatever code is needed for login auth on GCP
    //   await Auth.currentSession().then((user) => {
    //     const token = user.getIdToken().getJwtToken();
    //     const params = {
    //       headers: {
    //         'Gateway-token': token,
    //       },
    //       body: {
    //         CompanyId: payload.companyId,
    //         TenantId: payload.tenantId,
    //         FunctionName: 'import_users',
    //       },
    //     };
    //     try {
    //       // replace with service to import MS email accounts
    //       const response = (Promise.all([
    //         API.post('microsoftImportEmailAccounts', '/commsafe/import-accounts', params),
    //         new Promise((resolve, reject) => setTimeout(() => resolve(), 500)),
    //       ]))[0];
    //     } catch (error) {
    //       dispatchCheckApiError(context, error);
    //     }
    //   });
    // } catch (error) {
    //   await dispatchCheckApiError(context, error);
    // }
  },
  async actionsSubscribeMsUsers(
    context: MainContext,
    payload: {
      companyId: number;
      tenantId: string;
    }
  ) {
    try {
      // replace with whatever code is needed for login auth on GCP
      return true;
      // await Auth.currentSession().then((user) => {
      //   const token = user.getIdToken().getJwtToken();
      //   const params = {
      //     headers: {
      //       'Gateway-token': token,
      //     },
      //     body: {
      //       CompanyId: payload.companyId,
      //       TenantId: payload.tenantId,
      //       FunctionName: 'subscribe_users',
      //     },
      //   };
      //   try {
      //     // replace with service to subscribe MS accounts
      //     const response = (Promise.all([
      //       API.post('microsoftImportEmailAccounts', '/commsafe/import-accounts', params),
      //       new Promise((resolve, reject) => setTimeout(() => resolve(), 500)),
      //     ]))[0];
      //   } catch (error) {
      //     dispatchCheckApiError(context, error);
      //   }
      // });
    } catch (error) {
      await dispatchCheckApiError(context, error);
    }
  },
  async actionsStartAudit(
    context: MainContext,
    payload: {
      companyId: number;
      tenantId: string;
    }
  ) {
    try {
      // Enter your GCP code here
      return true;
      // await Auth.currentSession().then((user) => {
      //   const token = user.getIdToken().getJwtToken();
      //   const params = {
      //     headers: {
      //       'Gateway-token': token,
      //     },
      //     body: {
      //       CompanyId: payload.companyId,
      //       TenantId: payload.tenantId,
      //       FunctionName: 'audit',
      //     },
      //   };
      //   try {
      //     const response = (Promise.all([
      //       API.post('microsoftImportEmailAccounts', '/commsafe/import-accounts', params),
      //       new Promise((resolve, reject) => setTimeout(() => resolve(), 500)),
      //     ]))[0];
      //   } catch (error) {
      //     dispatchCheckApiError(context, error);
      //   }
      // });
    } catch (error) {
      await dispatchCheckApiError(context, error);
    }
  },
};

const { dispatch } = getStoreAccessors<MSState, State>("");

export const dispatchSubscribeMsUsers = dispatch(
  actions.actionsSubscribeMsUsers
);
export const dispatchImportMsUsers = dispatch(actions.actionsImportMsUsers);
export const dispatchStartAudit = dispatch(actions.actionsStartAudit);
